import styled from "styled-components";

export const Container = styled.div`
  background-color: #797979;
  color: #ffff;
  padding: 3px 6px;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
`;

export const Ativo = styled(Container)`
    background-color: #00ac00;
`;

export const Inativo = styled(Container)`
    background-color: #929292;
`;

export const Pendente = styled(Container)`
    background-color: #326bce;
`;

export const Bloqueado = styled(Container)`
    background-color: #ac001d;
`;
