import styled from 'styled-components';

export const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 50vh;
flex-direction: column;
`;

export const Title = styled.h1`
    font-size: 100px;
    font-weight: bold;
    color: #c8d9e3;

`;

export const Subtitle = styled.h2`
    font-size: 30px;
    color: #333;
    font-weight: bold;
    color: #91adbd;
`;