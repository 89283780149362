import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { useParams } from "react-router-dom";
import { fromB64, toB64 } from "../../utils/functions";
import USERS from "../../api/users";
import { Col, Container, Row } from "reactstrap";
import { Button, Label, Text, Title, WhiteBox } from "../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHeartPulse,
  faSpinner,
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import AddDependentModal from "../../components/AddDependentModal";

function Activate() {
  const { id } = useParams();
  const [status, setStatus] = useState("activating");
  const [generating_checkout, setGeneratingCheckout] = useState(false);
  const [state, setState] = useState({
    vidas: [],
    addDependentModal: false,
    me: {},
    campaign: false,
  });

  const { vidas, addDependentModal, me, campaign } = state;
  useEffect(() => {
    // activate();
    shouldActivate();
  }, []);

  async function shouldActivate() {
    if (!id) return setStatus("error");
    try {
      let response = await USERS.set.shouldActivate(id);
      console.log(response);
      if (response.activatedNow) {
        setState((s) => ({
          ...s,
          campaign: true,
        }));
        setStatus("activated");
        return;
      }
      let vidas = response?.vidas || [];
      let me = response?.user;

      // se não for vida ou não for responsável pelo pagamento, deve somente ativar a conta
      if (
        me?.is_life === false ||
        me?.is_billing === false ||
        me?.manage_life === false
      ) {
        await activate();
        return;
      }

      setState((s) => ({
        ...s,
        vidas: vidas,
        me: me,
      }));
      setStatus("idle");
    } catch (error) {
      setStatus("error");
    }
  }

  async function activate() {
    if (!id) return setStatus("error");
    if (status !== "activating") return;
    try {
      let response = await USERS.set.activate(id);
      setStatus("activated");
    } catch (error) {
      setStatus("error");
    }
  }

  async function addDependent() {
    console.log("add dependent");
    setState((s) => ({
      ...s,
      addDependentModal: true,
    }));
  }

  function closeAddDependent() {
    setState((s) => ({
      ...s,
      addDependentModal: false,
    }));
  }

  async function addDependentToDB(vida) {
    // save vidas
    try {
      let response = await USERS.set.add_dependent(id, vida);
      console.log(response.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function gotoPayment() {
    setGeneratingCheckout(true);
    // save vidas
    // let test = await addDependentsToDB();
    // if (test === false) return;
    let link = await USERS.set.generateCheckout(id, vidas.length + 1, me.email);
    console.log(link);
    window.location.assign(link.url);
  }

  // add dependentcallback
  async function addDependentCallback(dependent) {
    let nid = Math.random().toString(36).substring(7);
    setState((s) => {
      let new_vidas = [...s.vidas, { id: nid, ...dependent }];
      return { ...s, vidas: new_vidas, addDependentModal: false };
    });
    let id = await addDependentToDB(dependent);
    if (id === false) {
      setState((s) => {
        let new_vidas = s.vidas.filter((v) => v.id !== nid);
        return { ...s, vidas: new_vidas, addDependentModal: false };
      });
    } else {
      // update id in vidas
      setState((s) => {
        let new_vidas = s.vidas.map((v) => {
          if (v.id === nid) {
            return { ...v, id: id };
          }
          return v;
        });
        return { ...s, vidas: new_vidas, addDependentModal: false };
      });
    }
  }

  async function removeDependent(dependent_id) {
    // remove dependent
    let new_vidas = vidas.filter((v) => v.id !== dependent_id);
    setState((s) => ({ ...s, vidas: new_vidas }));
    // remove from db
    try {
      await USERS.set.remove_dependent(id, dependent_id);
    } catch (error) {
      console.log(error);
    }
  }

  switch (true) {
    case status === "idle" && me?.is_life === true:
      let total_vidas = vidas.length + 1;
      return (
        <Container>
          <Row>
            <Col md={8}>
              <WhiteBox>
                <Title>Dependentes</Title>
                {vidas.length === 0 && <Text>Nenhum dependente</Text>}
                <S.VidaGrid>
                  {vidas.map((vida, index) => (
                    <Dependente
                      key={index}
                      vida={vida}
                      remover={removeDependent}
                    />
                  ))}
                </S.VidaGrid>
                <hr />
                <Button onClick={addDependent}>Adicionar dependente</Button>
              </WhiteBox>
            </Col>
            <Col md={4}>
              <WhiteBox>
                <Title>Resumo</Title>
                <Label>Vidas</Label>
                <Text>{total_vidas}</Text>
                <hr />
                {generating_checkout ? (
                  <Text>
                    Gerando link de pagamento... Você será redirecionado em
                    breve
                  </Text>
                ) : (
                  <Button onClick={gotoPayment}>Ir para pagamento</Button>
                )}
              </WhiteBox>
            </Col>
          </Row>
          <AddDependentModal
            visible={addDependentModal}
            onChange={addDependentCallback}
            close={closeAddDependent}
          />
        </Container>
      );
    case status === "activated":
      return (
        <Container>
          <Row>
            <Col>
              <S.Container>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="3x"
                  color="#28a745"
                  style={{ marginBottom: 20 }}
                />
                <Title>Conta ativada com sucesso!</Title>
                <Text>
                  Sua conta foi ativada com sucesso, você já pode entrar no
                  sistema.
                </Text>
                {campaign && (
                  <>
                    <br />
                    <Text>Você já pode consultar clicando utilizando os seguintes dados:</Text>
                    <br/>
                    <Text>Usuário: SEU CPF</Text>
                    <Text>Senha: SEU CPF</Text>
                    <br />
                    <Button onClick={()=>{
                      window.open('https://telemedicina.lincesaude.com.br/','_blank')
                    }}>
                      <FontAwesomeIcon icon={faHeartPulse} style={{marginRight: 5}}/>
                      Iniciar teleatendimento
                    </Button>
                  </>
                )}
              </S.Container>
            </Col>
          </Row>
        </Container>
      );

    case status === "error":
      return (
        <Container>
          <Row>
            <Col>
              <S.Container>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  size="3x"
                  color="#dc3545"
                  style={{ marginBottom: 20 }}
                />
                <Title>Erro ao ativar conta</Title>
                <Text>
                  Esta conta pode já estar ativada, ou o link pode ter expirado
                </Text>
              </S.Container>
            </Col>
          </Row>
        </Container>
      );

    default:
      return (
        <Container>
          <Row>
            <Col>
              <S.Container>
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="3x"
                  spin={true}
                  color="#007bff"
                  style={{ marginBottom: 20 }}
                />
                <Title>Carregando</Title>
                <Text>
                  Estamos carregando os dados de sua conta, por favor aguarde...
                </Text>
              </S.Container>
            </Col>
          </Row>
        </Container>
      );
      break;
  }
}

function Dependente({ vida, remover }) {
  console.log(vida);

  function deletar() {
    console.log("deletar", vida.id);
    remover(vida.id);
  }

  return (
    <S.VidaContainer>
      <S.Title>
        <S.CloseButton onClick={deletar}>
          <FontAwesomeIcon icon={faTimes} />
        </S.CloseButton>
        {vida.name}
      </S.Title>
      <Label>CPF</Label>
      <Text>{vida.cpf}</Text>
    </S.VidaContainer>
  );
}

export default Activate;
