import React from "react";

import {
  faBuilding,
  faBuildings,
  faDollar,
  faDownload,
  faHeartPulse,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { LeftMenu, LeftMenuItem } from "../main-styles";
import { LinkMenu } from "./styles";
import moment from "moment-timezone";
import USERS from "../../api/users";
import { toast } from "react-toastify";

function Admin() {
  async function getXLS() {
    toast.promise(
      async () => {
        let file = await USERS.get.all_hubcare();
        console.log(file);
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `HubcareUsers ${moment().format(`DD-MM-YYYY HH:mm`)}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      {
        loading: "Baixando...",
        success: "Arquivo baixado!",
        error: "Erro ao baixar o arquivo",
      }
    );
  }
  return (
    <Container style={{marginTop: 15}}>
      <Row>
        <Col md={2}>
          <LeftMenu>
            <LeftMenuItem>
              <LinkMenu to="/admin/usuarios">
                <FontAwesomeIcon icon={faUsers} style={{ marginRight: 5 }} />
                Todos
              </LinkMenu>
            </LeftMenuItem>
            <LeftMenuItem>
              <LinkMenu to="/admin/precificacao">
                <FontAwesomeIcon icon={faDollar} style={{ marginRight: 5 }} />
                Precificação
              </LinkMenu>
            </LeftMenuItem>

            <LeftMenuItem>
              <LinkMenu to="/admin/contratos">
                <FontAwesomeIcon
                  icon={faBuildings}
                  style={{ marginRight: 5 }}
                />
                Contratos
              </LinkMenu>
            </LeftMenuItem>
            
          </LeftMenu>
          <LeftMenu style={{ marginTop: 15, backgroundColor: "#b5b5b5" }}>
            <LeftMenuItem>
              <LinkMenu to="#" onClick={getXLS}>
                <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
                Hubcare
              </LinkMenu>
            </LeftMenuItem>
          </LeftMenu>
        </Col>
        <Col md={10}>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}

export default Admin;
