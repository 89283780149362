import { faFilePdf, faHeadset } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import USERS from "../../api/users";
import LOGO_WHITE from "../../assets/logo_white.png";
import AddDependentModal from "../../components/AddDependentModal";
import { ROLES } from "../../utils/constants";
import { WhiteBox } from "../main-styles";
import Life from "./Life";
import PJ from "./PJ";
import * as S from "./styles";
import { Link, useNavigate } from "react-router-dom";

export const HomeContext = React.createContext();

function Home() {
  const { userData } = useSelector((state) => state.auth);
  const { is_life, manage_life, role } = userData;
  console.log("userData", userData);
  const [loading, setLoading] = useState(true);
  const [lifes, setLifes] = useState([]);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const navigate = useNavigate();

  function goto(link) {
    window.open(link, { target: "_blank" });
  }

  useEffect(() => {
    init();
  }, []);
  async function init() {
    let promises = [loadLifes()];
    await Promise.all(promises);
    setLoading(false);
  }

  async function loadLifes() {
    let lifes = await USERS.get.dependents(userData.id);
    console.log("lifes", lifes);
    setLifes(lifes);
  }

  function showModal() {
    setAddModalVisible(true);
  }

  async function addDependent(data) {
    try {
      console.log(data);
      let response = await toast.promise(
        USERS.set.add_dependent(userData.id, data, true),
        {
          loading: "Adicionando dependente",
          success: "Dependente adicionado com sucesso",
          error: "Erro ao adicionar dependente",
        }
      );

      console.log("responseaqui: ", response);
      if (response.success) {
        setAddModalVisible(false);
        init();
      }
    } catch (error) {
      setAddModalVisible(false);
    }
  }

  function closeAddDependent() {
    setAddModalVisible(false);
  }

  function deleteDependent(id) {
    console.log("delete", id);
    toast.warning("Envie um email solicitando a exclusão do dependente");
  }

  return (
    <HomeContext.Provider
      value={{ loadLifes, deleteDependent, loading, goto, showModal, lifes }}
    >
      <Container>
        <Row>
          <Col>
            <S.Container>
              <S.WelcomeMessage>
                Bem-vindo ao sistema de telemedicina Lince Saúde!
                <br />
                <img
                  src={LOGO_WHITE}
                  alt="Lince Saúde"
                  style={{ height: 30, width: "auto" }}
                />
              </S.WelcomeMessage>
              <S.UltraContent>
                {/* <Life /> */}
                {/* <ContentPerRole role={role} /> */}
                {role !== ROLES.dependente && (
                  <section id="support">
                    <WhiteBox
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <S.Title>Contratos</S.Title>
                      <S.Description>
                        Acesse e gerencie os seus contratos com a Lince Saúde.
                      </S.Description>
                      <S.Button onClick={() => navigate("/contratos")}>
                        <FontAwesomeIcon icon={faFilePdf} size="lg" />
                        Acessar meus contratos
                      </S.Button>
                    </WhiteBox>
                  </section>
                )}
                <section id="support">
                  <WhiteBox
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <S.Title>Conheça nosso painel de suporte</S.Title>
                    <S.Description>
                      Nosso painel de suporte é projetado para fornecer
                      assistência personalizada aos pacientes. Aqui você pode
                      encontrar informações sobre seus agendamentos, histórico
                      médico e muito mais.
                    </S.Description>
                    <S.Button
                      onClick={() => {
                        goto("https://lince.freshdesk.com");
                      }}
                    >
                      <FontAwesomeIcon icon={faHeadset} size="lg" />
                      Ir para o painel de suporte
                    </S.Button>
                  </WhiteBox>
                </section>
              </S.UltraContent>
            </S.Container>
          </Col>
        </Row>
      </Container>
    </HomeContext.Provider>
  );
}

function ContentPerRole({ role }) {
  switch (role) {
    case ROLES.pj:
    case ROLES.convenio:
      return <PJ />;
    case ROLES.titular:
    // não é necessario retornar nada, a funcão é chamada pelo <Life />
    // eslint-disable-next-line no-fallthrough
    case ROLES.dependente:
    // não é necessario retornar nada, a funcão é chamada pelo <Life />
    // eslint-disable-next-line no-fallthrough
    default:
      return null;
  }
}

export default Home;
