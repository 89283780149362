import styled from "styled-components";
import { COLORS } from "../../routes/main-styles";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  border-radius: 5px;
  background-color: ${(props) => (props.selected ? COLORS.main : "#efefef")};
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease-in-out;
`;

export const Text = styled.div`
  margin-left: 5px;
`;
