import React, { useEffect, useState } from "react";

import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import USERS from "../../../api/users";
import Spinner from "../../../components/Spinner";
import { Button, FH, FHSB, Input, Text } from "../../main-styles";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { formataReal } from "../../../utils/functions";
const STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  SAVING: "saving",
};

function UserPricing({ open, toggle }) {
  const [state, setState] = useState({
    value: 0,
    status: STATUS.LOADING,
  });
  const { value, status } = state;

  useEffect(() => {
    getPricing();
  }, []);
  async function getPricing() {
    setState((state) => ({ ...state, status: STATUS.LOADING }));
    const pricing = await USERS.get.pricing();

    setState((state) => ({ ...state, status: STATUS.IDLE, value: pricing }));
  }
  async function savePricing() {
    if (status !== STATUS.IDLE) return;
    setState((state) => ({ ...state, status: STATUS.SAVING }));
    let r = await USERS.set.pricing(state.value);
    if (r?.error) {
      console.log("");
      let min = formataReal(r.default.min);
      let max = formataReal(r.default.max);
      toast.error(
        `Erro ao definir taxa de adesão, valor deve estar entre ${min} e ${max}`
      );
    } else {
      toast.success("Taxa de adesão atualizada com sucesso");
      toggle();
    }
    setState((state) => ({ ...state, status: STATUS.IDLE }));
  }

  function updateValor(valor) {
    //deve aceitar somente numeros,
    valor = valor.replace(/\D,/g, "");
    setState((state) => ({ ...state, value: valor }));
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      {status === STATUS.LOADING ? (
        <ModalBody>
          <Spinner /> Carregando taxa de adesão
        </ModalBody>
      ) : (
        <>
          <ModalHeader>Taxa de Adesão</ModalHeader>
          <ModalBody
            style={{ display: "flex", gap: 5, flexDirection: "column" }}
          >
            <Alert color="info">
              Este valor será adicionado às novas assinaturas.
            </Alert>
            <FH>
              <Text>R$</Text>
              <NumericFormat
                customInput={Input}
                value={value}
                onChange={(e) => updateValor(e.target.value)}
                style={{ maxWidth: 100 }}
                decimalScale={2}
                decimalSeparator=","
                allowNegative={false}
              />
            </FH>
            <Button onClick={savePricing} disabled={status === STATUS.SAVING}>
              {status === STATUS.SAVING ? "Salvando..." : "Salvar"}
            </Button>
          </ModalBody>
        </>
      )}
    </Modal>
  );
}

export default UserPricing;
