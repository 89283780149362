import React, { useEffect, useMemo, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Alert, Col, Container, Row } from "reactstrap";
import USERS from "../../api/users";
import BasePage from "../../components/BasePage";
import Childrens from "../../components/Childrens";
import RenderLife from "../../components/RenderLife";
import UserBox from "../../components/UserBox";
import { ROLES } from "../../utils/constants";
import { fromB64, toB64 } from "../../utils/functions";
import UserData from "./UserData";
import PJData from "./PJData";
import { COLORS, Title } from "../main-styles";
import ConfigModal from "./ConfigModal";
import AddModal from "./AddModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

export const UserContext = React.createContext();

function User() {
  const navigate = useNavigate();
  // gets id
  const { id: unc } = useParams();
  const id = useMemo(() => {
    return unc ? fromB64(unc) : null;
  }, [unc]);
  const [state, setState] = useState({
    loading: true,
    data: null,
    show_config_modal: false,
    show_add_modal: false,
  });
  const { loading, data, show_config_modal, show_add_modal } = state;

  useEffect(() => {
    init();
  }, [id]);

  async function init() {
    if (!id) return;
    setState((s) => {
      return { ...s, loading: true };
    });

    let user = await USERS.get.one(id);
    setState((s) => {
      return { ...s, loading: false, data: user };
    });
  }

  function addClick() {
    console.log("addClick");
    switch(data?.role){
      case ROLES.titular:
        toggleAddModal()
        break;
      case ROLES.convenio:
        toggleAddModal()
        break;
      case ROLES.pj:
        toggleAddModal()
        break;
      default:
        break;
    }
  }

  function gotoConfig() {
    setState((s) => {
      return { ...s, show_config_modal: true };
    });
  }

  function gotoUser(id) {
    navigate(`/u/${toB64(id)}`);
  }

  function toggleConfigModal() {
    setState((s) => {
      return { ...s, show_config_modal: !s.show_config_modal };
    });
  }

  function toggleAddModal() {
    setState((s) => {
      return { ...s, show_add_modal: !s.show_add_modal };
    });
  }


  const color = useMemo(() => {
    switch (data?.role) {
      case ROLES.superadmin:
        return "#000000";
      case ROLES.admin:
        return "#525252";
      case ROLES.convenio:
        return "#368ECE";
      case ROLES.pj:
        return "#2DB292";
      default:
        return "#8C53D5";
        break;
    }
  }, [data?.role]);

  const label = useMemo(() => {
    switch (data?.role) {
      case ROLES.superadmin:
        return "Superadmin";
      case ROLES.admin:
        return "Admin";
      case ROLES.convenio:
        return "Convênio";
      case ROLES.pj:
        return "CNPJ";
      case ROLES.titular:
        return "Titular";
      default:
        return "Dependente";
        break;
    }
  }, [data?.role]);

  if (loading)
    return (
      <Container>
        <Row>
          <Col>
          <FontAwesomeIcon icon={faSpinner} spin size={"2x"} color={COLORS.grey} />
          </Col>
        </Row>
      </Container>
    );
  let isPJ = data?.role === ROLES.pj || data?.role === ROLES.convenio;
  let show_name = isPJ ? data?.company?.razao_social : data?.name;
  return (
    <UserContext.Provider value={{ data, setState }}>
      <BasePage
        label={label}
        title={show_name}
        color={color}
        menu={
          <>
            {data?.is_life && <RenderLife user={data} />}
            {data?.parent && (
              <UserBox
                title={"Vinculado a"}
                user={data?.parent}
                small={true}
                onClick={() => gotoUser(data?.parent?.id)}
              />
            )}
            {!data?.billing && data?.centro_custo && (
              <UserBox
                title="Centro de Custo"
                user={data?.centro_custo}
                small={true}
                onClick={() => gotoUser(data?.centro_custo?.id)}
              />
            )}
            {!data?.billing && !data?.centro_custo && (
              <Alert color="danger">Usuário sem centro de custo</Alert>
            )}
          </>
        }
        loading={loading}
        add={addClick}
        config={gotoConfig}
      >
        <Title>Dados de Acesso</Title>
        <UserData data={data} />
        <PJData data={data} />
        {data?.children?.length > 0 && (
          <>
            <Title>Items</Title>
            <Childrens items={data?.children} />
          </>
        )}
        <ConfigModal
          open={show_config_modal}
          toggle={toggleConfigModal}
          data={data}
          id={id}
        />
        <AddModal open={show_add_modal} toggle={toggleAddModal} />
      </BasePage>
    </UserContext.Provider>
  );
}

export default User;
