import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function IconToolTip({ id = "tt", icon, color, title = "", style = {}, onClick }) {
  function click(){
    if(onClick){
      onClick()
    }
  }


  return (
    <>
      <FontAwesomeIcon
        icon={icon}
        onClick={click}
        style={{ color, margin: 3, cursor: onClick ? "pointer": "unset", ...style }}
        data-tooltip-id="tooltip"
        data-tooltip-content={title}
      />
    </>
  );
}

export default IconToolTip;
