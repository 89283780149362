import styled from "styled-components";

export const Parent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5rem;
  grid-row-gap: 10px;
  // align items to the top
  align-items: start;
`;


export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5px;
  // align center
  align-items: center;
  // justify center
  justify-items: center;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

export const ActionButton = styled.div`
  background-color: #b6b6b6;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  line-height: 1;
  color: #ffffff;
  font-size: 1rem;
  &:hover {
    background-color: #e0e0e0;
  }
 
`;