import React from "react";

import * as S from "./styles";

function RenderStatus({ status }) {
  switch (status) {
    case "active":
      return <S.Ativo>Ativo</S.Ativo>;
      break;
    case "inactive":
      return <S.Inativo>Inativo</S.Inativo>;
      break;
    case "preactive":
      return <S.Pendente>Aguardando ativação</S.Pendente>;
      break;
    case "blocked":
      return <S.Bloqueado>Bloqueado</S.Bloqueado>;
      break;

    default:
      return <S.Container>{status}</S.Container>;
      break;
  }
}

export default RenderStatus;
