export const REQ_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
  RELOADING: "reloading",
  SENDING: "sending",
};

export const ROLES = {
  superadmin: "superadmin",
  admin: "admin",
  convenio: "convenio",
  pj: "pj",
  titular: "titular",
  dependente: "dependente",
};

export const USER_STATS = {
  preactive: "preactive",
  active: "active",
  inactive: "inactive",
  pending: "pending",
  blocked: "blocked",
  deleted: "deleted",
  banned: "banned",
}


export const isDEV = process.env.NODE_ENV !== "development" ? false : true;
// export const isDEV = false //process.env.NODE_ENV !== "development" ? false : true;