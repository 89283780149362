import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid ${(props) => props.cor};
  border-radius: 5px;
  margin-right: 15px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex:1;
  cursor: pointer;
`;

export const Nome = styled.div`
  color: ${(props) => props.cor};
  font-weight: bold;
  font-size: 14px;
`;

export const Imagem = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url(${(props) => props.src});
  background-size: cover;
`;

export const Descricao = styled.div`
  font-size: 10px;
  color: #bababa;
`;
