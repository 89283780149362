import React from "react";

import * as S from "./styles";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSpinner, faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { useSelector } from "react-redux";

function BasePage({
  label,
  title,
  color = "#a3a3a3",
  menu,
  children,
  add,
  config,
  loading = false,
}) {
  const { userData } = useSelector((state) => state.auth);
  const { manage_life } = userData;


  return (
    <Container>
      <Row>
        <Col>
          <S.Container>
            <S.TitleContainer>
              <S.TitleLabel color={color}>{label}</S.TitleLabel>
              <S.TitleName color={color}>
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin={true} />
                ) : (
                  title
                )}
              </S.TitleName>
              <S.TitleButtonContainer>
                {(add && manage_life) && (
                  <S.TitleButton color={color} onClick={add}>
                    <FontAwesomeIcon icon={faUserPlus} fixedWidth />
                  </S.TitleButton>
                )}
                {config && (
                  <S.TitleButton color={'#838383'} onClick={config}>
                    <FontAwesomeIcon icon={faCog} />
                  </S.TitleButton>
                )}
              </S.TitleButtonContainer>
            </S.TitleContainer>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin={true} />
            ) : (
              <>
                <S.MenuContainer>{menu}</S.MenuContainer>
                <S.ContentContainer>{children}</S.ContentContainer>
              </>
            )}
          </S.Container>
        </Col>
      </Row>
    </Container>
  );
}

export default BasePage;
