import React from "react";
import * as S from "../../main-styles";
import { ROLES } from "../../../utils/constants";
import {
  formatCNPJ,
  formatCPF,
  formataTelefone,
} from "../../../utils/functions";
import moment from "moment-timezone";
import RenderRole from "../../../components/RenderRole";
import RenderStatus from "../../../components/RenderStatus";
import UserTooltips from "../../../components/UserToolTips";

function PJData({ data }) {
  console.log(data);
  let vidas = data?.is_life ? data?.total_lives + 1 : data?.total_lives;
  if (data?.role !== ROLES.pj && data?.role !== ROLES.convenio) return null;
  const { company } = data;
  return (
    <>
      <S.Title>Dados da Empresa</S.Title>
      <S.InfoContainer>
        <S.GappedContainer>
          <div>
            <S.Label>Razão social</S.Label>
            <S.Text>{company?.razao_social}</S.Text>
          </div>
        </S.GappedContainer>
        <S.GappedContainer>
          <div>
            <S.Label>CNPJ</S.Label>
            <S.Text>{formatCNPJ(company?.cnpj)}</S.Text>
          </div>
        </S.GappedContainer>
        <S.GappedContainer>
          <div>
            <S.Label>Vidas</S.Label>
            <S.Text>{vidas}</S.Text>
          </div>
        </S.GappedContainer>
      </S.InfoContainer>
    </>
  );
}

export default PJData;
