import { toast } from "react-toastify";
import { ROLES } from "../../utils/constants";
import api from "../axios";

export const ACCOUNT_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  BLOCKED: "blocked",
  DELETED: "deleted",
  BANNED: "banned",
};

const AUTH = {
  get: {
    login: async (cpf, password) => {
      try {
        let response = await api.post("/auth/login", { cpf, password });
        console.log("response", response.data);
        // define token
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        return response.data;
      } catch (error) {
        console.log("error==>", error.response.data);
        throw new Error(error.response.data);
      }
    },
    recoveryCode: async(cpf)=>{
      try {
        let response =await api.post("/auth/password-recovery", { cpf });
        console.log("response", response.data);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    },
    getCampaing: async (id) => {
      let response = await api.get(`/finance/campaign/${id}`);
      return response.data;
    },
    checkRecoveryCode: async (token) => {
      try {
        console.log("enviando token", token)
        let response = await api.post("/auth/password-recovery/check", { token });
        console.log("response", response.data);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    },

  },
  set: {
    newPassword: async (token, password) => {
      try {
        let response = await api.post("/auth/password-recovery/set", { token, password });
        console.log("response", response.data);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    },
  },
};

export default AUTH;
