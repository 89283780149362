import styled from "styled-components";

export const Container = styled.div``;

export const Tools = styled.div`
  display: flex;
  gap: 10px;
`;

export const Tool = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: ${(props) => (props.active ? "#e2e2e2" : "#f0f0f0")};
  color: ${(props) => (props.active ? "#525252" : "#000")};
  transition: all 0.5s;
  &:hover {
    background-color: #e6e6e6;
  }
  width: 90px;
`;

export const ToolText = styled.div`
  font-size: 10px;
  line-height: 1;
  // text align center
  text-align: center;
`;

export const ToolIcon = styled.div`
  // all items must overlay each other

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 30px;
  & > * {
    position: absolute;
  }
`;
