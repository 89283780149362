import React from 'react';

// import * as S from './styles';
import { Badge } from 'reactstrap';

function StatusContrato({status}) {
    // status = provisorio | ativo | cancelado | bloqueado | inadimplente | arquivado
    switch (status) {
        case 'provisorio':
            return <Badge color="info" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Provisório</Badge>;
        case 'ativo':
            return <Badge color="success"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Ativo</Badge>;
        case 'cancelado':
            return <Badge color="secondary"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Cancelado</Badge>;
        case 'bloqueado':
            return <Badge color="danger"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Bloqueado</Badge>;
        case 'inadimplente':
            return <Badge color="danger"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Inadimplente</Badge>;
        case 'arquivado':
            return <Badge color="dark"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Arquivado</Badge>;
        case 'expirado':
            return <Badge color="dark"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Expirado</Badge>;
        default:
            return <Badge color="info"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>! {status}</Badge>;
            break;
    }
    
}

export default StatusContrato;