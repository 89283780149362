import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ROLES } from "../../utils/constants";
import {
  faShield,
  faPeopleRoof,
  faBuilding,
  faPeople,
  faUser,
  faHeartPulse,
  faPerson,
} from "@fortawesome/pro-light-svg-icons";

function RoleIcon({ role, small = false }) {
  let icon, color, textColor;
  console.log("role", role);
  switch (role) {
    case ROLES.admin || ROLES.superadmin:
      icon = faShield;
      color = "#323232";
      textColor = "#fff";
      break;
    case ROLES.convenio:
      icon = faPeopleRoof;
      color = "#368ECE";
      textColor = "#fff";
      break;
    case ROLES.pj:
      icon = faBuilding;
      color = "#2DB292";
      textColor = "#fff";
      break;
    default:
      icon = faUser;
      color = "#8C53D5";
      textColor = "#fff";
      break;
  }
  if (small)
    return (
      <div
        style={{
          backgroundColor: color,
          padding: 10,
          borderRadius: 50,
          height: 10,
          width: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon icon={icon} color={"#fff"} size="1x" />
      </div>
    );

  return (
    <div
      style={{
        backgroundColor: color,
        padding: 20,
        borderRadius: 50,
        height: 20,
        width: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FontAwesomeIcon icon={icon} color={"#fff"} size="2x" />
    </div>
  );
}

export default RoleIcon;
