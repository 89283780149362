import React from 'react';

// import * as S from './styles';
import { Badge } from 'reactstrap';

function RenderContractType({role}) {
    console.log("ROLE", role);
    // status = provisorio | ativo | cancelado | bloqueado | inadimplente | arquivado
    switch (role) {
        case 'titular':
            return <Badge color="info" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Pessoa Física</Badge>;
        case 'pj':
            return <Badge color="success"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Pessoa Jurídica</Badge>;
        case 'convenio':
            return <Badge color="secondary"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Convênio</Badge>;
        default:
            return <Badge color="dark"style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{role}</Badge>;
    }
    
}

export default RenderContractType;