import React from "react";

import * as S from "./styles";
import { Col, Container, Row } from "reactstrap";

function Page404() {
  return (
    <Container>
      <Row>
        <Col>
          <S.Container>
            <S.Title>404</S.Title>
            <S.Subtitle>Página não encontrada</S.Subtitle>
          </S.Container>
        </Col>
      </Row>
    </Container>
  );
}

export default Page404;
