import React, { useEffect, useState } from "react";

import * as S from "./styles";
import Spinner from "../Spinner";
import COMPANIES from "../../api/companies";
import PRICING from "../../api/pricing";
import { useSelector } from "react-redux";
import { ROLES } from "../../utils/constants";

function PricingPicker({ role, onConfirm }) {
  const { admin, superadmin } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [precificacoes, setPrecificacoes] = useState([]);
  const [setupFee, setSetupFee] = useState(0);
  const [cupom, setCupom] = useState(null);
  const [trialDays, setTrialDays] = useState(0);

  async function pegaPrecificacao() {
    return;
    setLoading(true);
    let getall = admin || superadmin;
    // let promises = []
    let precificacoes = [];
    switch (role) {
      case ROLES.pj:
        precificacoes = (await getall)
          ? PRICING.get.all_pj()
          : PRICING.get.default_pj();
        break;
      case ROLES.pf:
        precificacoes = (await getall)
          ? PRICING.get.all_pf()
          : PRICING.get.default_pf();
        break;
      case ROLES.convenio:
        precificacoes = await PRICING.get.all_convenio();
        break;
      default:
        precificacoes = []
        break;
    }
    const { adesao, prices } = precificacoes;
    console.log("precificacoes", precificacoes)
    setSetupFee(adesao);
    setPrecificacoes(precificacoes);
  }
  useEffect(() => {
    setLoading(false);
  }, [precificacoes]);

  useEffect(() => {
    pegaPrecificacao();
  }, [role]);

  if (loading) return <Spinner />;

  return <S.Container></S.Container>;
}

export default PricingPicker;
