import api  from "../axios";

const COMPANIES = {
    get: {
        search: async(search)=>{
            let response = await api.get(`/companies/search?search=${search}`);
            return response.data;
        },
        byRef: async(reference_id)=>{
            let response = await api.get(`/companies/byRef/${reference_id}`);
            return response.data;
        },
       
        cnpj_info: async(cnpj)=>{
            // remove all non-digits
            cnpj = cnpj.replace(/\D/g, '');
            let response = await api.get(`/companies/cnpj-info/${cnpj}`);
            return response.data;
        }
    },
    set: {
        new: async(data)=>{
            let response = await api.post(`/contracts/create`, data);
            return response.data;
        }

    }
}

export default COMPANIES;