import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Modal } from "reactstrap";
import CONTRACTS from "../../../api/contracts";
import Spinner from "../../../components/Spinner";
import { REQ_STATUS } from "../../../utils/constants";
import {
  Button,
  COLORS,
  FH,
  Input,
  Subtitle,
  WhiteBox,
} from "../../main-styles";
import RenderRole from "../../../components/RenderRole";
import RenderContractType from "../../../components/RenderContractType";
import { formatCNPJ, formatCPF, toB64 } from "../../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faEye,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faPencil,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { ActionButton } from "./style";
import StatusContrato from "../../../components/StatusContrato";

function Contratos() {
  const [state, setState] = React.useState({
    status: REQ_STATUS.LOADING,
    data: [],
    perPage: 10,
    page: 0,
    search: "",
    sort: "name",
    order: "asc",
    total: 0,
    selectedUser: null,
    filter: "all",
  });
  const navigate = useNavigate();
  const {
    status,
    data,
    perPage,
    page,
    search,
    sort,
    order,
    total,
    selectedUser,
    filter
  } = state;

  useEffect(() => {
    init();
  }, [filter, page, perPage]);
  

  async function init() {
    setState((state) => ({ ...state, status: REQ_STATUS.LOADING }));
    const promises = [CONTRACTS.get.all(filter, page, perPage)];
    const [data] = await Promise.all(promises);
    setState((state) => ({ ...state, data, total, status: REQ_STATUS.IDLE }));
  }

  function setFilter(filter) {
    setState((state) => ({ ...state, filter }));
  }

  function selectUser(id) {
    setState((state) => ({ ...state, selectedUser: id }));
  }

  function closeUser() {
    setState((state) => ({ ...state, selectedUser: null }));
  }

  function gotoContract(id){
    let cid = toB64(id);
    navigate(`/contratos/detalhes/${cid}`);
  }

  return (
    <WhiteBox>
      <Subtitle>Contratos</Subtitle>
      <div
        style={{
          display: "flex",
          paddingBottom: 5,
          marginBottom: 5,
          borderBottom: "1px solid #ccc",
          width: "100%",
        }}
      >
        <Input placeholder="Pesquisar" style={{ marginRight: 5 }} />
        <Button style={{ marginRight: 5 }} color={COLORS.grey}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>
        <Button onClick={() => navigate("/contratos/novo")}>
          Novo Contrato
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          paddingBottom: 5,
          marginBottom: 5,
          borderBottom: "1px solid #ccc",
          width: "100%",
        }}
      >
        <Button style={{ marginRight: 5 }} color={filter === 'all' ? COLORS.main : COLORS.grey} onClick={()=>setFilter('all')}>
          Todos
        </Button>
        <Button style={{ marginRight: 5 }} color={filter === 'ativo' ? COLORS.main : COLORS.grey} onClick={()=>setFilter('ativo')}>
          Ativos
        </Button>
        <Button style={{ marginRight: 5 }} color={filter === 'provisorio' ? COLORS.main : COLORS.grey} onClick={()=>setFilter('provisorio')}>
          Provisório
        </Button>
        <Button style={{ marginRight: 5 }} color={filter === 'bloqueado' ? COLORS.main : COLORS.grey} onClick={()=>setFilter('bloqueado')}>
          Bloqueados
        </Button>
        <Button style={{ marginRight: 5 }} color={filter === 'cancelado' ? COLORS.main : COLORS.grey} onClick={()=>setFilter('cancelado')}>
          Cancelados
        </Button>
        <Button style={{ marginRight: 5 }} color={filter === 'inadimplente' ? COLORS.main : COLORS.grey} onClick={()=>setFilter('inadimplente')}>
          Inadimplentes
        </Button>
      </div>
      {status === REQ_STATUS.LOADING ? (
        <Spinner />
      ) : (
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col" style={{ maxWidth: 40 }}>Nome</th>
              <th scope="col" style={{ width: 40 }}>
                Tipo
              </th>
              <th scope="col" style={{ width: 50 }}>
                Status
              </th>
              <th scope="col" style={{ maxWidth: 30, textAlign: 'center' }} data-tooltip-id="tooltip" data-tooltip-content={'Vidas deste contrato'}>
                VC
              </th>
              <th scope="col" style={{ maxWidth: 30, textAlign: 'center' }}  data-tooltip-id="tooltip" data-tooltip-content={'Vidas de todos as ramificações deste contrato'}>
                VT
              </th>
              <th scope="col" style={{ maxWidth: 60 }}>
                Documento
              </th>
              
              <th scope="col" style={{ width: 30 }}></th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={6}>Nenhum registro encontrado</td>
              </tr>
            )}
            {data.map((contrato) => {
              let isPj = contrato.tipo_contrato !== "titular";
              let nome = isPj ? contrato.empresa_name : contrato.name;
              let documento = isPj
                ? formatCNPJ(contrato.empresa_cnpj)
                : formatCPF(contrato.cpf);

              const status = contrato.status;
              return (
                <tr key={contrato.id}>
                  <td
                    onClick={() => {
                      // selectUser(user);
                    }}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 150,
                    }}
                  >
                    {nome}
                  </td>
                  <td>
                    <RenderContractType role={contrato.tipo_contrato} />
                  </td>
                  <td style={{ textAlign: "center"}}>
                    {<StatusContrato status={contrato.status} />}
                  </td>
                  <td style={{ textAlign: "center" }}>{contrato.vidas}</td>
                  <td style={{ textAlign: "center" }}>{contrato.vidas_total || 0}</td>
                  <td>{documento}</td>
                  {/* <td>{contrato.email}</td> */}
                  <td>
                    <FH>
                        <ActionButton
                          data-tooltip-id="tooltip"
                          data-tooltip-content={"Visualizar Contrato"}
                          color={COLORS.dark_blue}
                          onClick={()=>gotoContract(contrato.id)}
                        >
                          <FontAwesomeIcon icon={faEye} fixedWidth={true} />
                        </ActionButton>
                      {status !== "cancelado" && (
                        <ActionButton
                          data-tooltip-id="tooltip"
                          data-tooltip-content={"Editar Contrato"}
                          color={COLORS.dark_blue}
                        >
                          <FontAwesomeIcon icon={faPencil} fixedWidth={true} />
                        </ActionButton>
                      )}
                      {status === "provisorio" && (
                        <ActionButton
                          data-tooltip-id="tooltip"
                          data-tooltip-content={"Ativar Contrato"}
                          color={COLORS.dark_green}
                        >
                          <FontAwesomeIcon
                            icon={faBoltLightning}
                            fixedWidth={true}
                          />
                        </ActionButton>
                      )}
                      {status === "ativo" && (
                        <ActionButton
                          data-tooltip-id="tooltip"
                          data-tooltip-content={"Bloquear Contrato"}
                          color={COLORS.red}
                        >
                          <FontAwesomeIcon icon={faLock} fixedWidth={true} />
                        </ActionButton>
                      )}
                      {status === "bloqueado" && (
                        <>
                          <ActionButton
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Desbloquear Contrato"}
                            color={COLORS.dark_green}
                          >
                            <FontAwesomeIcon
                              icon={faLockOpen}
                              fixedWidth={true}
                            />
                          </ActionButton>
                          <ActionButton
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Cancelar Contrato"}
                            color={COLORS.red}
                          >
                            <FontAwesomeIcon icon={faTrash} fixedWidth={true} />
                          </ActionButton>
                        </>
                      )}
                      {status === "cancelado" && (
                        <>
                          <ActionButton
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Arquivar Contrato"}
                            color={COLORS.red}
                          >
                            <FontAwesomeIcon icon={faTrash} fixedWidth={true} />
                          </ActionButton>
                        </>
                      )}
                    </FH>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </WhiteBox>
  );
}

export default Contratos;
