import React from "react";
import { Container, Row, Col } from "reactstrap";
import * as S from "./styles";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth/auth_slice";
import { ROLES } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faHome,
  faShield,
  faStethoscope,
  faUser,
  faUserDoctor,
  faUserNurse,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";

function SubHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const { authenticated, userData } = auth;
  console.log("userData", userData);
  const role = userData?.role;
  const is_life = userData?.is_life;
  const active_life = userData?.active_life;

  switch (role) {
    case ROLES.superadmin:
    case ROLES.admin:
      return (
        <>
          <Col md={12}>
            <S.Menu>
              <S.MenuItem to="/">
                <FontAwesomeIcon icon={faHome} size={"lg"} />
                Principal
              </S.MenuItem>
              {active_life && (
                <S.MenuItem to="https://telemedicina.lincesaude.com.br">
                  <FontAwesomeIcon icon={faStethoscope} size={"lg"} />
                  Iniciar Atendimento
                </S.MenuItem>
              )}
              <S.MenuItem to="/admin">
                <FontAwesomeIcon icon={faShield} size={"lg"} />
                Administração
              </S.MenuItem>
            </S.Menu>
          </Col>
        </>
      );
      break;

    case ROLES.convenio:
      return (
        <>
          <Col md={12}>
            <S.Menu>
              <S.MenuItem to="/">
                <FontAwesomeIcon icon={faHome} size={"lg"} />
                Principal
              </S.MenuItem>
              {active_life && (
                <S.MenuItem to="https://telemedicina.lincesaude.com.br">
                  <FontAwesomeIcon icon={faStethoscope} size={"lg"} />
                  Iniciar Atendimento
                </S.MenuItem>
              )}
              <S.MenuItem to="/contratos">
                <FontAwesomeIcon icon={faFile} size={"lg"} />
                Contratos
              </S.MenuItem>
            </S.Menu>
          </Col>
        </>
      );
      break;
    case ROLES.pj:
      return (
        <>
          <Col md={12}>
            <S.Menu>
              <S.MenuItem to="/">
                <FontAwesomeIcon icon={faHome} size={"lg"} />
                Principal
              </S.MenuItem>
              {active_life && (
                <S.MenuItem to="https://telemedicina.lincesaude.com.br">
                  <FontAwesomeIcon icon={faStethoscope} size={"lg"} />
                  Iniciar Atendimento
                </S.MenuItem>
              )}
              <S.MenuItem to="/contratos">
                <FontAwesomeIcon icon={faUser} size={"lg"} />
                Contratos
              </S.MenuItem>
            </S.Menu>
          </Col>
        </>
      );
      break;

    case ROLES.titular:
      return (
        <>
          <Col md={8}/>
          <Col md={3} style={{marginTop: 20}}>
            {active_life && (
              <S.MenuItem to="https://telemedicina.lincesaude.com.br">
                <FontAwesomeIcon icon={faStethoscope} size={"lg"} />
                Iniciar Atendimento
              </S.MenuItem>
            )}
          </Col>
        </>
      );
      break;
    case ROLES.dependente:
      return (
        <>
          <Col md={12}>
            <S.Menu>
              <S.MenuItem to="/">
                <FontAwesomeIcon icon={faHome} size={"lg"} />
                Principal
              </S.MenuItem>

              {active_life && (
                <S.MenuItem to="https://telemedicina.lincesaude.com.br">
                  <FontAwesomeIcon icon={faStethoscope} size={"lg"} />
                  Iniciar Atendimento
                </S.MenuItem>
              )}
            </S.Menu>
          </Col>
        </>
      );
      break;

    default:
      return (
        <>
          <Col md={12}>
            <S.Menu></S.Menu>
          </Col>
        </>
      );
  }
}

export default SubHeader;
