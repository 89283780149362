import styled from 'styled-components';

export const Container = styled.div`
    
`;
export const Tag = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
width: 100%;
`;
export const TagTitle = styled.div``;
export const TagInput = styled.input`
width: 100%;
border: 1px solid #e6e6e6;
border-radius: 8px;
padding: 10px 20px;
color: #717171;
font-size: 16px;
font-weight: bold;
background-color: ${props=>props.color || '#ffffff'};
`;
