import styled from 'styled-components';

export const Container = styled.div`
     // center content to the middle of the page
    display: flex;
    justify-content: center;
    align-items: center;
    // full height of the page, no scrolling
    height: 90vh;
    flex-direction: column;
`;

export const CenteredContainer = styled.div`
      // 1 column grid
      display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
`;

export const FormContainer = styled.div`
    // 1 column grid
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
`;