import React from "react";

import * as S from "./styles";
import UserBox from "../UserBox";
import { toB64 } from "../../utils/functions";
import { useNavigate } from "react-router-dom";

function Childrens({ items }) {
  const navigate = useNavigate();
  // deve iterar entre todos os itemss
  console.log("items", items);
  if (!items) return null;
  function gotoUser(id) {
    let url = `/u/${toB64(id)}`;
    navigate(url);
  }
  return (
    <S.Container>
      {items.map((user) => {
        return <UserBox user={user} onClick={() => gotoUser(user.id)} />;
      })}
    </S.Container>
  );
}

export default Childrens;
