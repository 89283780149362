import styled from "styled-components";

export const Container = styled.div``;

export const MedicalContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 15px;
  background-color: #eafafa;
  padding: 10px;
  border-radius: 10px;
  color: #4e4e4e;
  font-size: 15px;
`;

export const MedicalICon = styled.div`
  height: 50px;
  width: 50px;
  padding: 5px;
  border-radius: 50%;
  background-color: #b00909;
  display: flex;
  justify-content: center;
  align-items: center;
`;
