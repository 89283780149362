import styled from "styled-components";

export const Container = styled.div`
  background-color: #797979;
  color: #ffff;
  padding: 3px 6px;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
`;

export const Superadmin = styled(Container)`
    background-color: #000000;
`;

export const Admin = styled(Container)`
    background-color: #929292;
`;

export const Convenio = styled(Container)`
    background-color: #368ECE;
`;

export const PJ = styled(Container)`
    background-color: #2DB292;
`;


export const Titular = styled(Container)`
    background-color: #8C53D5;
`;

