import React, { useEffect, useMemo, useRef, useState } from "react";

import * as S from "./styles";
import { Form, NavLink, useParams } from "react-router-dom";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import {
  COLORS,
  Description,
  FH,
  FHSB,
  FormContainer,
  Input,
  Label,
  Link,
  Text,
  Title,
  WhiteBox,
} from "../../main-styles";

import Select from "react-select";
import { REQ_STATUS, ROLES } from "../../../utils/constants";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTurnDownRight,
  faBoltLightning,
  faCheck,
  faCheckSquare,
  faCloudArrowDown,
  faCloudArrowUp,
  faDownload,
  faEye,
  faHeartCirclePlus,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faPencil,
  faSave,
  faSign,
  faSignature,
  faSpinner,
  faSquare,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faTurnDownRight,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import {
  ExcelDateToJSDate,
  formatCNPJ,
  formatCPF,
  fromB64,
  getContractId,
  validateCPF,
} from "../../../utils/functions";
import StatusContrato from "../../../components/StatusContrato";
import COMPANIES from "../../../api/companies";
import USERS from "../../../api/users";
import Spinner from "../../../components/Spinner";
import Vida from "./Vida";
import { useSelector } from "react-redux";
import { PatternFormat } from "react-number-format";
import PRICING from "../../../api/pricing";
import CONTRACTS from "../../../api/contracts";
import RenderContractType from "../../../components/RenderContractType";
import { ActionButton } from "../../Admin/Contratos/style";

function Review() {
  const { uuid, mine, edit } = useParams();
  const topRef = useRef(null);
  const tableRef = useRef(null);
  const [acceptTU, setAcceptTU] = useState(false);
  const [acceptPP, setAcceptPP] = useState(false);
  const [checkoutLink, setCheckoutLink] = useState(null);
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    centro_de_custos: [],
    edit_vida_selected: null,
    new_vida_visible: false,
    tipos_de_contrato: [],
    contrato: {
      tipo: "",
      id: uuid,
      role: "",
      numero_proposta: "xxxx-xxxx-xxxx-xxxx",
      numero_contrato: "xxxx-xxxx-xxxx-xxxx",
      data_criacao: moment().format("YYYY-MM-DD"),
      data_assinatura: "",
      data_cancelamento: "",
      convenio: {
        id: "",
        nome: "",
      },
      centro_de_custo: null,
      vidas: [],
      status: "provisorio", // provisorio, ativo, cancelado e arquivado
      valor: "",
      stripe_subscription_id: "",
      stripe_client_id: "",
      validado: false,
      validando: false,
    },
    company: {
      cnpj: "",
      razao_social: "-",
      nome_fantasia: "-",
      responsavel: "",
      cpf_responsavel: "",
      email: "",
      telefone: "",
      validado: false,
      validando: false,
    },
    precificacao: {},
    carregando_precificacao: true,
  });
  const {
    contrato,
    contrato: {
      numero_proposta,
      numero_contrato,
      data_criacao,
      data_assinatura,
      data_cancelamento,
      convenio,
      centro_de_custo,
      vidas,
      status,
      valor,
      stripe_subscription_id,
      stripe_client_id,
      tipo,
      responsavel,
      cpf_responsavel,
      email,
      telefone,
    },
    status: pageStatus,
    centro_de_custos,
    edit_vida_selected,
    new_vida_visible,
    tipos_de_contrato,
    company,
    taxa_adesao,
    precificacao,
    carregando_precificacao,
  } = state;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    await loadContract();
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
    }));
  }

  async function getCheckoutLink() {
    let checkoutlink = await CONTRACTS.get.checkoutLink(uuid);
    console.log("CHECKOUT LINK", checkoutlink);
    setCheckoutLink(checkoutlink.url);
  }

  async function loadContract() {
    getCheckoutLink();
    console.log("CARREGANDO CONTRATO", uuid);
    // carrega o contrato
    let contract = await CONTRACTS.get.byUUID(uuid);

    let contractId = getContractId(contract);
    let convenio = {
      id: "",
      nome: "",
    };

    setState((s) => ({
      ...S,
      centro_de_custos: contract.centro_de_custo,
      tipos_de_contrato: [],
      contrato: {
        tipo: contract.tipo_contrato,
        id: contract.id,
        role: contract.tipo_contrato,
        numero_proposta: contract.stripe_quote_id,
        numero_contrato: contractId,
        data_criacao: contract?.data_criacao,
        data_assinatura: contract?.data_assinatura,
        data_cancelamento: contract?.data_cancelamento,
        convenio,
        centro_de_custo: contract?.centro_de_custo,
        vidas: contract?.vidas_json,
        status: contract.status, // provisorio, ativo, cancelado e arquivado
        valor: "",
        stripe_subscription_id: "",
        stripe_client_id: "",
        validado: false,
        validando: false,
      },
      company: contract?.company || {},
      precificacao: {},
      taxa_adesao: contract?.taxa_adesao,
    }));

    await pegaPrecificacao(contract?.tipo_contrato);
    return true;
    // carrega a empresa
    // carrega as vidas
    // carrega os pagamentos
    // carrega os documentos
  }
  async function pegaPrecificacao(tipo) {
    console.log("vai buscar a precificacao do tipo", tipo);
    setState((s) => {
      return {
        ...s,
        carregando_precificacao: true,
        precificacao: null,
      };
    });
    // se tive um subscription id, deve buscar a precificação aplicada
    if (stripe_subscription_id) {
      // TODO: PEGA PRACIFICACAO APLICADA
      // let precificacao = await USERS.get.precificacao(stripe_subscription_id);
      // console.log("PRECIFICACAO", precificacao);
      return {
        valor: 0,
      };
    }
    // se não tiver subscription id, deve buscar a precificação do tipo de contrato: pf, pj ou convenio
    let precificacao;
    switch (tipo) {
      case ROLES.titular:
        precificacao = await PRICING.get.default_pf();
        console.log("PRECIFICACAO PF", precificacao);
        precificacao = precificacao.prices[0];
        break;
      case ROLES.pj:
        precificacao = await PRICING.get.default_pj();

        console.log("PRECIFICACOES PJ", precificacao);
        precificacao = precificacao.prices.find(
          (a) => a.lookup_key === "pj_avulso"
        );
        console.log("PRECIFICACAO PJ", precificacao);
        break;
      case ROLES.convenio:
        precificacao = await PRICING.get.default_pj();
        precificacao = precificacao.prices.find(
          (a) => a.lookup_key === "pj_convenio"
        );
        console.log("PRECIFICACAO convenio", precificacao);
        break;
      default:
        console.log("TIPO DE CONTRATO NÃO ENCONTRADO", tipo);
        break;
    }
    setState((s) => {
      return {
        ...s,
        precificacao,
        carregando_precificacao: false,
      };
    });
  }

  function pegaPrecificacaoUsuario(numero) {
    console.log("pegando precificacao usuario", numero);
    console.log("pegando precificacao precificacao", precificacao);
    numero = numero + 1;
    if (!precificacao?.tiers) return null;
    let precificacoes = precificacao?.tiers;
    console.log("PRECIFICACAO USADA", precificacao);
    for (let i = 0; i < precificacoes?.length; i++) {
      if (precificacoes[i].up_to === null || numero <= precificacoes[i].up_to) {
        return precificacoes[i].unit_amount;
      }
    }

    // Se não encontrar uma precificação adequada, retorna null ou um valor padrão
    return null;
  }

  function ordenaVidas(vidas) {
    let titulares = vidas
      .filter((v) => !v.titular)
      .sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });
    let dependentes = vidas
      .filter((v) => v.titular)
      .sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });
    vidas = titulares
      .map((titular) => {
        let dependentesDoTitular = dependentes.filter(
          (d) => d.titular.id === titular.id
        );
        return [titular, ...dependentesDoTitular];
      })
      .flat();
    return vidas;
  }

  function updateContrato() {}

  const totalPrice = useMemo(() => {
    if (vidas.length === 0) return 0;
    if (!precificacao?.tiers) return 0;
    let titulares = vidas.filter((v) => !v.titular);
    let precotitular = pegaPrecificacaoUsuario(0);
    let preco = titulares.reduce((prev, curr) => {
      prev += precotitular;
      let dependentes = vidas.filter((v) => v.titular.id === curr.id);
      dependentes.map((d, i) => {
        prev += pegaPrecificacaoUsuario(i + 1);
        return d;
      });
      return prev;
    }, 0);
    return preco / 100;
  }, [vidas, precificacao, pegaPrecificacaoUsuario]);

  const total = useMemo(() => {
    if (vidas.length === 0) return 0;
    let titulares = vidas.filter((v) => !v.titular)?.length;
    let total = totalPrice;
    total += taxa_adesao * titulares;
    return total;
  }, [totalPrice, taxa_adesao]);

  if (pageStatus === REQ_STATUS.LOADING) {
    return (
      <Container>
        <Row>
          <Col>
            <Spinner /> carregando...
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: 15 }}>
      <Row>
        <Col>
          <WhiteBox ref={topRef}>
            <Title>Proposta</Title>

            <S.FormFields>
              <Field
                id="numero_proposta"
                name="Número da Proposta"
                value={numero_proposta || "-"}
                onChange={(e) =>
                  updateContrato("numero_proposta", e.target.value)
                }
                type={"info"}
              />
              <Field
                id="numero_contrato"
                name="Número do Contrato"
                value={numero_contrato}
                onChange={(e) =>
                  updateContrato("numero_contrato", e.target.value)
                }
                type={"info"}
              />
              <Field
                id="data_criacao"
                name="Data de Criação"
                value={moment(data_criacao).format("DD/MM/YYYY")}
                onChange={(e) => updateContrato("data_criacao", e.target.value)}
                type="info"
              />
              {data_assinatura && (
                <Field
                  id="data_assinatura"
                  name="Data de Assinatura"
                  value={moment(data_assinatura).format("DD/MM/YYYY")}
                  onChange={(e) =>
                    updateContrato("data_assinatura", e.target.value)
                  }
                  type="info"
                />
              )}
              {status === "cancelado" && (
                <Field
                  id="data_cancelamento"
                  name="Data de Cancelamento"
                  value={data_cancelamento}
                  onChange={(e) =>
                    updateContrato("data_cancelamento", e.target.value)
                  }
                  type="info"
                />
              )}
              <FormContainer>
                <Label>Status</Label>
                <StatusContrato status={status} />
              </FormContainer>
              <FormContainer>
                <Label>Tipo de Contrato</Label>
                <RenderContractType role={contrato.tipo} />
              </FormContainer>

              <>
                {stripe_client_id && (
                  <Field
                    id="stripe_subscription_id"
                    name="Stripe Subscription ID"
                    value={stripe_subscription_id}
                    onChange={(e) =>
                      updateContrato("stripe_subscription_id", e.target.value)
                    }
                    type="info"
                  />
                )}
                {stripe_client_id && (
                  <Field
                    id="stripe_client_id"
                    name="Stripe Client ID"
                    value={stripe_client_id}
                    onChange={(e) =>
                      updateContrato("stripe_client_id", e.target.value)
                    }
                    type="info"
                  />
                )}
              </>
            </S.FormFields>
          </WhiteBox>
          {company?.type && (
            <ExtraData
              tipo={contrato?.tipo_contrato}
              updateCompany={() => {}}
              company={company}
            />
          )}
          <WhiteBox ref={tableRef}>
            <Title>Vidas</Title>

            <table
              className="table table-striped"
              style={{ borderRadius: 4, overflow: "hidden" }}
            >
              <thead>
                <tr>
                  <th>Nome</th>
                  <th></th>
                  <th>CPF</th>
                  <th>Email</th>
                  <th style={{ width: 100 }}>Nascimento</th>
                </tr>
              </thead>
              <tbody>
                {vidas.length === 0 && (
                  <tr>
                    <td colSpan={7}>Nenhuma vida cadastrada</td>
                  </tr>
                )}
                {vidas.map((vida, index) => {
                  return (
                    <tr key={index}>
                      {vida?.titular && (
                        <td>
                          <FontAwesomeIcon
                            icon={faArrowTurnDownRight}
                            style={{ marginLeft: 8 }}
                          />
                        </td>
                      )}

                      <td
                        colSpan={!vida?.titular ? 2 : 1}
                        style={{ textTransform: "capitalize" }}
                      >
                        {vida.name || vida.nome}
                      </td>
                      <td
                        style={vida.cpfError ? { color: COLORS.dark_red } : {}}
                      >
                        <FH>{formatCPF(vida.cpf)}</FH>
                      </td>
                      <td
                        style={
                          vida.emailError
                            ? {
                                color: COLORS.dark_red,
                                textTransform: "lowercase",
                              }
                            : { textTransform: "lowercase" }
                        }
                      >
                        <FH>{vida.email}</FH>
                      </td>
                      <td>{moment(vida.nascimento).format("DD/MM/YYYY")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </WhiteBox>
          <WhiteBox>
            <Title>Valores</Title>
            <Container>
              <Row>
                <Col md={4}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: 5, fontWeight: "bold" }}>
                          Tabela de Preços
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        {carregando_precificacao && (
                          <tr>
                            <td colSpan={2}>
                              <Spinner /> Atualizando tabela de preços
                            </td>
                          </tr>
                        )}
                      </tr>
                      <RenderTiers tiers={precificacao?.tiers} />
                    </tbody>
                  </table>
                </Col>
                <Col md={7}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: 5, fontWeight: "bold" }}>
                          Mensalidades
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      {vidas.length === 0 && (
                        <tr>
                          <td colSpan={3} style={{ padding: 5 }}>
                            <Label>Nenhuma vida adicionada</Label>
                          </td>
                        </tr>
                      )}
                      <RenderVidas
                        vidas={vidas}
                        pegaPrecificacaoUsuario={pegaPrecificacaoUsuario}
                      />
                      <tr
                        style={{
                          borderBottom: "1px solid #8a8a8a",
                          fontWeight: "bold",
                        }}
                      >
                        <td style={{ padding: 5 }}>SubTotal</td>
                        <td>
                          <Label>Mensal</Label>
                        </td>
                        <td>
                          {parseFloat(totalPrice)?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                      {vidas.length > 0 && (
                        <>
                          <tr style={{ borderBottom: "1px solid #8a8a8a" }}>
                            {vidas.filter((v) => !v.titular).length > 1 ? (
                              <td style={{ padding: 5 }}>
                                Taxa de Adesão (
                                {parseFloat(taxa_adesao)?.toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}{" "}
                                {` x`}
                                {vidas.filter((v) => !v.titular).length})
                              </td>
                            ) : (
                              <td style={{ padding: 5 }}>Taxa de Adesão</td>
                            )}

                            <td>
                              <Label style={{ fontWeight: "bold" }}>
                                TAXA ÚNICA
                              </Label>
                            </td>
                            <td>
                              {parseFloat(
                                vidas.filter((v) => !v.titular).length *
                                  parseFloat(taxa_adesao)
                              )?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                          </tr>
                        </>
                      )}
                      <tr style={{ fontWeight: "bold" }}>
                        <td style={{ padding: 5 }}>Total</td>
                        <td></td>
                        <td>
                          {parseFloat(total)?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md={1}></Col>
              </Row>
            </Container>
          </WhiteBox>
          <WhiteBox>
            <FH
              onClick={() => setAcceptTU(!acceptTU)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={acceptTU ? faCheckSquare : faSquare}
                style={{ marginRight: 8 }}
              />
              <Label>
                Declaro que li e estou de acordo com o{" "}
                <a href="" style={{ color: COLORS.main }}>
                  Termos de uso
                </a>
              </Label>
            </FH>
            <FH
              onClick={() => setAcceptPP(!acceptPP)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={acceptPP ? faCheckSquare : faSquare}
                style={{ marginRight: 8 }}
              />
              <Label>
                Declaro que li e estou de acordo com a{" "}
                <a href="" style={{ color: COLORS.main }}>
                  Políticas de privacidade
                </a>
              </Label>
            </FH>
          </WhiteBox>
          <WhiteBox>
            <FHSB style={{ width: "100%" }}>
              <div />
              {(checkoutLink && acceptPP && acceptTU ) ? (
                <Button
                  style={{
                    backgroundColor: COLORS.main,
                    border: 0,
                    padding: 25,
                    paddingTop: 15,
                    paddingBottom: 15,
                    fontSize: 20,
                  }}
                  href={checkoutLink}
                >
                  <FontAwesomeIcon
                    icon={faSignature}
                    size={"1x"}
                    style={{ marginRight: 8 }}
                  />
                  Aceitar proposta
                </Button>
              ):<Button
              style={{
                backgroundColor: '#aaa',
                border: 0,
                padding: 25,
                paddingTop: 15,
                paddingBottom: 15,
                fontSize: 20,
              }}
            >
              <FontAwesomeIcon
                icon={faSignature}
                size={"1x"}
                style={{ marginRight: 8 }}
              />
              Aceitar proposta
            </Button>}
            </FHSB>
          </WhiteBox>
        </Col>
      </Row>
    </Container>
  );
}

function ExtraData({ company }) {
  console.log("company", company);

  switch (company?.type) {
    case ROLES.pj:
    case ROLES.convenio:
      return (
        <WhiteBox id="company-data">
          <Container>
            <Row>
              <Col md={12}>
                <Title>Informações da Empresa</Title>
                <Field
                  id="CNPJ"
                  name="CNPJ"
                  value={formatCNPJ(company.document)}
                  onChange={(e) => {}}
                  type="info"
                  style={{ marginTop: 5 }}
                />
                <Field
                  id="razao_social"
                  name="Razão Social"
                  value={company.name}
                  type="info"
                  style={{ marginTop: 5 }}
                />
              </Col>
            </Row>
          </Container>
        </WhiteBox>
      );

      break;

    default:
      return null;
      break;
  }
}

function Field({ style = {}, id, name, value, onChange, type = "text" }) {
  return (
    <FormContainer style={style}>
      <Label>{name}</Label>
      {type === "info" ? (
        <Text>{value}</Text>
      ) : (
        <Input id={id} value={value} onChange={onChange} type={type} />
      )}
    </FormContainer>
  );
}

function RenderTiers({ tiers }) {
  if (!tiers)
    return (
      <tr>
        <td colSpan={2}>-</td>
      </tr>
    );
  return tiers.map((tier) => {
    return (
      <tr>
        <td>{tier?.up_to ? tier?.up_to : "Demais"} vidas</td>
        <td>
          {parseFloat(tier?.unit_amount / 100).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </td>
      </tr>
    );
  });
}

function RenderVidas({ vidas, pegaPrecificacaoUsuario }) {
  const titulares = vidas.filter((v) => !v.titular);

  return (
    <>
      {titulares.map((vida, index) => {
        const precouser = pegaPrecificacaoUsuario(0);
        const dependentes = vidas.filter((v) => v.titular.id === vida.id);
        return (
          <>
            <tr key={index} style={{ borderBottom: "1px dashed #e7e7e7" }}>
              <td style={{ padding: 5 }}>
                <Text>{vida.nome}</Text>
                <Label>CPF {formatCPF(vida.cpf)}</Label>
              </td>
              <td>
                <Label>Titular</Label>
              </td>
              <td>
                {parseFloat(precouser / 100)?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            {dependentes.map((dep, i) => {
              console.log("DEPENDENTE", dep);
              console.log("DEPENDENTE #", i);
              const precouser = pegaPrecificacaoUsuario(i + 1);
              return (
                <tr key={i} style={{ borderBottom: "1px dashed #e7e7e7" }}>
                  <td style={{ padding: 5 }}>
                    <Text>{dep.nome}</Text>
                    <Label>CPF {formatCPF(dep.cpf)}</Label>
                  </td>
                  <td>
                    <Label>Dependente</Label>
                  </td>
                  <td>
                    {parseFloat(precouser / 100)?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                </tr>
              );
            })}
          </>
        );
      })}
    </>
  );
}

export default Review;
