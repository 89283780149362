import React, { useContext, useState } from "react";

import * as S from "../styles";
import { useSelector } from "react-redux";
import { ROLES } from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faStethoscope } from "@fortawesome/pro-light-svg-icons";
import { HomeContext } from "..";
import { Text, WhiteBox } from "../../main-styles";
import NovaAssinatura from "../../NovaAssinatura";
import Listagem from "./Listagem";
import NovaEmpresa from "../../NovaEmpresa";

function PJ() {
  const { loading, goto, showModal, lifes } = useContext(HomeContext);
  const { userData } = useSelector((state) => state.auth);
  const { is_life, manage_life, is_billing, role } = userData;
  const [novaAssinaturaVisible, setNovaAssinaturaVisible] = useState(false);
  const [novaEmpresaVisible, setNovaEmpresaVisible] = useState(false);

  function novaAssinatura() {
    setNovaAssinaturaVisible(true);
  }

  function novaEmpresa() {
    setNovaEmpresaVisible(true);
  }

  function closeNovaEmpresa() {
    setNovaEmpresaVisible(false);
  }

  function close() {
    setNovaAssinaturaVisible(false);
  }

  async function callback(resultado) {
    console.log(resultado);
  }
  async function callbackNovaEmpresa(resultado) {
    console.log(resultado);
  }

  return (
    <S.UltraContent>
      <WhiteBox>
        <S.Title>Titulares</S.Title>
        <Text>
          Abaixo você encontrará todos os titulares diretamente relacionados à
          sua empresa.
        </Text>
        {manage_life && (
          <S.Button onClick={novaAssinatura}>
            <FontAwesomeIcon icon={faPlus} size="lg" />
            Nova Assinatura
          </S.Button>
        )}
        <Listagem role={ROLES.titular} />
      </WhiteBox>
      {role === ROLES.convenio && (
        <WhiteBox>
          <S.Title>Empresas conveniadas</S.Title>
          <Text>
            Abaixo você encontrará todas as empresas vinculadas ao seu convênio.
          </Text>
          <S.Button onClick={novaEmpresa}>
            <FontAwesomeIcon icon={faPlus} size="lg" />
            Nova Empresa
          </S.Button>
          <Listagem role={ROLES.pj} />
        </WhiteBox>
      )}
      {novaAssinaturaVisible && (
        <NovaAssinatura
          isOpen={novaAssinaturaVisible}
          callback={callback}
          close={close}
        />
      )}
      {novaEmpresaVisible && (
        <NovaEmpresa
          isOpen={novaEmpresaVisible}
          callback={callbackNovaEmpresa}
          close={closeNovaEmpresa}
        />
      )}
    </S.UltraContent>
  );
}

export default PJ;
