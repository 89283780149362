import React, { useEffect, useState } from "react";

import {
  faBuilding,
  faTimesCircle,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  Button,
  Description,
  FHSB,
  FormContainer,
  HG,
  Input,
} from "../../routes/main-styles";
import { REQ_STATUS } from "../../utils/constants";
import Entidade from "../Entidade";
import IconToggler from "../IconToggler";
import * as S from "./styles";
import USERS from "../../api/users";
import Spinner from "../Spinner";
import COMPANIES from "../../api/companies";
import { formatCNPJ, formatCPF } from "../../utils/functions";
function ParentSelect({ id, tipo = "pf", onSelect }) {
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    info: null,
    search: "",
    type: tipo === null ? "pf" : tipo,
    isOpen: false,
    results: [],
    status: REQ_STATUS.IDLE,
  });

  const { status, info, type, search, isOpen, results } = state;

  useEffect(() => {
    if (id !== info?.id) {
      buscarDados();
    }
  }, [id]);

  async function buscarDados() {
    // se não tiver info deve buscar quando...
    if (id) {
      // se tiver value, busca o parent
      if (type === "pf") {
        // busca parent pessoa física pelo id
      } else {
        // busca parent pessoa jurídica pelo id
      }
    }
  }

  function busca() {
    if (search === "") {
      // busca parent pelo search, ignorando o id
      if (type === "pf") {
        toast.warning("Digite um nome, email ou cpf para buscar");
        return;
      } else {
        toast.warning("Digite um nome de empresa, empresa ou cnpj para buscar");
        return;
      }
    }
    buscaOpcoes();
  }

  function buscaOpcoes() {
    // busca pelo search
    if (status === REQ_STATUS.LOADING) return;
    setState((state) => ({
      ...state,
      status: REQ_STATUS.LOADING,
      results: [],
    }));
    if (type === "pf") {
      // busca parent pessoa física pelo search
      buscarPessoaFisica();
    } else {
      buscarPessoaJuridica();
    }
  }

  async function buscarPessoaJuridica() {
    // busca usuario
    let results = await COMPANIES.get.search(search);
    console.log("results", results);
    setState((state) => ({ ...state, results, status: REQ_STATUS.SUCCEEDED }));
  }

  async function buscarPessoaFisica() {
    // busca usuario
    let results = await USERS.get.search(search);
    console.log("results", results);
    setState((state) => ({ ...state, results, status: REQ_STATUS.SUCCEEDED }));
  }

  function setType(type) {
    setState((state) => ({
      ...state,
      type,
      results: [],
      status: REQ_STATUS.IDLE,
    }));
  }

  function setSearch(value) {
    setState((state) => ({
      ...state,
      search: value,
      results: [],
      status: REQ_STATUS.IDLE,
    }));
  }

  function openSearch(e) {
    e.preventDefault();
    setState((state) => ({ ...state, isOpen: true }));
  }

  function deselect() {
    setState((state) => ({
      ...state,
      info: null,
      search: "",
      status: REQ_STATUS.IDLE,
    }));
  }

  function closeSearch() {
    setState((state) => ({ ...state, isOpen: false }));
  }

  function select(result) {
    setState((state) => ({
      ...state,
      isOpen: false,
      info: result,
      search: "",
      status: REQ_STATUS.IDLE,
    }));
    onSelect(result);
  }

  const isPF = type === "pf";
  console.log("type", type);
  return (
    <S.Container>
      {info ? (
        <FHSB>
          {isPF ? (
            <Entidade
              imagem={info?.imagem}
              nome={info?.name}
              descricao={info?.cpf}
              tipo={"pf"}
            />
          ) : (
            <Entidade
              imagem={info?.imagem}
              nome={info?.name}
              descricao={info?.document}
              tipo={"pj"}
            />
          )}
          <div onClick={deselect} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faTimesCircle} color={"#cd0707"} />
          </div>
        </FHSB>
      ) : (
        <Button onClick={openSearch}>Selecionar</Button>
      )}
      {isOpen && (
        <Modal isOpen={isOpen} toggle={closeSearch}>
          <ModalHeader toggle={closeSearch}>Selecionar Vínculo</ModalHeader>
          <ModalBody>
            <HG>
              <IconToggler
                icon={faUser}
                name="Pessoa Física"
                selected={type === "pf"}
                toggle={() => setType("pf")}
              />
              <IconToggler
                icon={faBuilding}
                name="Pessoa Jurídica"
                selected={type === "pj"}
                toggle={() => setType("pj")}
              />
            </HG>
            <hr />
            <FHSB>
              <Input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={
                  type === "pf"
                    ? "Digite um nome, email ou cpf para buscar"
                    : "Digite um nome de empresa, empresa ou cnpj para buscar"
                }
              />
              <Button style={{ marginLeft: 5 }} onClick={busca}>
                Buscar
              </Button>
            </FHSB>
            <FormContainer>
              <hr />
              {status === REQ_STATUS.LOADING && <Spinner />}
              {results.length === 0 && status === REQ_STATUS.SUCCEEDED && (
                <Description>Nenhum resultado encontrado</Description>
              )}
              {results.map((result) => {
                return (
                  <Entidade
                    imagem={result?.imagem}
                    nome={result?.name}
                    descricao={isPF ? formatCPF(result?.cpf) : formatCNPJ(result?.document)}
                    tipo={type}
                    onSelect={() => select(result)}
                  />
                );
              })}
            </FormContainer>
          </ModalBody>
        </Modal>
      )}
    </S.Container>
  );
}

export default ParentSelect;
