import React from "react";

import * as S from "../../styles";
import { ModalBody, ModalHeader } from "reactstrap";
import {
  Button,
  COLORS,
  FH,
  FormContainer,
  Input,
  Label,
  Title,
} from "../../../main-styles";
import { PatternFormat } from "react-number-format";
import { FormFields } from "../styles";
import moment from "moment-timezone";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { validateCPF } from "../../../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faSquare,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/pro-light-svg-icons";

function Vida({ data, onChange }) {
  const [state, setState] = React.useState(data);

  function save() {
    if (state.nome.split(" ").length < 2) {
      toast.error("Nome inválido");
      return;
    }
    if (validateCPF(state.cpf) === false) {
      toast.error("CPF inválido");
      return;
    }
    if (moment(state.nascimento).isAfter(moment())) {
      toast.error("Data de nascimento inválida");
      return;
    }
    if (state.email.indexOf("@") === -1 && state.email.indexOf(".") === -1) {
      toast.error("Email inválido");
      return;
    }

    onChange(data.id, { ...state, validated: true });
  }

  function toggleBilling() {
    setState((state) => ({ ...state, billing: !state.billing }));
  }

  return (
    <>
      <ModalHeader>
        <Title style={{ lineHeight: 1, margin: 0, padding: 0 }}>Vida</Title>
      </ModalHeader>
      <ModalBody>
        <FormContainer>
          <Label>Nome</Label>
          <Input
            value={state.nome}
            onChange={(e) => setState({ ...state, nome: e.target.value })}
          />
          <Label>CPF</Label>
          <PatternFormat
            value={state.cpf}
            onValueChange={(e) => {
              console.log(e);
              setState({ ...state, cpf: e.value, cpfError: false });
            }}
            format="###.###.###-##"
            customInput={Input}
            style={
              state?.cpfError
                ? {
                    borderColor: COLORS.red,
                    backgroundColor: `${COLORS.light_red}55`,
                    color: COLORS.red,
                  }
                : {}
            }
          />
          <Label>Data de Nascimento</Label>
          <Input
            value={moment(state.nascimento).format("YYYY-MM-DD")}
            onChange={(e) => setState({ ...state, nascimento: e.target.value })}
            type="date"
          />
          <Label>Email</Label>
          <Input
            value={state.email}
            onChange={(e) =>
              setState({ ...state, email: e.target.value, emailError: false })
            }
            style={
              state?.emailError
                ? {
                    borderColor: COLORS.red,
                    backgroundColor: `${COLORS.light_red}55`,
                    color: COLORS.red,
                  }
                : {}
            }
          />
        </FormContainer>
      </ModalBody>
      ;
    </>
  );
}

export default Vida;
