import {
  faHeartPulse,
  faPiggyBank,
  faSitemap,
  faSquareDollar,
} from "@fortawesome/pro-light-svg-icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import USERS from "../../../api/users";
import IconToolTip from "../../../components/IconToolTip";
import { REQ_STATUS, ROLES } from "../../../utils/constants";
import { Button, Input, Subtitle } from "../../main-styles";
import RenderRole from "../../../components/RenderRole";
import Spinner from "../../../components/Spinner";
import RenderUserStats from "../../../components/RenderUserStats";
import { toB64 } from "../../../utils/functions";
import { Tooltip } from "react-tooltip";

function Convenios() {
  const [state, setState] = React.useState({
    status: REQ_STATUS.LOADING,
    data: [],
    perPage: 10,
    page: 1,
    search: "",
    sort: "name",
    order: "asc",
    total: 0,
    selectedUser: null,
  });
  const navigate = useNavigate();
  const {
    status,
    data,
    perPage,
    page,
    search,
    sort,
    order,
    total,
    selectedUser,
  } = state;

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    const promises = [
      USERS.get.all(page, perPage, search, ROLES.convenio),
      USERS.get.count(),
    ];
    const [data, total] = await Promise.all(promises);
    setState((state) => ({ ...state, data, total, status: REQ_STATUS.IDLE }));
  }

  function gotoUser(id) {
    console.log("gotoUser", id);

    navigate(`/u/${toB64(id)}`);
  }

  function selectUser(id) {
    setState((state) => ({ ...state, selectedUser: id }));
  }

  function closeUser() {
    setState((state) => ({ ...state, selectedUser: null }));
  }

  return (
    <div>
      <Subtitle>Convênios</Subtitle>
      <div
        style={{
          display: "flex",
          paddingBottom: 5,
          marginBottom: 5,
          borderBottom: "1px solid #ccc",
        }}
      >
        <Input placeholder="Pesquisar" style={{ marginRight: 5 }} />
        <Button style={{ marginRight: 5 }}>Pesquisar</Button>
        <Button onClick={() => navigate("/admin/usuarios/novo")}>
          Adicionar
        </Button>
      </div>
      {status === REQ_STATUS.LOADING ? (
        <Spinner />
      ) : (
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col" style={{ maxWidth: 120 }}></th>
              <th scope="col">Nome</th>
              <th scope="col" style={{ maxWidth: 30 }}>
                Vidas
              </th>
              <th scope="col" style={{ maxWidth: 60 }}>
                Documento
              </th>
              <th scope="col" style={{ maxWidth: 60 }}>
                E-mail
              </th>
              <th scope="col" style={{ width: 30 }}>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={6}>Nenhum registro encontrado</td>
              </tr>
            )}
            {data.map((user) => (
              <tr key={user.id}>
                <td style={{ textAlign: "center" }}>
                  <UserTooltips user={user} />
                </td>
                <td
                  onClick={() => {
                    selectUser(user);
                  }}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {user.name}
                </td>
                <td style={{ textAlign: "center" }}>X</td>
                <td>{user.cpf}</td>
                <td>{user.email}</td>
                <td>
                  <Button onClick={() => gotoUser(user.id)}>Gerenciar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal isOpen={selectedUser !== null} toggle={closeUser}>
        <UserInfo user={selectedUser} close={closeUser} />
        <Tooltip id="tooltip2" />
      </Modal>
    </div>
  );
}

function UserTooltips({ user }) {
  return (
    <>
      {user.is_life && (
        <IconToolTip
          id="icon-life"
          icon={faHeartPulse}
          title="Vida"
          color={"#900000"}
        />
      )}
      {user.billing && (
        <IconToolTip
          id="icon-billing"
          icon={faSquareDollar}
          title="Responsável pelo pagamento"
          color={"#009030"}
        />
      )}
      {user.manage_life && (
        <IconToolTip
          id="icon-manage-life"
          icon={faSitemap}
          title="Gerencia vidas"
          color={"#620090"}
        />
      )}
      {user.cash_back && (
        <IconToolTip
          id="icon-cash-back"
          icon={faPiggyBank}
          title="Comissionado"
          color={"#003e90"}
        />
      )}
    </>
  );
}

function UserInfo({ user, close }) {
  console.log("UserInfo", user);
  if (!user) {
    return null;
  }
  return (
    <>
      <ModalHeader toggle={close}>{user.name}</ModalHeader>
      <ModalBody></ModalBody>
    </>
  );
}

export default Convenios;
