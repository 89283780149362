
import api from "../axios";

const USERS = {
  get: {
    all: async (page, perPage, search = "", role) => {
      let url = `/users?page=${page}&perPage=${perPage}`;
      if (search) url += `&search=${search}`;
      if (role) url += `&role=${role}`;
      let response = await api.get(url);
      return response.data;
    },
    one: async (id) => {
      let url = `/users/one/${id}`;
      let response = await api.get(url);
      return response.data;
    },
    all_hubcare: async () => {
      let response = await api.get("/users/all-hubcare-users");
      return response.data;
    },
    count: async () => {
      return 100;
    },
    search: async (search) => {
      let response = await api.get(`/users/search?search=${search}`);
      return response.data;
    },
    dependents: async (id) => {
      let response = await api.get(`/users/dependents/${id}`);
      return response.data;
    },
    cpfExists: async (cpf) => {
      let response = await api.get(`/users/cpf-exists/${cpf}`);
      return response.data;
    },
    emailExists: async (email) => {   
      let response = await api.get(`/users/email-exists/${email}`);
      return response.data;
    },
    related: async (id, page, perPage, search) => {
      let url = `/users/related?id=${id}&page=${page}&perPage=${perPage}`;
      if (search) url += `&search=${search}`;
      let response = await api.get(url);
      return response.data;
    },
    pricing: async (uid) => {
      //pega a taxa de adesão praticada pelo usuario
      let response = await api.get(`/finance/pricing/convenio`);
      return response.data.pricing || 49.9;
    },
    centro_de_custos: async()=>{
      // pega centros de custos abaixo de mim
      let response = await api.get('/finance/mine/centro_custo')
      return response.data
    }
  },
  set: {
    create: async (data) => {
      let response = await api.post("/users", data);
      return response.data;
    },
    block: async (id) => {
      let response = await api.post(`/users/block/${id}`);
      return response.data;
    },
    unblock: async (id) => {
      let response = await api.post(`/users/unblock/${id}`);
      return response.data;
    },
   
    send_activation: async (id) => {
      let response = await api.put(`/users/activate/${id}`);
      return response.data;
    },
    send_reset: async (cpf) => {
      try {
        let response =await api.post("/auth/password-recovery", { cpf });
        console.log("response", response.data);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    },
    check_dependent: async (email, cpf) => {
      let response = await api.post("/users/check-dependent", { email, cpf });
      return response.data;
    },
    add_dependent: async (id, vida, live = false) => {
      let response;
      if (live) {
        response = await api.post(`/users/dependents`, vida);
      } else {
        const data = {
          action: "add",
          target_id: id,
          dependent: vida,
        };

        response = await api.post(`/users/public-dependents`, data);
      }
      return response.data;
    },
    remove_dependent: async (target_id, dependent_id) => {
      const data = {
        action: "remove",
        target_id,
        dependent_id,
      };
      let response = await api.post(`/users/public-dependents`, data);
      return response.data;
    },
    publicCreation: async (data) => {
      let response = await api.post("/users/public-registration", data);
      return response.data;
    },
    
    shouldActivate: async (id) => {
      let response = await api.post(`/users/should-activate/${id}`);
      return response.data;
    },
    activate: async (id) => {
      let response = await api.post(`/users/activate/${id}`);
      return response.data;
    },
    update: async (id, data) => {
      let response = await api.put(`/users/${id}`, data);
      return response.data;
    },
    delete: async (id) => {},
    add_life: async (id) => {
      let data = {
        action: "add",
        id,
      };
      let response = await api.post(`/users/live/${id}`, data);
      return response.data;
    },
    remove_life: async (id) => {
      let data = {
        action: "remove",
        id,
      };
      let response = await api.post(`/users/live/${id}`, data);
      return response.data;
    },
    generateCheckout: async (id, vidas, email) => {
      let response = await api.post(`/finance/checkout/`, { id, vidas, email });
      return response.data;
    },
    pricing: async (pricing) => {
      //pega a taxa de adesão praticada pelo usuario
      let response = await api.post(`/finance/pricing/convenio`, { pricing });
      return response.data;
    },
    force_activate: async (id) => {
      let response = await api.post(`/users/force-activate/${id}`);
      return response.data;
    }
  },
};

export default USERS;
