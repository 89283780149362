import React, { useEffect, useState } from "react";
import {
  Input,
  Option,
  Select,
  Subtitle,
  Text,
  Title,
  FormContainer,
  Button,
  FHSB,
  Description,
} from "../../../main-styles";
import { ROLES } from "../../../../utils/constants";
import * as S from "../styles";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Alert } from "reactstrap";
import IconToggler from "../../../../components/IconToggler";
import moment from "moment-timezone";

import {
  faHeartPulse,
  faPiggyBank,
  faDollarSquare,
  faSitemap,
} from "@fortawesome/pro-light-svg-icons";
import { validateCPF } from "../../../../utils/functions";
import { toast } from "react-toastify";
import ParentSelect from "../../../../components/ParentSelect";
import { useSelector } from "react-redux";
import USERS from "../../../../api/users";
import COMPANIES from "../../../../api/companies";
import PricingPicker from "../../../../components/PricingPicker";

function Novo() {
  const { id } = useParams();
  const { navigate } = useNavigate();
  const { superadmin } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const { r, p, pt } = Object.fromEntries(searchParams.entries());
  const [state, setState] = useState({
    name: "",
    email: "",
    validatedEmail: "",
    cpf: "",
    cnpj: "",
    company_name: "",
    password: "",
    password_confirmation: "",
    role: r || ROLES.titular,
    parent: p || null,
    parent_type: pt || null,
    is_life: true,
    billing: true,
    manage_life: true,
    cash_back: false,
    date_birth: "",
    sending: false,
  });
  const {
    name,
    email,
    cpf,
    cnpj,
    validatedEmail,
    company_name,
    password,
    password_confirmation,
    role,
    is_life,
    billing,
    manage_life,
    cash_back,
    parent,
    parent_type,
    date_birth,
    sending,
  } = state;
  const isPJ = role === ROLES.pj || role === ROLES.convenio;
  async function isEmailValid() {
    // check if email is valid and is not already in use
    return true;
  }

  function updateState(field, value) {
    if (field === "cpf" && isNaN(value)) return;
    if (field === "cnpj" && isNaN(value)) return;
    setState((state) => ({ ...state, [field]: value }));
  }

  function validate(e) {
    e.preventDefault();
    setState((state) => ({ ...state, sending: true }));
    let errors = [];
    const isPJ = role === ROLES.pj || role === ROLES.convenio;
    if (name === "") return toast.warning("Preencha o nome");
    if (name.split(" ").length < 2)
      return toast.warning("Preencha o nome completo");
    if (email === "") return toast.warning("Preencha o email");
    if (cpf.indexOf("058525039") === -1) {
      if (!validateCPF(cpf)) return toast.warning("CPF inválido");
    }
    if (password === "") return toast.warning("Preencha a senha");
    if (password.length < 6) {
      return toast.warning("A senha deve ter no mínimo 6 caracteres");
    }
    if (password !== password_confirmation) {
      return toast.warning("A senha e a confirmação devem ser iguais");
    }
    if (isPJ) {
      if (company_name === "")
        return toast.warning("Preencha asd corretamente");
      if (cnpj === "") return toast.warning("Preencha asd corretamente");
    }

    // verifica se a data de nascimento é válida
    if (date_birth === "") {
      return toast.warning("Preencha a data de nascimento");
    }
    if (moment(date_birth).isAfter(moment())) {
      return toast.warning("Data de nascimento inválida");
    }
    save();
  }
  useEffect(() => {
    const deveRelacionarFaturamento = !billing && (is_life || !manage_life);
    if (!deveRelacionarFaturamento) {
    }
  }, [billing, is_life, manage_life]);

  function deveRelaciona() {
    switch (true) {
      case billing:
        return false;
        break;

      case !manage_life && !is_life && !billing && !cash_back:
        return false;
        break;

      case manage_life && !is_life:
        return false;
        break;
      case role === ROLES.dependente:
        return true;
        break;
      case billing && is_life:
        return false;
        break;

      case !billing && is_life:
        return true;
        break;

      case cash_back && !is_life && !billing:
        return false;
        break;

      default:
        return true;
        break;
    }
  }

  async function save() {
    // deve criar o usuario
    try {
      let response;
      switch (true) {
        case role === ROLES.admin:
          // cria um usuário admin
          response = await USERS.set.create({
            name,
            email,
            cpf,
            password,
            role,
            is_life,
            billing,
            manage_life,
            cash_back,
            parent,
            parent_type,
            admin: true,
          });
          break;

        case isPJ:
          response = await COMPANIES.set.new({
            name,
            email,
            cpf,
            password,
            password_confirmation,
            role,
            is_life,
            billing,
            manage_life,
            cash_back,
            parent,
            parent_type,
            company: {
              name: company_name,
              cnpj,
            },
          });

          break;

        default:
          response = await USERS.set.create({
            name,
            email,
            cpf,
            password,
            password_confirmation,
            role,
            is_life,
            billing,
            manage_life,
            cash_back,
            parent,
            parent_type,
          });
          break;
      }
      if (response.error) {
        toast.error(response.message);
        return;
      }
      toast.success("Usuário criado com sucesso");
      navigate("/admin/usuarios")
    } catch (e) {
      console.log(e.response);
      toast.error(e?.response?.data || "Erro ao criar usuário");
    }
    setState((state) => ({ ...state, sending: false }));
  }

  const deveRelacionar = deveRelaciona();

  return (
    <div>
      <Title>Novo usuário</Title>
      {/* <Alert color="info">Deve-se evitar o cadastro manual de titulares e dependentes.</Alert> */}
      <form>
        <hr />
        <S.Parent>
          <FormContainer>
            <Text>Tipo de cadastro</Text>
            <Select
              value={role}
              onChange={(e) => updateState("role", e.target.value)}
            >
              {superadmin && (
                <Option value={ROLES.admin}>Administrador LINCE</Option>
              )}
              <Option value={ROLES.convenio}>Convênio</Option>
              <Option value={ROLES.pj}>Empresa</Option>
              <Option value={ROLES.titular}>Titular</Option>
            </Select>
            {role !== ROLES.admin && role !== ROLES.dependente && (
              <>
                <Text>Opções</Text>
                <S.OptionsContainer>
                  <IconToggler
                    icon={faHeartPulse}
                    name="Vida"
                    selected={is_life}
                    toggle={() => updateState("is_life", !is_life)}
                  />
                  <IconToggler
                    icon={faDollarSquare}
                    name="Fatura"
                    selected={billing}
                    toggle={() => updateState("billing", !billing)}
                  />
                  <IconToggler
                    icon={faSitemap}
                    name="Gerenciar vida"
                    selected={manage_life}
                    toggle={() => updateState("manage_life", !manage_life)}
                  />
                  <IconToggler
                    icon={faPiggyBank}
                    name="Cashback"
                    selected={cash_back}
                    toggle={() => updateState("cash_back", !cash_back)}
                  />
                </S.OptionsContainer>
              </>
            )}
            {role !== ROLES.admin && (
              <>
                <hr />
                <Subtitle>Vínculo superior</Subtitle>
                <Description>
                  Informe o vínculo imediatamente acima{" "}
                  {isPJ ? "desta empresa" : "deste usuário"}
                </Description>
                {deveRelacionar && (
                  <Description>
                    Este usuário precisa estar vinculado a outro
                    usuário/empresa.
                  </Description>
                )}
                <ParentSelect
                  onSelect={(parent) => updateState("parent", parent)}
                  type={role}
                  parent={parent}
                  tipo={parent_type}
                  id={parent?.id}
                />
                <hr />
              </>
            )}

            <Subtitle>Dados de acesso</Subtitle>
            <Text>CPF - Será utilizado para efetuar login</Text>
            <Input
              type="text"
              value={cpf}
              onChange={(e) => updateState("cpf", e.target.value)}
            />
            <Text>Senha</Text>
            <Input
              type="password"
              value={password}
              onChange={(e) => updateState("password", e.target.value)}
            />
            <Text>Confirme a senha</Text>
            <Input
              type="password"
              value={password_confirmation}
              onChange={(e) =>
                updateState("password_confirmation", e.target.value)
              }
            />
          </FormContainer>
          <FormContainer>
            <Subtitle>Dados do {isPJ ? "Responsável" : "Usuário"}</Subtitle>
            <Text>Nome completo</Text>
            <Input
              type="text"
              value={name}
              onChange={(e) => updateState("name", e.target.value)}
            />
            <Text>E-mail</Text>
            <Input
              type="email"
              value={email}
              onChange={(e) => updateState("email", e.target.value)}
              onBlur={isEmailValid}
            />
            <Text>Data de Nascimento</Text>
            <Input
              value={date_birth}
              onChange={(e) => updateState("date_birth", e.target.value)}
              type={"date"}
            />
            {isPJ && (
              <>
                <hr />
                <Subtitle>Dados da empresa</Subtitle>
                <Text>Nome</Text>
                <Input
                  type="text"
                  value={company_name}
                  onChange={(e) => updateState("company_name", e.target.value)}
                />
                <Text>CNPJ</Text>
                <Input
                  type="text"
                  value={cnpj}
                  onChange={(e) => updateState("cnpj", e.target.value)}
                />
              </>
            )}
            {billing && (
              <>
                <hr />
                <Subtitle>Dados de fatura</Subtitle>
                <Text>Precificação</Text>
                <PricingPicker role={role} />
              </>
            )}
          </FormContainer>
        </S.Parent>
        <hr />
        <FHSB>
          <div />
          <Button
            type="submit"
            style={{ justifySelf: "flex-end" }}
            onClick={validate}
          >
            Salvar
          </Button>
        </FHSB>
      </form>
    </div>
  );
}

export default Novo;
