import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button, FHSB, Input, Label, Title } from "../../routes/main-styles";
import { toast } from "react-toastify";
import { validateCPF } from "../../utils/functions";
import USERS from "../../api/users";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

function AddDependentModal({ onChange, visible, close = () => {} }) {
  const [state, setState] = useState({
    name: "",
    cpf: "",
    birthdate: "",
    email: "",
  });
  const [testing, setTesting] = useState(false);
  useEffect(() => {
    setState({
      name: "",
      cpf: "",
      birthdate: "",
      email: "",
    });
  }, [visible]);

  function updateCpf(value) {
    // only accepts numbers
    console.log(value);
    if (!/^\d+$/.test(value)) return;
    // only accepts 11 characters
    if (value.length > 11) return;
    setState((s) => ({ ...s, cpf: value }));
  }

  async function validate() {
    if (testing) return;
    setTesting(true);
    let error = false;
    if (
      state.name === "" ||
      state.name.length < 3 ||
      state.name.split(" ").length < 2
    ) {
      toast.error("Nome inválido");
      error = true;
    }
    if (validateCPF(state.cpf) === false) {
      toast.error("CPF inválido");
      error = true;
    }
    if (state.birthdate === "") {
      toast.error("Data de nascimento inválida");
      error = true;
    }
    if (
      state.email === "" ||
      !state.email.includes("@") ||
      !state.email.includes(".") ||
      state.email.split("@").length < 2
    ) {
      toast.error("Email inválido");
      error = true;
    }

    try {
      await USERS.set.check_dependent(state.email, state.cpf);
    } catch (error) {
      toast.error("CPF ou email já cadastrado");
      error = true;
      setTesting(false);
      return false;
    }
    
    if (error === true) {
      setTesting(false);
    }
    setTesting(false);
    onChange(state);
    return false;
  }

  if (!visible) return null;
  return (
    <Modal isOpen={true} toggle={close}>
      <ModalHeader style={{ display: "flex", }}>
        <FHSB>
          <Title style={{ flex: 1 }}>Adicionar dependente</Title>
          <div style={{ float: "right" }}>
            <FontAwesomeIcon icon={faTimes} onClick={close} />
          </div>
        </FHSB>
      </ModalHeader>
      <ModalBody>
        <S.Container>
          <Label>Nome</Label>
          <Input
            value={state.name}
            onChange={(e) => setState((s) => ({ ...s, name: e.target.value }))}
          />
          <Label>CPF</Label>
          <Input
            value={state.cpf}
            onChange={(e) => updateCpf(e.target.value)}
          />
          <Label>Data de nascimento</Label>
          <Input
            value={state.birthdate}
            type="date"
            onChange={(e) =>
              setState((s) => ({ ...s, birthdate: e.target.value }))
            }
          />
          <Label>Email</Label>
          <Input
            value={state.email}
            onChange={(e) => setState((s) => ({ ...s, email: e.target.value }))}
          />
          <Button
            onClick={() => {
              validate();
            }}
          >
            {testing ? "Aguarde..." : "Adicionar"}
          </Button>
        </S.Container>
      </ModalBody>
    </Modal>
  );
}

export default AddDependentModal;
