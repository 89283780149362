import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { COLORS } from "../../routes/main-styles";
export const Container = styled.div``;

export const Logo = styled.div``;

export const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  gap: 5px;
  @media (max-width: 768px) {
    justify-content: flex-end;
    flex-wrap: wrap;
  }
`;

export const MenuItem = styled(NavLink)`
  margin: 0 10px;
  padding: 10px 30px;
  background-color: ${COLORS.blue};
  color: #ffffff;
  text-decoration: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all ease 0.3s;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &:hover {
    background: ${COLORS.blue};
    filter: brightness(1.1);
  }
`;
