import styled from "styled-components";

export const Container = styled.div`
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const VidaContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
`;

export const VidaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #333;
  display: flex;
  gap: 5px;

`
export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dc3545;
  color: white;
  padding: 5px;
  border-radius: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: scale(0.8);
`;