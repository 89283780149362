import React, { useEffect, useState } from "react";

import * as S from "./styles";
import BasePage from "../../components/BasePage";
import {
  Button,
  COLORS,
  FH,
  Input,
  Label,
  LeftMenu,
  LeftMenuItem,
  Text,
} from "../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faHeadset,
  faReceipt,
} from "@fortawesome/pro-light-svg-icons";
import UserPricing from "../Home/UserPricing";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { ROLES } from "../../utils/constants";
import { Link } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import USERS from "../../api/users";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import AUTH from "../../api/auth";

function Config() {
  const { id, role, cpf, email } = useSelector((state) => state.auth.userData);
  const [userPricingOpen, setUserPricingOpen] = useState(false);

  const [state, setState] = useState({
    phone: "",
    curentPassword: "",
    password: "",
    confirmPassword: "",
    originalPhone: "",
    loading: true,
  });

  const { loading, phone, password, confirmPassword, curentPassword } = state;

  useEffect(() => {
    // fetch user data
    init();
  }, []);

  async function init() {
    // fetch user data
    let user = await USERS.get.one(id);
    console.log("USERS", user);
    let { phone } = user;
    if (!phone) phone = "";
    setState((s) => ({ ...s, phone, originalPhone: phone, loading: false }));
  }

  async function savePhone() {
    if (phone === "") return;
    if (phone === state.originalPhone) return;
    if (phone.length < 11) {
      toast.error("Número de telefone inválido");
      return;
    }
    let user = await USERS.set.update(id, { phone });
    console.log("USERS", user);
    setState((s) => ({ ...s, originalPhone: phone }));
  }

  async function requestPassword() {
    let response = await AUTH.get.recoveryCode(cpf);
    console.log(response);
    toast.success(`Link para redefinição de senha enviado para o email: ${email}`);
  }

  function toggleUserPricing() {
    setUserPricingOpen(!userPricingOpen);
  }

  if (loading)
    return (
      <BasePage title="Configurações">
        <Spinner />
      </BasePage>
    );

  return (
    <BasePage
      title="Configurações"
      menu={
        <LeftMenu>
          <LeftMenuItem>
            <FontAwesomeIcon icon={faHeadset} size="lg" />
            <a
              href="https://lince.freshdesk.com"
              target="_blank"
              rel="noreferrer"
            >
              Painel de suporte
            </a>
          </LeftMenuItem>
        </LeftMenu>
      }
    >
      <Container>
        <Row>
          <Col md={6}>
            <S.Container>
              <h3>Alterar meus dados</h3>
              <Text htmlFor="phone">Celular</Text>
              <FH>
                <PatternFormat
                  format="(##) #####-####"
                  mask="_"
                  id="phone"
                  name="phone"
                  customInput={Input}
                  value={phone}
                  onValueChange={(values) => {
                    const { value } = values;
                    console.log(value);
                    setState((s) => ({ ...s, phone: value }));
                  }}
                />
                {phone !== "" && phone !== state.originalPhone && (
                  <>
                    <Button color={COLORS.main} onClick={savePhone}>
                      Salvar
                    </Button>
                  </>
                )}
              </FH>
              <hr />
              <h3>Senha de acesso</h3>
              
              <div />
              <Button  onClick={requestPassword}>Alterar minha senha</Button>
              <hr />
              <div className="alert alert-danger">
                <strong>Excluir conta</strong>
                <br />
                ⚠️ Se encerrar sua conta, você não poderá acessar consultas,
                dados de consultas, dados financeiros e dependendo do tipo de
                conta, você não poderá mais acessar o painel de administração.
                <hr />
                <Button color={COLORS.red}>Encerrar conta</Button>
              </div>
            </S.Container>
          </Col>

          {role === ROLES.convenio && (
            <Col md={6}>
              <h3>Taxa de Adesão</h3>
              <Button onClick={toggleUserPricing}>
                Configurar taxa de adesão padrão
              </Button>
            </Col>
          )}
          {userPricingOpen && (
            <UserPricing open={userPricingOpen} toggle={toggleUserPricing} />
          )}
        </Row>
      </Container>
    </BasePage>
  );
}

export default Config;
