import axios from "axios";
import { isDEV } from "../utils/constants";
import { jwt_decode } from "../utils/functions";

isDEV && console.log("isDEV", isDEV);

const BASE_URL = isDEV
  ? "http://localhost:3012/api/v1"
  : "https://api.lincesaude.com.br/api/v1";

const api = axios.create({
  baseURL: BASE_URL,
});

// Function to refresh the access token
const refreshAccessToken = async () => {
  try {
    // Assuming refreshToken is stored in local storage or a secure place
    const refreshToken = localStorage.getItem("refreshToken");

    if(!refreshToken) return null;
    if(isTokenExpired(refreshToken)) return null;
    const response = await axios.post(`${BASE_URL}/auth/refresh`, {
      refreshToken,
    });
    console.log("response", response.data);
    const { token, refreshToken: new_refresh } = response.data;
    // Update the accessToken in localStorage
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", new_refresh);

    return token;
  } catch (error) {
    // deve deslogar
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    // dispatch store logout

    const store = require("../redux/store").store;
    store.dispatch({ type: "auth/logout" });


    window.location.href = "/auth/login";
    throw new Error("Unable to refresh access token.");
  }
};

// Interceptor to add the access token to the request headers
api.interceptors.request.use(
  async (config) => {
    // Get the access token from your authentication system
    const auth = await getToken();
    const accessToken = auth;

    // Add the access token to the request headers
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

//GETS TOKEN FROM REDUX STORE
function getToken() {
  let token = localStorage.getItem("token");
  return token;
}

// Interceptor to handle token expiration and refresh the access token
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      // verifica se o token está vencido
      let isExpired = isTokenExpired();
      console.log("isExpired", isExpired)
      // verify if user is logged in

      if (isExpired) {
        originalRequest._retry = true;
        try {
          const newAccessToken = await refreshAccessToken();
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
    }
    return Promise.reject(error);
  }
);


function isTokenExpired(token) {
  if (!token) return true;
  const decoded = jwt_decode(token);
  console.log("DECODED JWT", decoded)
  const currentTime = Date.now() / 1000;
  let isExpired = decoded.exp < currentTime;
  return isExpired
}

export default api;
