import React, { useEffect, useMemo, useRef, useState } from "react";

import * as S from "./styles";
import { Form, NavLink, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import {
  COLORS,
  Description,
  FH,
  FHSB,
  FormContainer,
  Input,
  Label,
  Link,
  Text,
  Title,
  WhiteBox,
} from "../../main-styles";

import Select from "react-select";
import { REQ_STATUS, ROLES } from "../../../utils/constants";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTurnDownRight,
  faBoltLightning,
  faCheck,
  faCheckSquare,
  faCloudArrowDown,
  faCloudArrowUp,
  faDownload,
  faHeartCirclePlus,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faPencil,
  faSave,
  faSpinner,
  faSquare,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faTurnDownRight,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import {
  ExcelDateToJSDate,
  formatCPF,
  validateCPF,
} from "../../../utils/functions";
import StatusContrato from "../../../components/StatusContrato";
import COMPANIES from "../../../api/companies";
import USERS from "../../../api/users";
import Spinner from "../../../components/Spinner";
import Vida from "./Vida";
import { useSelector } from "react-redux";
import { PatternFormat } from "react-number-format";
import PRICING from "../../../api/pricing";

function Novo() {
  const navigate = useNavigate();
  const { uuid, mine, edit } = useParams();
  const { role } = useSelector((state) => state.auth.userData);

  const topRef = useRef(null);
  const companyDataRef = useRef(null);
  const tableRef = useRef(null);

  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    centro_de_custos: [],
    edit_vida_selected: null,
    new_vida_visible: false,
    tipos_de_contrato: [],
    send_to_client: true,
    taxa_adesao: 49.9,
    contrato: {
      tipo: "",
      id: uuid,
      role: "",
      numero_proposta: "xxxx-xxxx-xxxx-xxxx",
      numero_contrato: "xxxx-xxxx-xxxx-xxxx",
      data_criacao: moment().format("YYYY-MM-DD"),
      data_assinatura: "",
      data_cancelamento: "",
      convenio: {
        id: "",
        nome: "",
      },
      centro_de_custo: null,
      vidas: [],
      status: "provisorio", // provisorio, ativo, cancelado e arquivado
      valor: "",
      stripe_subscription_id: "",
      stripe_client_id: "",
      validado: false,
      validando: false,
    },
    company: {
      cnpj: "",
      razao_social: "-",
      nome_fantasia: "-",
      responsavel: "",
      cpf_responsavel: "",
      email: "",
      telefone: "",
      validado: false,
      validando: false,
      cep: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "Brasil",
    },
    precificacao: {},
    carregando_precificacao: true,
  });
  const {
    send_to_client,
    contrato,
    contrato: {
      numero_proposta,
      numero_contrato,
      data_criacao,
      data_assinatura,
      data_cancelamento,
      convenio,
      centro_de_custo,
      vidas,
      status,
      valor,
      stripe_subscription_id,
      stripe_client_id,
      tipo,
      responsavel,
      cpf_responsavel,
      email,
      telefone,
    },
    status: pageStatus,
    centro_de_custos,
    edit_vida_selected,
    new_vida_visible,
    tipos_de_contrato,
    company,
    taxa_adesao,
    precificacao,
    carregando_precificacao,
  } = state;

  useEffect(() => {
    init();
  }, []);

  async function getTaxaAdesao() {
    try {
      let taxa = await USERS.get.pricing();
      console.log("taxa", taxa);
      setState((s) => ({ ...s, taxa_adesao: taxa }));

      return true;
    } catch (error) {
      setState((s) => ({ ...s, taxa_adesao: 49.9 }));
      return true;
      console.error(error);
    }
  }

  function updateCompany(field, value) {
    if (field === "cnpj") {
      clearCompany();
    }
    setState((s) => {
      return {
        ...s,
        company: {
          ...s.company,
          [field]: value,
        },
      };
    });
  }

  async function pegaPrecificacao(tipo) {
    console.log("tipo", tipo);
    setState((s) => {
      return {
        ...s,
        carregando_precificacao: true,
        precificacao: null,
      };
    });
    // se tive um subscription id, deve buscar a precificação aplicada
    if (stripe_subscription_id) {
      // TODO: PEGA PRACIFICACAO APLICADA
      // let precificacao = await USERS.get.precificacao(stripe_subscription_id);
      // console.log("PRECIFICACAO", precificacao);
      return {
        valor: 0,
      };
    }
    // se não tiver subscription id, deve buscar a precificação do tipo de contrato: pf, pj ou convenio
    let precificacao;
    switch (tipo) {
      case ROLES.titular:
        precificacao = await PRICING.get.default_pf();
        console.log("PRECIFICACAO PF", precificacao);
        precificacao = precificacao.prices[0];
        break;
      case ROLES.pj:
        precificacao = await PRICING.get.default_pj();

        console.log("PRECIFICACOES PJ", precificacao);
        precificacao = precificacao.prices.find(
          (a) => a.lookup_key === "pj_avulso"
        );
        console.log("PRECIFICACAO PJ", precificacao);
        break;
      case ROLES.convenio:
        precificacao = await PRICING.get.default_pj();
        precificacao = precificacao.prices.find(
          (a) => a.lookup_key === "pj_convenio"
        );
        console.log("PRECIFICACAO convenio", precificacao);
        break;
      default:
        console.log("TIPO DE CONTRATO NÃO ENCONTRADO", tipo);
        break;
    }

    setState((s) => {
      return {
        ...s,
        precificacao,
        carregando_precificacao: false,
      };
    });

    return true;
  }

  function clearCompany() {
    setState((s) => {
      return {
        ...s,
        company: {
          razao_social: "-",
          nome_fantasia: "-",
          validado: false,
          validando: false,
        },
      };
    });
  }

  function closeEditVida() {
    setState((s) => {
      // remove as vidas que não foram validadas
      let vidas = s.contrato.vidas.filter((a) => a.validated !== false);
      return {
        ...s,
        edit_vida_selected: null,
        contrato: {
          ...s.contrato,
          vidas,
        },
      };
    });
  }

  function selectVida(vida) {
    setState((s) => {
      return {
        ...s,
        edit_vida_selected: vida,
      };
    });
  }

  function saveVida(id, vida) {
    setState((s) => {
      let vidas = s.contrato.vidas.map((v) => {
        if (v.id === id) {
          return vida;
        }
        return v;
      });

      vidas = ordenaVidas(vidas);
      return {
        ...s,
        edit_vida_selected: null,
        contrato: {
          ...s.contrato,
          vidas,
        },
      };
    });
  }

  function newVida(titular = false) {
    console.log("ADICONANDO VIDA COM TITULAR", titular);
    if (titular !== false) {
      titular = vidas.find((v) => v.id === titular);
      titular = {
        id: titular.id,
        nome: titular.nome,
      };
    }
    let vida = {
      id: `#id-${vidas.length + 1}`,
      nome: "",
      cpf: "",
      email: "",
      nascimento: "",
      titular,
      validated: false,
      billing: false,
    };
    console.log("NOVA VIDA", vida);
    setState((s) => {
      let contrato = s.contrato;

      let vidas = [...contrato.vidas, vida];
      vidas = ordenaVidas(vidas);

      contrato = {
        ...contrato,
        vidas,
      };

      return {
        ...s,
        contrato,
        edit_vida_selected: vida,
      };
    });
  }

  async function init() {
    try {
      let tipos_de_contrato;
      switch (role) {
        case ROLES.titular:
          tipos_de_contrato = [];

          break;
        case ROLES.pj:
          tipos_de_contrato = [
            {
              value: ROLES.titular,
              label: "Pessoa Física",
            },
          ];
          break;
        case ROLES.convenio:
          tipos_de_contrato = [
            {
              value: ROLES.titular,
              label: "Pessoa Física",
            },
            {
              value: ROLES.pj,
              label: "Pessoa Jurídica",
            },
          ];

          break;
        case ROLES.admin:
        case ROLES.superadmin:
          tipos_de_contrato = [
            {
              value: ROLES.titular,
              label: "Pessoa Física",
            },
            {
              value: ROLES.pj,
              label: "Pessoa Jurídica",
            },
            {
              value: ROLES.convenio,
              label: "Convênio",
            },
          ];
          break;
        default:
          tipos_de_contrato = [];
          break;
      }
      let promises = [];
      promises.push(getTaxaAdesao());
      promises.push(loadCentroDeCustos());
      promises.push(pegaPrecificacao(tipos_de_contrato[0].value));

      console.log("AGUARDANDO DE CONTRATO CARREGADOS");
      await Promise.all(promises);
      console.log("TIPOS DE CONTRATO CARREGADOS");
      setState((s) => ({
        ...s,
        tipos_de_contrato,
        contrato: { ...s.contrato, tipo: tipos_de_contrato[0] },
        status: REQ_STATUS.IDLE,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async function loadCentroDeCustos() {
    try {
      let centro_de_custos = await USERS.get.centro_de_custos();
      let auto = {
        value: 0,
        label: "> Este contrato",
      };
      centro_de_custos = centro_de_custos.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      });
      setState((s) => ({
        ...s,
        centro_de_custos: [auto, ...centro_de_custos],
        contrato: {
          ...s.contrato,
          centro_de_custo: auto,
        },
      }));
      return true;
    } catch (e) {
      console.error(e);
    }
  }

  async function validate() {
    if (contrato?.validando || contrato.validado) return true;
    updateContrato("validando", true);
    if (tipo.value !== ROLES.titular) {
      if (company?.erroCNPJ) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error(
          "Não é possível criar um novo contrato para uma empresa ativa."
        );
        return false;
      }
      if (company.validado === false) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("Confira os dados da empresa.");
        return false;
      }
      if (company?.responsavel.length < 2) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("Nome do responsável inválido.");
        return false;
      }
      if (!validateCPF(company?.cpf_responsavel)) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("CPF do responsável inválido.");
        return false;
      }
      if (
        company?.email.indexOf("@") === -1 &&
        company?.email.indexOf(".") === -1
      ) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("Email do responsável é inválido.");
        return false;
      }
      if (company?.telefone.length < 10) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("Telefone do responsável é inválido.");
        return false;
      }

      if (company?.cep.length < 7) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("CEP inválido.");
        return false;
      }

      if (company?.address.length < 5) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("Endereço inválido.");
        return false;
      }

      if (company?.city.length < 2) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("Cidade inválida.");
        return false;
      }

      if (company?.state.length < 2) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("Estado inválido.");
        return false;
      }

      if (company?.country?.length < 2) {
        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        updateContrato("validando", false);
        toast.error("País inválido.");
        return false;
      }
    }

    if (vidas.length === 0) {
      // scroll to #vida-table
      tableRef?.current.scrollIntoView({ behavior: "smooth" });
      updateContrato("validando", false);
      toast.error("Adicione pelo menos uma vida ao contrato.");
      return false;
    }
    let error = false;
    let validatedVidas = vidas.map(async (v) => {
      // verifica se cpf já existe
      if (!validateCPF(v.cpf)) {
        v.cpfError = true;
        error = true;
      }
      if (v.email.indexOf("@") === -1 && v.email.indexOf(".") === -1) {
        v.emailError = true;
        error = true;
      }
      if (error) return v;
      let [cpfExists, emailExists] = await Promise.all([
        USERS.get.cpfExists(v.cpf),
        USERS.get.emailExists(v.email),
      ]);
      if (cpfExists && v.cpf === company.cpf_responsavel) cpfExists = false;
      if (cpfExists) {
        v.cpfError = true;
        error = true;
      }
      if (emailExists && v.email === company.email) emailExists = false;
      if (emailExists) {
        v.emailError = true;
        error = true;
      }
      return v;
    });
    Promise.all(validatedVidas).then((vidas) => {
      if (error) {
        setState((s) => ({
          ...s,
          contrato: {
            ...s.contrato,
            vidas,
            validando: false,
            validado: false,
          },
        }));

        tableRef?.current.scrollIntoView({ behavior: "smooth" });
        toast.error("Confira os dados do contrato.");
        return false;
      }
      setState((s) => ({
        ...s,
        contrato: {
          ...s.contrato,
          vidas,
          validado: true,
        },
      }));
      toast.success("Contrato validado com sucesso.");
      return true;
    });
  }

  function openFileUpload() {
    // creates an input element
    const input = document.createElement("input");
    // sets the input type to file
    input.type = "file";
    // sets the input accept attribute to only accept .xlsx or .xls files
    input.accept = ".xlsx, .xls";
    // adds an event listener to the input element
    input.addEventListener("change", handleFileUpload);
    // triggers a click event on the input element
    input.click();
  }

  function handleFileUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const readOpts = {
        type: "binary",
      };

      const jsonOpts = {
        header: 0,
        defval: "",
        blankrows: true,
        raw: false,
        dateNF: 'd"/"m"/"yyyy', // <--- need dateNF in sheet_to_json options (note the escape chars)
      };
      const workbook = XLSX.read(event.target.result, readOpts);
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let vidas = XLSX.utils.sheet_to_json(sheet, jsonOpts);
      try {
        vidas = vidas.filter(
          (a) =>
            a.nome !== "" &&
            a.cpf !== "" &&
            a.email !== "" &&
            a.nascimento !== ""
        );
        console.log("VIDAS", vidas);

        // verifica se nas vidas tem algum email ou cpf repetido
        let emails = vidas.map((v) => v.email.toLowerCase());
        let cpfs = vidas.map((v) => v.cpf.replace(/\D/g, ""));
        let emailRepetido = emails.some(
          (email, i) => emails.indexOf(email) !== i
        );
        let cpfRepetido = cpfs.some((cpf, i) => cpfs.indexOf(cpf) !== i);
        if (emailRepetido) {
          toast.error("Emails repetidos no arquivo, cada usuário deve ter um email único.");
           return;
        }
        if (cpfRepetido) {
          toast.error("CPFs repetidos no arquivo, cada usuário deve ter um CPF único.");
          return;
        }




        vidas = vidas.map((vida, i) => {
          let { cpf, email, nascimento, nome, cpftitular } = vida;
          cpf = cpf.replace(/\D/g, "");
          // capitalize nome
          nome = nome
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
          email = email.toLowerCase();
          cpftitular = cpftitular?.replace(/\D/g, "");
          console.log(vida);
          if (cpf === cpftitular) cpftitular = "";
          let id = i + 1;
          return {
            id: `#id-${id}`,
            cpf,
            email,
            nascimento,
            nome,
            cpftitular,
          };
        });
        vidas = vidas.map((vida) => {
          let cpftitular = vida?.cpftitular?.replace(/\D/g, "");
          let titular = vidas.find((v) => v.cpf === cpftitular);
          if (titular) {
            return {
              ...vida,
              titular: { id: titular.id, nome: titular.nome },
            };
          }
          return { ...vida, titular: false };
        });
        // ordena as vidas por titularidade e nome
        vidas = ordenaVidas(vidas);
        console.log("Vidas Ordenandas", vidas);
        setState((s) => {
          return {
            ...s,
            contrato: {
              ...s.contrato,
              vidas,
            },
          };
        });
      } catch (error) {
        console.error(error);
        toast.error("Layout inválido. Verifique o arquivo e tente novamente.");
      }
    };

    reader.readAsBinaryString(file);
  }

  function ordenaVidas(vidas) {
    let titulares = vidas
      .filter((v) => !v.titular)
      .sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });
    let dependentes = vidas
      .filter((v) => v.titular)
      .sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });
    vidas = titulares
      .map((titular) => {
        let dependentesDoTitular = dependentes.filter(
          (d) => d.titular.id === titular.id
        );
        return [titular, ...dependentesDoTitular];
      })
      .flat();
    return vidas;
  }

  function updateContrato(field, value) {
    console.log(field, value);
    if (field === "tipo") {
      pegaPrecificacao(value.value);
    }
    setState((s) => {
      return {
        ...s,
        contrato: {
          ...s.contrato,
          [field]: value,
        },
      };
    });
  }

  function pegaPrecificacaoUsuario(numero) {
    console.log("pegando precificacao usuario", numero);
    console.log("pegando precificacao precificacao", precificacao);
    numero = numero + 1;
    if (!precificacao?.tiers) return null;
    let precificacoes = precificacao?.tiers;
    console.log("PRECIFICACAO USADA", precificacao);
    for (let i = 0; i < precificacoes?.length; i++) {
      if (precificacoes[i].up_to === null || numero <= precificacoes[i].up_to) {
        return precificacoes[i].unit_amount;
      }
    }

    // Se não encontrar uma precificação adequada, retorna null ou um valor padrão
    return null;
  }

  async function enviarContrato() {
    console.log("ENVIANDO CONTRATO", state.status);
    if (state.status === REQ_STATUS.SENDING) return;
    setState((s) => ({
      ...s,
      status: REQ_STATUS.SENDING,
    }));
    let isValid = await validate();
    console.log("isValid", isValid);
    if (!isValid) {
      setState((s) => ({
        ...s,
        status: REQ_STATUS.IDLE,
      }));
      toast.error("Confira os dados do contrato.");
      return;
    }
    let payload = {
      contrato,
      empresa: company,
      send_to_client,
      centro_de_custo,
      numero_contrato,
      numero_proposta,
      precificacao,
      taxa_adesao,
      titulares: vidas.filter((v) => !v.titular).length,
    };
    try {
      let response = await COMPANIES.set.new(payload);
      console.log("RESPONSE", response);
      toast.success("Contrato enviado com sucesso.");
      setState((s) => ({
        ...s,
        status: REQ_STATUS.IDLE,
      }));
      navigate("/contratos");
    } catch (error) {
      toast.error("Erro ao enviar contrato.");
      console.error(error);
      setState((s) => ({
        ...s,
        status: REQ_STATUS.IDLE,
      }));
    }
  }

  function removeVida(id) {
    // verifica se tem dependentes
    let vida = vidas.find((v) => v.id === id);
    if (!vida.titular) {
      let dependentes = vidas.filter((v) => v.titular.id === vida.id);
      if (dependentes.length > 0) {
        toast.error("Remova os dependentes antes de remover o titular.");
        return;
      }
    }

    setState((s) => {
      return {
        ...s,
        contrato: {
          ...s.contrato,
          vidas: s.contrato.vidas.filter((v) => v.id !== id),
        },
      };
    });
  }

  const totalPrice = useMemo(() => {
    if (vidas.length === 0) return 0;
    if (!precificacao?.tiers) return 0;
    let titulares = vidas.filter((v) => !v.titular);
    let precotitular = pegaPrecificacaoUsuario(0);
    let preco = titulares.reduce((prev, curr) => {
      prev += precotitular;
      let dependentes = vidas.filter((v) => v.titular.id === curr.id);
      dependentes.map((d, i) => {
        prev += pegaPrecificacaoUsuario(i + 1);
        return d;
      });
      return prev;
    }, 0);
    return preco / 100;
  }, [vidas, precificacao, pegaPrecificacaoUsuario]);

  const total = useMemo(() => {
    if (vidas.length === 0) return 0;
    let titulares = vidas.filter((v) => !v.titular)?.length;
    let total = totalPrice;
    total += taxa_adesao * titulares;
    return total;
  }, [totalPrice, taxa_adesao]);

  if (pageStatus === REQ_STATUS.LOADING) {
    return (
      <Container>
        <Row>
          <Col>
            <Spinner /> carregando...
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: 15 }}>
      <Row>
        <Col>
          <WhiteBox ref={topRef}>
            {uuid ? (
              <Title>Editar Contrato</Title>
            ) : (
              <Title>Novo Contrato</Title>
            )}
            <S.FormFields>
              <Field
                id="numero_proposta"
                name="Número da Proposta"
                value={numero_proposta}
                onChange={(e) =>
                  updateContrato("numero_proposta", e.target.value)
                }
                type={"info"}
              />
              <Field
                id="numero_contrato"
                name="Número do Contrato"
                value={numero_contrato}
                onChange={(e) =>
                  updateContrato("numero_contrato", e.target.value)
                }
                type={"info"}
              />
              <Field
                id="data_criacao"
                name="Data de Criação"
                value={moment(data_criacao).format("DD/MM/YYYY")}
                onChange={(e) => updateContrato("data_criacao", e.target.value)}
                type="info"
              />
              {data_assinatura && (
                <Field
                  id="data_assinatura"
                  name="Data de Assinatura"
                  value={moment(data_assinatura).format("DD/MM/YYYY")}
                  onChange={(e) =>
                    updateContrato("data_assinatura", e.target.value)
                  }
                  type="info"
                />
              )}
              {status === "cancelado" && (
                <Field
                  id="data_cancelamento"
                  name="Data de Cancelamento"
                  value={data_cancelamento}
                  onChange={(e) =>
                    updateContrato("data_cancelamento", e.target.value)
                  }
                  type="info"
                />
              )}
              <FormContainer>
                <Label>Status</Label>
                <StatusContrato status={status} />
              </FormContainer>
              <FormContainer>
                <Label>Tipo de Contrato</Label>
                <Select
                  options={tipos_de_contrato}
                  value={tipo}
                  placeholder={"Selecione"}
                  noOptionsMessage={() => "Nenhum disponível"}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    console.log(e);
                    if (vidas.length > 0) {
                      return toast.error(
                        "Não é possível alterar o tipo de contrato com vidas cadastradas."
                      );
                    }

                    updateContrato("tipo", e);
                  }}
                  isDisabled={vidas?.length > 0}
                />
              </FormContainer>
              <FormContainer
                style={{
                  gridColumn: "span 2",
                }}
              >
                <Label>Centro de Custo</Label>
                <Select
                  options={centro_de_custos}
                  //   defaultValue={centro_de_custo}
                  value={centro_de_custo}
                  noOptionsMessage={() => "Nenhum cadastrado"}
                  placeholder={"Selecione"}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    console.log(e);
                    updateContrato("centro_de_custo", e);
                  }}
                />
              </FormContainer>
              {/* 
              Não será enviado nunca, o vendedor deve enviar manualmente e o cliente deve aceitar
              <FormContainer>
                <Label>Enviar para o cliente?</Label>
                <Text
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      send_to_client: !s.send_to_client,
                    }))
                  }
                >
                  <FontAwesomeIcon
                    icon={send_to_client ? faCheckSquare : faSquare}
                    size="2xl"
                    color={
                      send_to_client ? COLORS.dark_green : COLORS.dark_grey
                    }
                  />{" "}
                  Sim
                </Text>
              </FormContainer> */}

              <>
                {stripe_client_id && (
                  <Field
                    id="stripe_subscription_id"
                    name="Stripe Subscription ID"
                    value={stripe_subscription_id}
                    onChange={(e) =>
                      updateContrato("stripe_subscription_id", e.target.value)
                    }
                    type="info"
                  />
                )}
                {stripe_client_id && (
                  <Field
                    id="stripe_client_id"
                    name="Stripe Client ID"
                    value={stripe_client_id}
                    onChange={(e) =>
                      updateContrato("stripe_client_id", e.target.value)
                    }
                    type="info"
                  />
                )}
              </>
            </S.FormFields>
          </WhiteBox>
          {tipo?.value && (
            <ExtraData
              tipo={tipo?.value}
              updateCompany={updateCompany}
              company={company}
            />
          )}
          <WhiteBox ref={tableRef}>
            <FHSB style={{ alignItems: "center", width: "100%" }}>
              <Title>Vidas</Title>
              {tipo?.value === ROLES.titular ? (
                <>
                  {vidas.filter((v) => v.titular === false).length === 0 ? (
                    <FH style={{ flex: 1, justifyContent: "flex-end" }}>
                      <Button onClick={() => newVida()}>
                        <FontAwesomeIcon
                          icon={faHeartCirclePlus}
                          style={{ marginRight: 5 }}
                        />
                        Adicionar Titular
                      </Button>
                    </FH>
                  ) : (
                    <FH style={{ flex: 1, justifyContent: "flex-end" }}>
                      <Button onClick={() => newVida(vidas[0].id)}>
                        <FontAwesomeIcon
                          icon={faHeartCirclePlus}
                          style={{ marginRight: 5 }}
                        />
                        Adicionar Dependente
                      </Button>
                    </FH>
                  )}
                </>
              ) : (
                <FH style={{ flex: 1, justifyContent: "flex-end" }}>
                  <Link
                    href="/downloads/LayoutLince.xlsx"
                    style={{ marginRight: 5 }}
                  >
                    <FontAwesomeIcon
                      icon={faCloudArrowDown}
                      style={{ marginRight: 5 }}
                    />
                    Baixar layout
                  </Link>
                  <Button onClick={openFileUpload}>
                    <FontAwesomeIcon
                      icon={faCloudArrowUp}
                      style={{ marginRight: 5 }}
                    />
                    Importar Layout
                  </Button>

                  <Button onClick={() => newVida()}>
                    <FontAwesomeIcon
                      icon={faHeartCirclePlus}
                      style={{ marginRight: 5 }}
                    />
                    Adicionar Vida
                  </Button>
                </FH>
              )}
            </FHSB>
            <table
              className="table table-striped"
              style={{ borderRadius: 4, overflow: "hidden" }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Nome</th>
                  <th>CPF</th>
                  <th>Email</th>
                  <th style={{ width: 100 }}>Nascimento</th>
                  <th>Titular</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {vidas.length === 0 && (
                  <tr>
                    <td colSpan={7}>Nenhuma vida cadastrada</td>
                  </tr>
                )}
                {vidas.map((vida, index) => {
                  return (
                    <tr key={index}>
                      {vida?.titular && (
                        <td>
                          <FontAwesomeIcon
                            icon={faArrowTurnDownRight}
                            style={{ marginLeft: 8 }}
                          />
                        </td>
                      )}
                      <td
                        colSpan={!vida?.titular ? 2 : 1}
                        style={{ textTransform: "capitalize" }}
                      >
                        {vida.nome}
                      </td>
                      <td
                        style={vida.cpfError ? { color: COLORS.dark_red } : {}}
                      >
                        <FH>
                          {formatCPF(vida.cpf)}
                          {vida.cpfError && (
                            <div
                              data-tooltip-content={
                                "CPF já cadastrado ou inválido"
                              }
                              data-tooltip-id="tooltip"
                            >
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                color={COLORS.dark_red}
                              />
                            </div>
                          )}
                        </FH>
                      </td>
                      <td
                        style={
                          vida.emailError
                            ? {
                                color: COLORS.dark_red,
                                textTransform: "lowercase",
                              }
                            : { textTransform: "lowercase" }
                        }
                      >
                        <FH>
                          {vida.email}{" "}
                          {vida.emailError && (
                            <div
                              data-tooltip-content={
                                "Email já cadastrado ou inválido"
                              }
                              data-tooltip-id="tooltip"
                            >
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                color={COLORS.dark_red}
                              />
                            </div>
                          )}
                        </FH>
                      </td>
                      <td>{moment(vida.nascimento).format("DD/MM/YYYY")}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {vida?.titular ? vida.titular.nome : "Titular"}
                      </td>
                      <th
                        style={{
                          gap: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          data-tooltip-id="tooltip"
                          data-tooltip-content={"Editar"}
                          onClick={() => selectVida(vida)}
                        >
                          <FontAwesomeIcon icon={faPencil} fixedWidth={true} />
                        </Button>
                        {!vida.titular && (
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Adicionar Dependente"}
                            onClick={() => newVida(vida.id)}
                          >
                            <FontAwesomeIcon
                              icon={faUserPlus}
                              fixedWidth={true}
                            />
                          </Button>
                        )}
                        {status === "provisorio" && (
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Remover"}
                            onClick={() => removeVida(vida.id)}
                          >
                            <FontAwesomeIcon icon={faTimes} fixedWidth={true} />
                          </Button>
                        )}
                        {status === "ativo" && (
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Cancelar"}
                          >
                            <FontAwesomeIcon icon={faTimes} fixedWidth={true} />
                          </Button>
                        )}
                        {status === "ativo" && (
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Bloquear"}
                          >
                            <FontAwesomeIcon icon={faLock} fixedWidth={true} />
                          </Button>
                        )}
                        {status === "cancelado" && (
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Arquivar"}
                          >
                            <FontAwesomeIcon icon={faTrash} fixedWidth={true} />
                          </Button>
                        )}
                        {status === "bloqueado" && (
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content={"Desbloquear"}
                          >
                            <FontAwesomeIcon
                              icon={faLockOpen}
                              fixedWidth={true}
                            />
                          </Button>
                        )}
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </WhiteBox>
          <WhiteBox>
            <Title>Valores</Title>
            <Container>
              <Row>
                <Col md={4}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: 5, fontWeight: "bold" }}>
                          Tabela de Preços
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        {carregando_precificacao && (
                          <tr>
                            <td colSpan={2}>
                              <Spinner /> Atualizando tabela de preços
                            </td>
                          </tr>
                        )}
                      </tr>
                      <RenderTiers tiers={precificacao?.tiers} />
                    </tbody>
                  </table>
                </Col>
                <Col md={7}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: 5, fontWeight: "bold" }}>
                          Mensalidades
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      {vidas.length === 0 && (
                        <tr>
                          <td colSpan={3} style={{ padding: 5 }}>
                            <Label>Nenhuma vida adicionada</Label>
                          </td>
                        </tr>
                      )}
                      <RenderVidas
                        vidas={vidas}
                        pegaPrecificacaoUsuario={pegaPrecificacaoUsuario}
                      />
                      <tr
                        style={{
                          borderBottom: "1px solid #8a8a8a",
                          fontWeight: "bold",
                        }}
                      >
                        <td style={{ padding: 5 }}>SubTotal</td>
                        <td>
                          <Label>Mensal</Label>
                        </td>
                        <td>
                          {parseFloat(totalPrice)?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                      {vidas.length > 0 && (
                        <>
                          <tr style={{ borderBottom: "1px solid #8a8a8a" }}>
                            {vidas.filter((v) => !v.titular).length > 1 ? (
                              <td style={{ padding: 5 }}>
                                Taxa de Adesão (
                                {parseFloat(taxa_adesao)?.toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}{" "}
                                {` x`}
                                {vidas.filter((v) => !v.titular).length})
                              </td>
                            ) : (
                              <td style={{ padding: 5 }}>Taxa de Adesão</td>
                            )}

                            <td>
                              <Label style={{ fontWeight: "bold" }}>
                                TAXA ÚNICA
                              </Label>
                            </td>
                            <td>
                              {parseFloat(
                                vidas.filter((v) => !v.titular).length *
                                  parseFloat(taxa_adesao)
                              )?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                          </tr>
                        </>
                      )}
                      <tr style={{ fontWeight: "bold" }}>
                        <td style={{ padding: 5 }}>Total</td>
                        <td></td>
                        <td>
                          {parseFloat(total)?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md={1}></Col>
              </Row>
            </Container>
          </WhiteBox>
          <WhiteBox>
            <FHSB style={{ justifyContent: "flex-end", width: "100%" }}>
              {!contrato?.validado && (
                <Button
                  style={
                    contrato?.validando
                      ? {
                          backgroundColor: COLORS.light_grey,
                          borderColor: COLORS.light_grey,
                        }
                      : {
                          backgroundColor: COLORS.main,
                          borderColor: COLORS.main,
                        }
                  }
                  onClick={validate}
                >
                  <FontAwesomeIcon
                    icon={contrato?.validando ? faSpinner : faCheck}
                    style={{ marginRight: 5 }}
                  />
                  {contrato?.validando
                    ? "Validando contrato"
                    : "Validar Contrato"}
                </Button>
              )}
              <Button
                style={
                  contrato?.validado
                    ? {
                        backgroundColor: COLORS.main,
                        borderColor: COLORS.main,
                      }
                    : {
                        backgroundColor: COLORS.light_grey,
                        borderColor: COLORS.light_grey,
                      }
                }
                disabled={!contrato?.validado}
                onClick={enviarContrato}
              >
                <FontAwesomeIcon icon={faSave} /> Criar contrato
              </Button>
              <NavLink to={"/"}>
                <Button color="secondary">Cancelar</Button>
              </NavLink>
            </FHSB>
          </WhiteBox>
        </Col>
      </Row>

      {edit_vida_selected && (
        <Modal isOpen={edit_vida_selected} toggle={closeEditVida}>
          <Vida
            data={edit_vida_selected}
            titulares={vidas.filter((a) => !a.titular)}
            onChange={saveVida}
          />
        </Modal>
      )}
    </Container>
  );
}

function RenderTiers({ tiers }) {
  if (!tiers)
    return (
      <tr>
        <td colSpan={2}>-</td>
      </tr>
    );
  return tiers.map((tier) => {
    return (
      <tr>
        <td>{tier?.up_to ? tier?.up_to : "Demais"} vidas</td>
        <td>
          {parseFloat(tier?.unit_amount / 100).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </td>
      </tr>
    );
  });
}

function RenderVidas({ vidas, pegaPrecificacaoUsuario }) {
  const titulares = vidas.filter((v) => !v.titular);

  return (
    <>
      {titulares.map((vida, index) => {
        const precouser = pegaPrecificacaoUsuario(0);
        const dependentes = vidas.filter((v) => v.titular.id === vida.id);
        return (
          <>
            <tr key={index} style={{ borderBottom: "1px dashed #e7e7e7" }}>
              <td style={{ padding: 5 }}>
                <Text>{vida.nome}</Text>
                <Label>CPF {formatCPF(vida.cpf)}</Label>
              </td>
              <td>
                <Label>Titular</Label>
              </td>
              <td>
                {parseFloat(precouser / 100)?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            {dependentes.map((dep, i) => {
              console.log("DEPENDENTE", dep);
              console.log("DEPENDENTE #", i);
              const precouser = pegaPrecificacaoUsuario(i + 1);
              return (
                <tr key={i} style={{ borderBottom: "1px dashed #e7e7e7" }}>
                  <td style={{ padding: 5 }}>
                    <Text>{dep.nome}</Text>
                    <Label>CPF {formatCPF(dep.cpf)}</Label>
                  </td>
                  <td>
                    <Label>Dependente</Label>
                  </td>
                  <td>
                    {parseFloat(precouser / 100)?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                </tr>
              );
            })}
          </>
        );
      })}
    </>
  );
}

function ExtraData({ tipo, updateCompany, company }) {
  const navigate = useNavigate();
  async function getCompanyInfo() {
    if (company.validando) return;
    if (company.validado) return;
    if (!company.cnpj) return;
    if (company.cnpj.length !== 14) {
      toast.error("CNPJ inválido");
      return;
    }
    updateCompany("validando", true);
    let info = await COMPANIES.get.cnpj_info(company.cnpj);
    console.log(info);
    if (info?.name) {
      if (info?.exists) {
        toast.error("Esta empresa já tem um contrato com a Lince.");
        updateCompany("validando", false);
        updateCompany("validado", false);
        updateCompany("erroCNPJ", true);
      } else {
        updateCompany("validando", false);
        updateCompany("validado", true);
        updateCompany("erroCNPJ", false);
      }
      updateCompany("razao_social", info.name);
      updateCompany("nome_fantasia", info.fantasyName);
      updateCompany("email", info.email?.toLowerCase());
      updateCompany("telefone", info.phone);
      updateCompany("address", `${info.endereco}`);
      updateCompany("address2", info.address2);
      updateCompany("bairro", info.bairro);
      updateCompany("city", info.city);
      updateCompany("state", info.state);
      updateCompany("cep", info.zipCode);
      updateCompany("country", info.country);
    } else {
      updateCompany("validando", false);
      toast.error("CNPJ não encontrado");
    }
  }

  switch (tipo) {
    case ROLES.pj:
    case ROLES.convenio:
      return (
        <WhiteBox id="company-data">
          <Container>
            <Row>
              <Col md={12}>
                <Title>Informações da Empresa</Title>
              </Col>
              <Col md={6}>
                <S.FormFields style={{ gridTemplateColumns: `1fr 30px` }}>
                  <FormContainer>
                    <Label>CNPJ</Label>
                    <FH>
                      <PatternFormat
                        value={company.cnpj}
                        onValueChange={(e) => updateCompany("cnpj", e.value)}
                        format="##.###.###/####-##"
                        customInput={Input}
                        onBlurCapture={() => {
                          getCompanyInfo();
                        }}
                        style={
                          company?.erroCNPJ
                            ? { backgroundColor: COLORS.light_red }
                            : {}
                        }
                      />

                      {company.validado ? (
                        <FontAwesomeIcon
                          icon={company?.erroCNPJ ? faTimes : faCheck}
                          color={
                            company?.erroCNPJ
                              ? COLORS.dark_red
                              : COLORS.dark_green
                          }
                        />
                      ) : company.validando ? (
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                      ) : (
                        <div onClick={getCompanyInfo}>
                          <FontAwesomeIcon
                            icon={
                              company?.erroCNPJ ? faTimes : faMagnifyingGlass
                            }
                            color={
                              company?.erroCNPJ
                                ? COLORS.dark_red
                                : COLORS.dark_grey
                            }
                          />
                        </div>
                      )}
                    </FH>
                  </FormContainer>
                </S.FormFields>
                <Field
                  id="razao_social"
                  name="Razão Social"
                  value={company.razao_social}
                  onChange={(e) =>
                    updateCompany("razao_social", e.target.value)
                  }
                  type="info"
                  style={{ marginTop: 5 }}
                />
                <Field
                  id="nome_fantasia"
                  name="Nome Fantasia"
                  value={company.nome_fantasia}
                  onChange={(e) =>
                    updateCompany("nome_fantasia", e.target.value)
                  }
                  type="info"
                  style={{ marginTop: 5 }}
                />
                <FormContainer>
                  <Label>CEP</Label>
                  <FH>
                    <PatternFormat
                      value={company.cep}
                      onValueChange={(e) => updateCompany("cep", e.value)}
                      format="##.###-###"
                      customInput={Input}
                    />
                  </FH>
                </FormContainer>
                <Field
                  id="address"
                  name="Endereço"
                  value={company.address}
                  onChange={(e) => updateCompany("address", e.target.value)}
                />
                <Field
                  id="address2"
                  name="Complemento"
                  value={company.address2}
                  onChange={(e) => updateCompany("address2", e.target.value)}
                />
                <Field
                  id="bairro"
                  name="Complemento"
                  value={company.bairro}
                  onChange={(e) => updateCompany("bairro", e.target.value)}
                />
                <Field
                  id="city"
                  name="Cidade"
                  value={company.city}
                  onChange={(e) => updateCompany("city", e.target.value)}
                />
                <Field
                  id="state"
                  name="UF"
                  value={company.state}
                  onChange={(e) => updateCompany("state", e.target.value)}
                />
              </Col>
              <Col md={6}>
                <S.FormFields
                  style={{
                    gridTemplateColumns: `repeat(1, 1fr)`,
                  }}
                >
                  <Field
                    id="responsavel"
                    name="Responsavel"
                    value={company.responsavel}
                    onChange={(e) =>
                      updateCompany("responsavel", e.target.value)
                    }
                  />
                  <FormContainer>
                    <Label>CPF do Responsável</Label>
                    <PatternFormat
                      id="cpf_responsavel"
                      name="CPF do Responsável"
                      value={company.cpf_responsavel}
                      onValueChange={(e) =>
                        updateCompany("cpf_responsavel", e.value)
                      }
                      format="###.###.###-##"
                      customInput={Input}
                    />
                  </FormContainer>
                  <Field
                    id="email"
                    name="Email"
                    value={company.email}
                    onChange={(e) => updateCompany("email", e.target.value)}
                  />
                  <FormContainer>
                    <Label>Telefone</Label>
                    <FH>
                      <PatternFormat
                        value={company.telefone}
                        onValueChange={(e) =>
                          updateCompany("telefone", e.value)
                        }
                        format={"(##)#####-####"}
                        customInput={Input}
                      />
                    </FH>
                  </FormContainer>
                </S.FormFields>
              </Col>
            </Row>
          </Container>
        </WhiteBox>
      );

      break;

    default:
      return null;
      break;
  }
}

function Field({ style = {}, id, name, value, onChange, type = "text" }) {
  return (
    <FormContainer style={style}>
      <Label>{name}</Label>
      {type === "info" ? (
        <Text>{value}</Text>
      ) : (
        <Input id={id} value={value} onChange={onChange} type={type} />
      )}
    </FormContainer>
  );
}

export default Novo;
