import React, { useEffect, useState } from "react";

import * as S from "../../main-styles";
import { Container, Row, Col } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import USERS from "../../../api/users";
import COMPANIES from "../../../api/companies";
import { toast } from "react-toastify";
import { validateCPF, validatePhone } from "../../../utils/functions";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import AUTH from "../../../api/auth";
import { PatternFormat } from "react-number-format";

function Register() {
  const navigate = useNavigate();
  const { reference_id, campaign_id } = useParams();
  const [state, setState] = useState({
    status: "loading",
    name: "",
    email: "",
    cpf: "",
    password: "",
    passwordConfirm: "",
    telefone: "",
    reference: null,
    date_birth: "",
    uso: false,
    politica: false,
    isVoucher: false,
    trial_days: 0,
  });
  const {
    name,
    email,
    password,
    telefone,
    passwordConfirm,
    status,
    reference,
    cpf,
    date_birth,
    uso,
    politica,
    isVoucher,
    trial_days,
  } = state;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    console.log("reference_id", reference_id);
    if (reference_id) {
      await getPJData();
    } else {
      if (campaign_id) {
        let result = await getCampaign();
        console.log("campaign", result);
        return setState((s) => ({
          ...state,
          isVoucher: true,
          trial_days: result.trial_days,
          status: "idle",
        }));
      }
      setState((s) => ({ ...state, status: "idle" }));
    }
  }

  async function getCampaign() {
    let campaign = await AUTH.get.getCampaing(campaign_id);
    return campaign;
  }

  async function getPJData() {
    let reference = await COMPANIES.get.byRef(reference_id);
    setState((s) => ({ ...state, reference, status: "idle" }));
  }

  function updateField(key, value) {
    setState((s) => ({ ...state, [key]: value }));
  }

  function updateTelefone(value) {
    // formata o telefone como (XX) XXXXX-XXXX ou (XX) XXXX-XXXX
    let formatted = mtel(value);

    updateField("telefone", formatted);
  }

  function validate() {
    if (!uso || !politica) {
      toast.warn(
        "Você precisa aceitar os termos de uso e a política de privacidade"
      );
      return false;
    }
    if (!name || !email || !password || !telefone || !passwordConfirm) {
      toast.warn("Preencha todos os campos");
      return false;
    }
    let parsedphone = telefone.replace(/\D/g, "");
    if (!validatePhone(parsedphone)) {
      toast.warn("Telefone inválido");
      return false;
    }

    if (password.length < 6) {
      toast.warn("A senha precisa ter no mínimo 6 caracteres");
      return false;
    }

    if (password !== passwordConfirm) {
      toast.warn("As senhas não conferem");
      return false;
    }
    if (!validateCPF(cpf)) {
      toast.warn("CPF inválido");
      return false;
    }
    if (moment().diff(moment(date_birth), "years") < 18) {
      toast.warn("Você precisa ter mais de 18 anos para se cadastrar");
      return false;
    }

    return true;
  }

  async function register(e) {
    e.preventDefault();
    if (status !== "idle") return;
    if (!validate()) return;
    setState((s) => ({ ...state, status: "registering" }));
    let parsedphone = telefone.replace(/\D/g, "");
    let data = {
      name,
      email,
      password,
      phone: parsedphone,
      indication: reference_id,
      date_birth,
      cpf,
      campaign_id,
    };
    try {
      let response = await USERS.set.publicCreation(data);
      console.log("RESPONSE", response);
      if (response) {
        toast.success("Cadastro realizado com sucesso");
        setState((s) => ({ ...state, status: "success" }));
      }
    } catch (error) {
      console.log("Erro", error.response);
      setState((s) => ({ ...state, status: "idle" }));
      toast.warn(error.response.data);
    }
  }

  function mtel(v) {
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return v;
  }

  if (status === "loading") {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <S.Title style={{ color: S.COLORS.main }}>Carregando...</S.Title>
          </Col>
        </Row>
      </Container>
    );
  }

  if (status === "success") {
    return (
      <Container>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <S.WhiteBox style={{ padding: 30, textAlign: "center" }}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={S.COLORS.main}
                size="3x"
              />
              <S.Title style={{ color: S.COLORS.main, marginTop: 10 }}>
                Confira seu email!
              </S.Title>
              <S.Text>
                Enviamos um email de confirmação para você. Por favor, verifique
                sua caixa de entrada e siga as instruções para ativar sua conta.
              </S.Text>
            </S.WhiteBox>
          </Col>
          <Col md={4} />
        </Row>
      </Container>
    );
  }

  let disabled = status !== "idle";

  return (
    <Container>
      <form>
        <Row>
          <Col md={2}></Col>
          <Col
            md={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: 40,
              gap: 10,
            }}
          >
            {isVoucher ? (
              <>
                <img
                  src="https://img.seidecor.com.br/fit-in/700x700/-LIG_iJcJTGJZwPKMJiZ/null/convite.png"
                  style={{
                    borderRadius: 20,
                    width: "60vh",
                    marginBottom: 20,
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                  alt="Convite"
                  className="img-fluid"
                />
                <S.Title style={{ color: S.COLORS.main, textAlign: "center" }}>
                  Convidamos você para conhecer nossos serviços!
                </S.Title>
                <S.Text style={{ textAlign: "center" }}>
                  Preencha o cadastro abaixo e aproveite os benefícios da
                  telemedicina sem custo por {trial_days} dias.
                </S.Text>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 30,
                  paddingBottom: 0,
                  gap: 10,
                }}
              >
                <S.Title style={{ color: S.COLORS.main }}>Cadastro</S.Title>
              </div>
            )}
          </Col>
          <Col md={2}></Col>
          <Col md={2}></Col>
          <Col md={4}>
            <S.WhiteBox>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 30,

                  gap: 10,
                }}
              >
                <S.Label>Nome completo</S.Label>
                <S.Input
                  disabled={disabled}
                  type="text"
                  placeholder="Nome"
                  autoComplete="name"
                  value={name}
                  onChange={(e) => updateField("name", e.target.value)}
                />
                <S.Label>CPF</S.Label>
                <PatternFormat
                  format="###.###.###-##"
                  mask="_"
                  placeholder="CPF"
                  disabled={disabled}
                  value={cpf}
                  onValueChange={(e) => {
                    console.log(e.value);
                    updateField("cpf", e.value);
                  }}
                 
                  customInput={S.Input}
                />

                <S.Label>Data de nascimento</S.Label>
                <S.Input
                  disabled={disabled}
                  type="date"
                  placeholder="Data de nascimento"
                  autoComplete="bday"
                  value={date_birth}
                  onChange={(e) => updateField("date_birth", e.target.value)}
                />

                <S.Label>Telefone</S.Label>
                <S.Input
                  disabled={disabled}
                  type="telefone"
                  autoComplete="tel"
                  value={telefone}
                  placeholder={"(XX) XXXX-XXXX"}
                  onChange={(e) => updateTelefone(e.target.value)}
                />
              </div>
            </S.WhiteBox>
          </Col>
          <Col md={4}>
            <S.WhiteBox>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 30,

                  gap: 10,
                }}
              >
                {" "}
                <S.Label>Email</S.Label>
                <S.Input
                  disabled={disabled}
                  type="email"
                  placeholder="Email"
                  value={email}
                  autoComplete="email"
                  onChange={(e) => updateField("email", e.target.value)}
                />
                <S.Label>Senha</S.Label>
                <S.Input
                  disabled={disabled}
                  type="password"
                  placeholder="Senha"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => updateField("password", e.target.value)}
                />
                <S.Label>Confirme sua senha</S.Label>
                <S.Input
                  disabled={disabled}
                  type="password"
                  placeholder="Confirme sua senha"
                  value={passwordConfirm}
                  onChange={(e) =>
                    updateField("passwordConfirm", e.target.value)
                  }
                />
                <S.Label
                  style={{
                    color: "#1d1d1d",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    disabled={disabled}
                    style={{ marginRight: 5 }}
                    onChange={(e) => updateField("uso", !uso)}
                    value={uso}
                  />
                  <span style={{ fontSize: 10 }}>
                    Li e estou de acordo com os{" "}
                    <a
                      href="https://lincesaude.com.br/termos-de-uso/"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: S.COLORS.main,
                        textDecoration: "none",
                        fontSize: 10,
                      }}
                    >
                      termos de uso
                    </a>
                  </span>
                </S.Label>
                <S.Label
                  style={{
                    color: "#1d1d1d",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    disabled={disabled}
                    style={{ marginRight: 5 }}
                    onChange={(e) => updateField("politica", !politica)}
                    value={politica}
                  />
                  <span style={{ fontSize: 10 }}>
                    Li e estou de acordo com a{" "}
                    <a
                      href="https://lincesaude.com.br/declaracao-de-privacidade/"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: S.COLORS.main,
                        textDecoration: "none",
                        fontSize: 10,
                      }}
                    >
                      declaração de privacidade
                    </a>
                  </span>
                </S.Label>
                <S.Button onClick={register}>
                  {disabled ? "Aguarde..." : "Cadastrar"}
                </S.Button>
              </div>
            </S.WhiteBox>
          </Col>
          <Col md={3}></Col>
        </Row>
      </form>
    </Container>
  );
}

export default Register;
