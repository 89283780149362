import base64 from "base-64";
import USERS from "../api/users";
import { decodeJwt } from "jose";
import moment from "moment-timezone";
import { ROLES } from "./constants";

export function validateCPF(cpf) {
  if (!cpf) {
    return false;
  }
  // validates cpf digit
  if (cpf.length !== 11) {
    return false;
  }

  if (!cpf.match(/\d/g)) {
    return false;
  }
  var numeros = cpf.match(/\d/g).map(Number);
  var soma = numeros.reduce((acc, cur, idx) => {
    if (idx < 9) {
      return acc + cur * (10 - idx);
    }
    return acc;
  }, 0);

  var resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== numeros[9]) {
    return false;
  }

  soma = numeros.reduce((acc, cur, idx) => {
    if (idx < 10) {
      return acc + cur * (11 - idx);
    }
    return acc;
  }, 0);

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== numeros[10]) {
    return false;
  }

  return true;
}

export function formatCPF(cpf) {
  let cpfPontuado = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  return cpfPontuado;
}

export function formatCNPJ(cnpj) {
  try {
    let cnpjPontuado = cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
    return cnpjPontuado;
  } catch (error) {
    return cnpj;
  }
}

export function toB64(text) {
  return base64.encode(text);
}

export function fromB64(text) {
  return base64.decode(text);
}

export function formataTelefone(phone) {
  // (XX) XXXX-XXXX ou (XX) XXXXX-XXXX
  if (!phone) return "";
  if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }
  if (phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }
  return phone;
}

export function validatePhone(phone) {
  if (!phone) return false;
  if (phone.length < 10) return false;
  if (phone.length > 11) return false;
  return true;
}

export async function cpfExists(cpf) {
  let response = await USERS.get.cpfExists(cpf);
  return response;
}
export async function emailExists(email) {
  let response = await USERS.get.emailExists(email);
  return response;
}

export function jwt_decode(token) {
  let decoded = decodeJwt(token);

  return decoded;
}

export function addLeadingZeros(number, size=8){
  let s = String(number);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}

export function ExcelDateToJSDate(date) {
  let converted_date = new Date(Math.round((date - 25569) * 864e5));
        converted_date = String(converted_date).slice(4, 15)
        date = converted_date.split(" ")
        let day = date[1];
        let month = date[0];
        month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
        if (month.toString().length <= 1)
            month = '0' + month
        let year = date[2];
        return String(day + '-' + month + '-' + year.slice(2, 4))
}

export function getContractId(contrato){
  let isPJ = contrato?.empresa_name ? true : false;
  let initial = isPJ ? (contrato.empresa_type === ROLES.pj ? "J" : "C") : "F";
  initial = initial.substring(0, 1).toUpperCase();
  let id = addLeadingZeros(contrato.id, 4);
  let m = moment(contrato.created_at).format("YYMM");
  id =  `${initial}-${m}${id}`
  return id
}

export function formataReal(numero){
  return parseFloat(numero)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) || "R$ -"
}