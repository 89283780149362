import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REQ_STATUS } from "../../utils/constants";
import { toast } from "react-toastify";
import AUTH from "../../api/auth";
const initialState = {
  userData: null,
  token: null,
  refreshToken: null,
  status: REQ_STATUS.IDLE,
  authenticated: false,
  login_form: { cpf: null, password: null },
  vida: false,
  admin: false,
  superadmin: false,
};

export const doLogin = createAsyncThunk(
  "users/login",
  async (arg, { getState, rejectWithValue }) => {
    // pega ultimo state
    const { auth } = getState(); // <-- invoke and access state object
    const { cpf, password } = auth.login_form;
    // pega os dados do login_form
    try {
      let response = await AUTH.get.login(cpf, password);
      return response;
    } catch (error) {
      console.log("error", error);
      toast.warning(error.message);
      throw new Error(error);
    }
  }
);


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateForm: (state, action) => {
      const { field, value } = action.payload;
      // if field  = cpf, then should accept only numbers
      if (field === "cpf") {
        // check if value is a number
        if (isNaN(value)) return state;
      }
      state.login_form[field] = value;
    },
    logout: (state) => {
      // reset state
      state.userData = null;
      state.token = null;
      state.refreshToken = null;
      state.status = REQ_STATUS.IDLE;
      state.authenticated = false;
      state.admin = false;
      state.superadmin = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.userData = action.payload.userData;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.status = REQ_STATUS.SUCCEEDED;
      state.authenticated = true;
      state.login_form = { cpf: "", password: "" };
      state.admin = action.payload.userData.admin;
      state.superadmin = action.payload.userData.superadmin;

      return state;
    });

    builder.addCase(doLogin.rejected, (state, action) => {
      // Add user to the state array
      state.userData = null;
      state.token = null;
      state.status = REQ_STATUS.FAILED;
      state.authenticated = false;
    });

    builder.addCase(doLogin.pending, (state, action) => {
      // Add user to the state array
      state.userData = null;
      state.token = null;
      state.status = REQ_STATUS.LOADING;
      state.authenticated = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { logout, updateForm } = authSlice.actions;

export default authSlice.reducer;
