import { faHeartPulse, faPiggyBank, faSitemap, faSquareDollar } from "@fortawesome/pro-light-svg-icons";
import IconToolTip from "../IconToolTip";

function UserTooltips({ user }) {
    return (
      <>
        {user.is_life && (
          <IconToolTip
            id="icon-life"
            icon={faHeartPulse}
            title="Vida"
            color={"#900000"}
          />
        )}
        {user.billing && (
          <IconToolTip
            id="icon-billing"
            icon={faSquareDollar}
            title="Responsável pelo pagamento"
            color={"#009030"}
          />
        )}
        {user.manage_life && (
          <IconToolTip
            id="icon-manage-life"
            icon={faSitemap}
            title="Gerencia vidas"
            color={"#620090"}
          />
        )}
        {user.cash_back && (
          <IconToolTip
            id="icon-cash-back"
            icon={faPiggyBank}
            title="Comissionado"
            color={"#003e90"}
          />
        )}
      </>
    );
  }

  export default UserTooltips;