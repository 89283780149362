import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ROLES } from "../utils/constants";
import Admin from "./Admin";
import Login from "./Authentication/Login";
import Register from "./Authentication/Register";
import ResetPassword from "./Authentication/ResetPassword";

import Usuarios from "./Admin/Usuarios";
import UsuariosDetalhes from "./Admin/Usuarios/Detalhes";
import UsuariosNovo from "./Admin/Usuarios/Novo";

import AdminContratos from "./Admin/Contratos";
import Convenios from "./Admin/Convenios";
import Dashboard from "./Admin/Dashboard";
import Empresas from "./Admin/Empresas";
import Titulares from "./Admin/Titulares";

import Activate from "./Activate";
import Atendimento from "./Atendimento";
import Config from "./Config";
import Contratos from "./Contratos";
import DetalhesContrato from "./Contratos/Detalhes";
import NovoContrato from "./Contratos/Novo";
import ReviewContrato from "./Contratos/Review";
import Home from "./Home";
import Page404 from "./Page404";
import User from "./User";
import Pricing from "./Admin/Pricing";

function Rotas() {
  const { auth } = useSelector((state) => state);
  const { authenticated } = auth;
  // TI Lince
  const superadmin = auth?.userData?.role === ROLES.superadmin;
  // Admins Lince
  const admin = auth?.userData?.role === ROLES.admin;
  return (
    <Routes>
      {/* Public routes */}
      <>
        <Route path="/contratos/review/:uuid" element={<ReviewContrato />} />
        <Route
            path="/auth/reset-password/:passwordlink"
            element={<ResetPassword />}
          />
      </>
      {!authenticated ? (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/auth/login" element={<Login />} />
          
          <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/register/ref/:reference_id" element={<Register />} />
          <Route path="/auth/register/campaign/:campaign_id" element={<Register />} />
          <Route path="/activate/:id" element={<Activate />} />
          <Route path="/consultores/onboarding" element={<Login />} />
        </>
      ) : (
        <>
          {/* Main routes */}
          <></>
          {/* Admin and Superadmin route */}
          {(superadmin || admin) && (
            <>
              <Route path="/auth/register" element={<Dashboard />} />
              <Route path="/auth/activate/:id" element={<Dashboard />} />
              <Route path="/auth/register/:id" element={<Dashboard />} />
              <Route path="/auth/login" element={<Dashboard />} />
              <Route path="/admin" element={<Admin />}>
                <Route path="/admin" element={<Dashboard />} />
                <Route path="contratos" element={<AdminContratos />} />
                <Route path="usuarios" element={<Usuarios />} />
                <Route path="precificacao" element={<Pricing />} />
                <Route
                  path="usuarios/detalhes/:id"
                  element={<UsuariosDetalhes />}
                />
                <Route path="usuarios/novo" element={<UsuariosNovo />} />
                <Route path="convenios" element={<Convenios />} />
                <Route
                  path="convenios/detalhes/:id"
                  element={<div>Convênios</div>}
                />
                <Route path="pj" element={<Empresas />} />
                <Route path="pj/detalhes/:id" element={<div>Empresa</div>} />
                <Route path="titulares" element={<Titulares />} />
                <Route
                  path="titulares/detalhes/:id"
                  element={<div>Titular</div>}
                />
                <Route
                  path="titulares/detalhes/:id/dependente/:id"
                  element={<div>Dependente</div>}
                />
              </Route>
            </>
          )}
          {/* Authenticated Routes */}
          <>
            <Route path="/" element={<Home />} />
            <Route path="/u/:id" element={<User />} />
            <Route path="/config" element={<Config />} />
            <Route path="/contratos" element={<Contratos />} />
            <Route path="/contratos/novo" element={<NovoContrato />} />
            <Route path="/contratos/meu" element={<NovoContrato />} />
            <Route
              path="/contratos/detalhes/:uuid"
              element={<DetalhesContrato />}
            />
            <Route path="/contratos/editar/:uuid" element={<NovoContrato />} />
            <Route path="/auth/login" element={<Home />} />
            <Route path="/atendimento" element={<Atendimento />} />
            <Route path="/atendimento/:id" element={<Atendimento />} />
          </>
          <Route path="*" element={<Page404 />} />
        </>
      )}
    </Routes>
  );
}

export default Rotas;
