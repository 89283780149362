
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div``;

export const LinkMenu = styled(NavLink)`
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
    
    &:hover {
        text-decoration: underline;
        color: #e0ffec;
    }
`;
