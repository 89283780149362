import React from "react";
import { Container, Row, Col } from "reactstrap";
import * as S from "./styles";
import { useSelector } from "react-redux";
import {
  NavLink,
  useMatch,
  useNavigate,
  useNavigation,
  useRoutes,
} from "react-router-dom";

import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth/auth_slice";
import SubHeader from "../SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faHeadset, faHome } from "@fortawesome/pro-light-svg-icons";
import LOGO from "../../assets/logo_s.png";
function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // checks

  const { auth } = useSelector((state) => state);
  const { authenticated, userData } = auth;
  const nome = userData?.name?.split(" ")[0] || "Usuário";
  console.log("auth", auth);
  function sair() {
    dispatch(logout());
    navigate("/");
  }

  function gotoMain() {
    navigate("/");
  }
  let REVIEW_ROUTE = useMatch("/contratos/review/:uuid");

  return (
    <>
      <Container>
        <Row className="flex-column-reverse flex-md-row">
          <Col md={6}>
            <S.Logo src={LOGO} onClick={gotoMain} />
          </Col>
          {!REVIEW_ROUTE && (
            <Col md={6}>
              {authenticated ? (
                <>
                  <S.Menu>
                    <div>Olá, {nome}</div>
                    <S.MenuItem
                      to="https://lince.freshdesk.com/"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faHeadset} size="lg" />
                    </S.MenuItem>

                    <S.MenuItem to="/config">
                      <FontAwesomeIcon icon={faCog} size="lg" />
                    </S.MenuItem>
                    <div onClick={sair} className="pointer">
                      Sair
                    </div>
                  </S.Menu>
                </>
              ) : (
                <S.Menu>
                  <S.MenuItem to={"/auth/login"}>Entrar</S.MenuItem>
                  <S.MenuItem to={"/auth/register"}>
                    Ainda não é cliente?
                  </S.MenuItem>
                </S.Menu>
              )}
            </Col>
          )}
        </Row>
        {!REVIEW_ROUTE && <SubHeader />}
      </Container>
    </>
  );
}

export default Header;
