import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  line-height: 1;
 
`;

export const TitleContainer = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const TitleLabel = styled.div`
  grid-area: 1 / 1 / 2 / 5;
  color: ${(props) => props.color};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;
export const TitleName = styled.div`
  grid-area: 2 / 1 / 3 / 5;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  color: ${(props) => props.color};
  text-transform: capitalize;
`;
export const TitleButtonContainer = styled.div`
  grid-area: 1 / 5 / 3 / 6;
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TitleButton = styled.div`
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 30px;
  padding: 10px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: 0px 0px 10px 0px #00000010;
  &:hover {
    filter: brightness(1.1);
  }
`;

export const MenuContainer = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  // 1 column grid
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentContainer = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  background-color: #ffffff67;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000010;
  backdrop-filter: blur(5px);
`;
