// pagina de criação de assinatura para pessoa juridica
import React from "react";

import * as S from "./styles";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  Button,
  COLORS,
  FHSB,
  Input,
  Label,
  Text,
  Title,
} from "../main-styles";
import { cpfExists, emailExists, validateCPF } from "../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSpinner,
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { ROLES } from "../../utils/constants";
import USERS from "../../api/users";

/*
        name, // required
      email, // required
      cpf, // required
      password, // not-required
      is_life, // ✅ required and should be true if no parent is provided
      billing, // ✅ required, and should be true if no parent is provided and is_life is true
      manage_life, // ✅ required, and should be true if no parent is provided and is_life is true
      cash_back, // required
      parent, // not-required
      parent_type, // not-required: should be "titular", "convenio", "pj"
      birth_date, // required

*/

function NovaAssinatura({ isOpen, callback, close }) {
  const { userData } = useSelector((state) => state.auth);
  console.log("userData", userData);
  const [state, setState] = React.useState({
    name: "",
    cpf: "",
    birthdate: "",
    email: "",
    telefone: "",
    testing: false,
    verificandoCPF: false,
    verificandoEmail: false,
    emailInvalido: false,
    cpfInvalido: false,
    telefone: "",
    cpfValidado: false,
    emailValidado: false,
    enviando: false,
  });
  const {
    name,
    cpf,
    birthdate,
    email,
    testing,
    verificandoCPF,
    verificandoEmail,
    emailInvalido,
    cpfInvalido,
    telefone,
    cpfValidado,
    emailValidado,
    enviando,
  } = state;

  function updateCpf(value) {
    // only accepts numbers
    setState((s) => ({
      ...s,
      verificandoCPF: false,
      cpfInvalido: false,
      cpfValidado: false,
    }));
    console.log(value);
    if (!/^\d+$/.test(value)) return;
    // only accepts 11 characters
    if (value.length > 11) return;
    setState((s) => ({ ...s, cpf: value }));
  }

  function updateTelefone(value) {
    // only accepts numbers
    setState((s) => ({ ...s, telefone: value }));
    console.log(value);
    if (!/^\d+$/.test(value)) return;
    // only accepts 11 characters
    if (value.length > 11) return;
    setState((s) => ({ ...s, telefone: value }));
  }

  function updateEmail(value) {
    setState((s) => ({
      ...s,
      email: value,
      emailInvalido: false,
      emailValidado: false,
    }));
  }

  async function checkCPF(e) {
    setState((s) => ({
      ...s,
      verificandoCPF: true,
      cpfInvalido: false,
      cpfValidado: false,
    }));
    // limpar cpf
    let cpfLimpo = cpf.replace(/\D/g, "");
    // validar cpf
    if (cpfLimpo.length !== 11) {
      toast.error(`CPF inválido`);
      setState((s) => ({
        ...s,
        cpfInvalido: true,
        verificandoCPF: false,
        cpfValidado: true,
      }));
      return;
    }

    if (!validateCPF(cpfLimpo)) {
      toast.error("CPF inválido");
      setState((s) => ({
        ...s,
        cpfInvalido: true,
        verificandoCPF: false,
        cpfValidado: true,
      }));
      return;
    }
    // verificar se cpf existe
    let cpfExiste = await cpfExists(cpfLimpo);
    if (cpfExiste) {
      toast.error("CPF já cadastrado");
      setState((s) => ({
        ...s,
        cpfInvalido: true,
        cpfValidado: true,
        verificandoCPF: false,
      }));
      return;
    } else {
      setState((s) => ({
        ...s,
        cpfInvalido: false,
        cpfValidado: true,
        verificandoCPF: false,
      }));
      return;
    }
  }

  async function checkEmail() {
    // verificar se email existe
    setState((s) => ({
      ...s,
      verificandoEmail: true,
      emailInvalido: false,
      emailValidado: false,
    }));
    let emailExiste = await emailExists(email);
    if (emailExiste) {
      console.log("email existe");
      setState((s) => ({
        ...s,
        emailInvalido: true,
        emailValidado: true,
        verificandoEmail: false,
      }));
      return;
    } else {
      console.log("email não existe");
      setState((s) => ({
        ...s,
        emailInvalido: false,
        emailValidado: true,
        verificandoEmail: false,
      }));
      return;
    }
  }

  function validate() {
    if (emailInvalido) {
      return toast.error("Email inválido ou já cadastrado");
    }
    if (cpfInvalido) {
      return toast.error("CPF inválido ou já cadastrado");
    }
    if (
      name === "" ||
      cpf === "" ||
      birthdate === "" ||
      email === "" ||
      telefone === ""
    ) {
      return toast.error("Preencha todos os campos");
    }
    if(name.split(" ").length < 2) {
      return toast.error("Preencha o nome completo");
    }

    if(email.split("@").length < 2) {
      return toast.error("Preencha um email válido");
    }

    if (moment(birthdate).isAfter(moment().subtract(18, "years"))) {
      return toast.error("O titular deve ser maior de idade");
    }
    cadastra();
  }

  async function cadastra() {
    setState((s) => ({ ...s, enviando: true }));
    let shouldPay = true;
    switch (true) {
      case userData?.role === ROLES.pj && userData?.billing === true:
        shouldPay = false;

        break;
      case userData?.role === ROLES.pj && userData?.billing === false:
        shouldPay = true;

        break;
      case userData?.role === ROLES.convenio && userData?.billing === true:
        shouldPay = false;

        break;
      case userData?.role === ROLES.convenio && userData?.billing === false:
        shouldPay = true;

        break;
      default:
        shouldPay = true;
        break;
    }
    // cadastra o titular
    let payload = {
      name,
      email,
      cpf,
      role: "titular",
      is_life: true,
      billing: shouldPay,
      manage_life: true,
      cash_back: false,
      parent: {
        id: userData.id,
        type: userData.role,
      },
    };
    try {
      let response = await USERS.set.create(payload);
      console.log("response", response);
      if (response.id) {
        toast.success("Titular cadastrado com sucesso");
        callback(response);
        close();
      } else {
        toast.error("Erro ao cadastrar titular");
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data || "Erro ao cadastrar titular");
    }

    setState((s) => ({ ...s, enviando: false }));
  }

  if (!isOpen) return null;

  return (
    <Modal isOpen={true} size="xl">
      <ModalHeader toggle={close}>Nova Assinatura</ModalHeader>
      <ModalBody>
        <S.Container>
          <div className="alert alert-warning">
            <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
            <strong>Cadastro de titular.</strong>
            <br />
            Os dependentes deverão ser adicionados acessando o perfil do titular
            após o cadastro
          </div>
          <Label>Nome</Label>
          <Input
            value={name}
            onChange={(e) => setState((s) => ({ ...s, name: e.target.value }))}
          />
          <Label>CPF</Label>
          <FHSB>
            <PatternFormat
              value={cpf}
              format="###.###.###-##"
              mask="_"
              onValueChange={(values) => {
                updateCpf(values.value);
              }}
              onBlur={checkCPF}
              customInput={Input}
            />
            {verificandoCPF && <FontAwesomeIcon icon={faSpinner} spin />}
            {cpfValidado && (
              <FontAwesomeIcon
                icon={cpfInvalido ? faTimes : faCheck}
                color={cpfInvalido ? COLORS.red : COLORS.green}
              />
            )}
          </FHSB>
          <Label>Data de nascimento</Label>
          <Input
            value={birthdate}
            type="date"
            onChange={(e) =>
              setState((s) => ({ ...s, birthdate: e.target.value }))
            }
          />
          <Label>Email</Label>
          <FHSB>
            <Input
              value={email}
              onBlur={checkEmail}
              onChange={(e) => updateEmail(e.target.value)}
            />
            {verificandoEmail && <FontAwesomeIcon icon={faSpinner} spin />}
            {emailValidado && (
              <FontAwesomeIcon
                icon={emailInvalido ? faTimes : faCheck}
                color={emailInvalido ? COLORS.red : COLORS.green}
              />
            )}
          </FHSB>
          <Label>Celular</Label>
          <PatternFormat
            format="(##)#####-####"
            mask="_"
            customInput={Input}
            value={telefone}
            onValueChange={(values) => {
              updateTelefone(values.value);
            }}
          />
          <Button
            onClick={() => {
              validate();
            }}
            disabled={enviando}
          >
            {enviando ? "Aguarde..." : "Criar Assinatura"}
          </Button>
        </S.Container>
      </ModalBody>
    </Modal>
  );
}

export default NovaAssinatura;
