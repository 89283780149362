import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const ContainerDireito = styled.div`
  grid-area: 1 / 5 / 2 / 6;
`;
export const ContainerEsquerdo = styled.div`
  grid-area: 1 / 1 / 2 / 5;
`;

export const DependentContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  // grid
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;
`;

export const Dependent = styled.div``;

export const DependentName = styled.div`
  color: #979797;
  font-size: 13px;
  font-weight: bold;
`;

export const DependentImage = styled.div``;

export const DependentInfo = styled.div`
  color: #ccc;
  font-size: 13px;
`;

export const DependentButton = styled.div``;
