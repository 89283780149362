import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Top = styled.div`
  grid-area: 1 / 1 / 2 / 3;
`;

export const SintomasContainer = styled.div`
  grid-area: 2 / 1 / 3 / 2;
`;

export const InfoContainer = styled.div`
  grid-area: 2 / 2 / 3 / 3;
`;

export const BottomContainer = styled.div`
  grid-area: 3 / 1 / 4 / 3;
`;

export const Sintoma = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #e6e6e6; */
  padding: 10px;
  border-radius: 8px;
  background-color: #f1f8fd;
`;

export const SintomaName = styled.div`
    font-size: 12px;
    color: #333;
    font-weight: bold; 
`;

export const RemoveButton = styled.div`
  cursor: pointer;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  background-color: #ad3b3b;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
