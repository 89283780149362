import styled from "styled-components";
import { COLORS } from "../main-styles";

export const Container = styled.div`
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const WelcomeMessage = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  background-color: ${COLORS.main};
  padding: 40px;
  border-radius: 25px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 0px 0px 10px 0px #00000010;
  @media (max-width: 768px) {
    grid-area: 1 / 1 / 2 / 2;
  }
`;

export const Section = styled.div`
  background-color: #ffffff77;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000010;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  backdrop-filter: blur(5px);
`;
export const Content = styled.div`
  display: grid;
  gap: 20px;
`;

export const UltraContent = styled.div`
  grid-area: 1 / 2 / 2 / 5;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    grid-area: 2 / 1 / 3 / 2;
  }
`;

export const Contents = styled(Section)`
  grid-area: 2 / 1 / 3 / 2;
`;

export const SecondContent = styled(Section)`
  grid-area: 2 / 2 / 3 / 5;
  @media (max-width: 768px) {
    grid-area: 3 / 1 / 4 / 2;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${COLORS.main};
`;

export const Description = styled.div`
  font-size: 15px;
  color: #333333;
`;

export const Button = styled.div`
  background-color: ${COLORS.secondary};
  color: white;
  border-radius: 40px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
  :hover {
    filter: brightness(1.1);
  }
`;

export const LifesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  border-top: 1px solid #00000010;
  padding-top: 20px;
  width: 100%;
`;

export const LifeCard = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px #00000010;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LifeTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${COLORS.main};
`;

export const LifeDescription = styled.div`
  font-size: 10px;
  color: #333333;
  display: flex;
  gap: 5px;
`;

export const LifeDeleteButton = styled.div`
  font-size: 10px;
  background-color: #e64f4f;
  color: white;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
