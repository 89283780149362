import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { useParams } from "react-router-dom";
import PA from "../../api/pa";
import {
  Title,
  Text,
  Description,
  WhiteBox,
  TextArea,
  GappedContainer,
  Select,
  Button,
} from "../main-styles";
import { Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";
import Waiting from "./Waiting";

const STATUS = {
  LOADING: "loading",
  LOADED: "loaded",
  INICIANDO_ATENDIMENTO: "iniciandoAtendimento",
};

function Atendimento() {
  const { id } = useParams();

  const [state, setState] = useState({
    id: id || "",
    sintomas: [],
    status: STATUS.LOADING,
    sintomasSelecionados: [],
    screening: "",
    id_atendimento: null,
  });
  const { sintomas, status, sintomasSelecionados, screening, id_atendimento } =
    state;

  useEffect(() => {
    init();
  }, [id]);

  async function init() {
    let promises = [];
    promises.push(loadSympthoms());
    await Promise.all(promises);
    setState((s) => ({ ...s, status: STATUS.LOADED }));
  }

  async function loadSympthoms() {
    let response = await PA.get.sintomas();
    setState((s) => ({ ...s, sintomas: response }));
  }

  function selecionaSintoma(id) {
    setState((s) => {
      let sintoma = sintomas.find((sintoma) => sintoma.value === id);
      return {
        ...s,
        sintomasSelecionados: [...s.sintomasSelecionados, sintoma],
      };
    });
  }

  function deselecionaSintoma(sintoma) {
    setState((s) => {
      return {
        ...s,
        sintomasSelecionados: s.sintomasSelecionados.filter(
          (item) => item.value !== sintoma.value
        ),
      };
    });
  }

  function validate() {
    if (sintomasSelecionados.length === 0) {
      toast.warn("Selecione ao menos um sintoma");
      return false;
    }
    return true;
  }

  function setStatus(status) {
    setState((s) => ({ ...s, status }));
  }

  async function iniciarAtendimento() {
    if (!validate()) return;
    setStatus(STATUS.INICIANDO_ATENDIMENTO);
    try {
      let dadosAtendimento = await PA.set.new({
        sintomas: sintomasSelecionados.map((s) => s.value),
        screening,
      });
      console.log("RESPOSTA", dadosAtendimento);

      toast.success("Atendimento iniciado com sucesso");
    } catch (error) {
      console.error(error);
      setStatus(STATUS.LOADED);
      toast.error(error.response.data);
    }
  }
  if (status === STATUS.LOADING)
    return (
      <Container>
        <Row>
          <Col md={12}>Carregando...</Col>
        </Row>
      </Container>
    );

  if (status === STATUS.INICIANDO_ATENDIMENTO) {
    return <Waiting id_atendimento={id_atendimento} />;
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <S.Container>
            <S.Top>
              <WhiteBox style={{ marginTop: 20 }}>
                <Title>Quase lá</Title>
                <Text>
                  Iniciaremos o seu atendimento o mais breve possível, mas antes
                  precisamos de algumas informações
                </Text>
              </WhiteBox>
            </S.Top>
            <S.SintomasContainer>
              <WhiteBox style={{ height: "100%" }}>
                <Title>Qual o motivo da consulta?</Title>
                <Description>
                  Selecione os sintomas que você está sentindo
                </Description>
                <GappedContainer>
                  {sintomasSelecionados.map((sintoma) => (
                    <Sintoma
                      data={sintoma}
                      onClick={() => deselecionaSintoma(sintoma)}
                    />
                  ))}
                  <Select
                    onChange={(e) => selecionaSintoma(e.target.value)}
                    value=""
                  >
                    <option value="">Selecione o motivo da consulta</option>
                    {sintomas
                      .filter((a) => sintomasSelecionados.indexOf(a) === -1)
                      .map((sintoma) => {
                        //   console.log(sintoma);
                        return (
                          <option key={sintoma.value} value={sintoma.value}>
                            {sintoma.description}
                          </option>
                        );
                      })}
                  </Select>
                </GappedContainer>
              </WhiteBox>
            </S.SintomasContainer>
            <S.InfoContainer>
              <WhiteBox style={{ height: "100%" }}>
                <Title>Mais informações</Title>
                <Text>
                  Existe mais alguma informação que seja importante o médico
                  saber?
                </Text>
                <TextArea
                  style={{ marginTop: 10 }}
                  value={screening}
                  placeholder="Alguns exemplos: Quando começaram os sintomas? Houve algum
                  incidente que ocasionou os sintomas?"
                  onChange={(e) =>
                    setState((s) => ({ ...s, screening: e.target.value }))
                  }
                />
              </WhiteBox>
            </S.InfoContainer>
            <S.BottomContainer
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button style={{ width: 200 }} onClick={iniciarAtendimento}>
                Iniciar atendimento
              </Button>
            </S.BottomContainer>
          </S.Container>
        </Col>
      </Row>
    </Container>
  );
}

function Sintoma({ data, onClick }) {
  return (
    <S.Sintoma>
      <S.SintomaName>{data.description}</S.SintomaName>
      <S.RemoveButton onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} />
      </S.RemoveButton>
    </S.Sintoma>
  );
}

export default Atendimento;
