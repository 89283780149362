import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { COLORS } from "../../routes/main-styles";
export const Container = styled.div``;

export const Logo = styled.img`
  width: auto;
  height: 40px;
  margin-top: 20px;
  @media (max-width: 768px) {
    background-color: #ffffffc5;
    padding: 20px;
    border-radius: 15px;
    height: 70px;
    margin-top: 5px;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${COLORS.main};
  color: white;
  border-radius: 0px 0px 15px 15px;
  gap: 30px;
  padding: 20px 30px;
  font-size: 1.2em;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const MenuItem = styled(NavLink)`
  border-radius: 5px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 1em;
  &:hover {
    text-decoration: underline;
  }
`;
