import React from "react";

import * as S from "./styles";
import { faUser, faBuilding } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Entidade({
  imagem,
  nome = " - ",
  descricao = "-",
  tipo = "pf",
  onSelect
}) {
  const cor = tipo === "pf" ? "#06a259" : "#2878a7";
  let icone = tipo === "pf" ? faUser : faBuilding;
  return (
    <S.Container cor={cor} onClick={onSelect}>
      {imagem && <S.Imagem src={imagem} />}
      {!imagem && <FontAwesomeIcon icon={icone} color={cor} size="2x" />}
      <div style={{ flex: 1, marginLeft: 15 }}>
        <S.Nome cor={cor}>{nome}</S.Nome>
        <S.Descricao>{descricao}</S.Descricao>
      </div>
    </S.Container>
  );
}

export default Entidade;
