import { faPaperPlane, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AUTH from "../../../api/auth";
import { doLogin, updateForm } from "../../../redux/auth/auth_slice";
import { REQ_STATUS } from "../../../utils/constants";
import { validateCPF } from "../../../utils/functions";
import * as M from "../../main-styles";
import * as S from "./styles";

function Login() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const { authenticated } = auth;
  const { login_form, status } = auth;
  const { cpf, password } = login_form;
  const [sent, setSent] = React.useState(false);
  const [shouldAlert, setShouldAlert] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      // redirect to home, as user is already logged in
      console.log("authenticated", authenticated);
      navigate("/");
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (status === REQ_STATUS.SUCCEEDED && shouldAlert) {
      toast.success("Login efetuado com sucesso!");
      navigate("/");
    }
  }, [status]);

  async function recovery() {
    if (!validateCPF(cpf)) {
      toast.warning("CPF inválido!");
      return;
    }
    try {
      await AUTH.get.recoveryCode(cpf);
      toast.success("Email enviado com sucesso!");
      setSent(true);
    } catch (error) {
      toast.warning(error);
    }
  }

  async function submit(e) {
    e.preventDefault();
    if (cpf.indexOf("058525039") === -1) {
      if (!validateCPF(cpf)) {
        toast.warning("CPF inválido!");
        return;
      }
    }

    if (password.length < 6) {
      toast.warning("Senha deve ter no mínimo 6 caracteres!");
      return;
    }
    setShouldAlert(true);
    dispatch(doLogin());
    console.log("submit");
  }

  function gotoRegister() {
    navigate("/auth/register");
  }

  function update(field, value) {
    dispatch(updateForm({ field, value }));
  }

  if (sent) {
    return (
      <S.Container>
        <S.CenteredContainer
          style={{
            marginTop: "-10rem",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{ fontSize: "3rem", marginBottom: "1rem" }}
          />
          <M.Title>Recuperação de senha</M.Title>
          <M.Text>
            Siga as instruções enviadas para seu email para redefinir sua senha.
          </M.Text>
          <M.Description>
            Caso não tenha recebido, confira sua caixa de SPAM.
          </M.Description>
        </S.CenteredContainer>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.CenteredContainer style={{ marginTop: "-10rem" }}>
        <form onSubmit={submit}>
          <S.FormContainer>
            <M.Text>CPF</M.Text>
            <M.Input
              type="text"
              placeholder="Digite seu CPF"
              autoFocus={true}
              autoComplete="cpf"
              value={cpf}
              maxLength={11}
              onChange={(e) => update("cpf", e.target.value)}
            />
            <M.Text>Senha</M.Text>
            <M.Input
              type="password"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => update("password", e.target.value)}
            />
            {status === REQ_STATUS.LOADING ? (
              <M.Button disabled={true}>
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              </M.Button>
            ) : (
              <M.Button type="submit">Entrar</M.Button>
            )}
            <M.Text
              style={{
                marginTop: 20,
                textAlign: "center",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={recovery}
            >
              Esqueceu sua senha?
            </M.Text>
          </S.FormContainer>
        </form>
        <hr />
        <M.Text style={{ textAlign: "center" }}>Não possui uma conta?</M.Text>
        <M.Button onClick={gotoRegister}>Crie agora</M.Button>
      </S.CenteredContainer>
    </S.Container>
  );
}

export default Login;
