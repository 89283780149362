import {
  faHandshake,
  faHeartPulse,
  faLock,
  faPassport,
  faPerson,
  faPiggyBank,
  faSitemap,
  faSquareDollar,
} from "@fortawesome/pro-light-svg-icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import USERS from "../../../api/users";
import IconToolTip from "../../../components/IconToolTip";
import { REQ_STATUS, USER_STATS } from "../../../utils/constants";
import { Button, Input, Subtitle } from "../../main-styles";
import RenderRole from "../../../components/RenderRole";
import Spinner from "../../../components/Spinner";
import RenderUserStats from "../../../components/RenderUserStats";
import { toB64 } from "../../../utils/functions";
import UserTooltips from "../../../components/UserToolTips";
import { ActionButton, ButtonsContainer } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import AUTH from "../../../api/auth";

function Usuarios() {
  const [state, setState] = React.useState({
    status: REQ_STATUS.LOADING,
    data: [],
    perPage: 10,
    page: 1,
    search: "",
    sort: "name",
    order: "asc",
    total: 0,
    selectedUser: null,
  });
  const navigate = useNavigate();
  const {
    status,
    data,
    perPage,
    page,
    search,
    sort,
    order,
    total,
    selectedUser,
  } = state;

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    const promises = [USERS.get.all(page, perPage, search), USERS.get.count()];
    const [data, total] = await Promise.all(promises);
    setState((state) => ({ ...state, data, total, status: REQ_STATUS.IDLE }));
  }

  function gotoUser(id) {
    console.log("gotoUser", id);
    navigate(`/u/${toB64(id)}`);
  }

  function selectUser(id) {
    setState((state) => ({ ...state, selectedUser: id }));
  }

  function closeUser() {
    setState((state) => ({ ...state, selectedUser: null }));
  }

  return (
    <div>
      <Subtitle>Usuários</Subtitle>
      <div
        style={{
          display: "flex",
          paddingBottom: 5,
          marginBottom: 5,
          borderBottom: "1px solid #ccc",
        }}
      >
        <Input placeholder="Pesquisar" style={{ marginRight: 5 }} />
        <Button style={{ marginRight: 5 }}>Pesquisar</Button>
        <Button onClick={() => navigate("/admin/usuarios/novo")}>
          Adicionar
        </Button>
      </div>
      {status === REQ_STATUS.LOADING ? (
        <Spinner />
      ) : (
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col" style={{ width: 120 }}></th>
              <th scope="col">Nome</th>
              <th scope="col">Tipo</th>
              <th scope="col">CPF</th>
              <th scope="col" style={{ width: 30 }}>
                Status
              </th>
              <th scope="col" style={{ width: 30 }}>
                E-mail
              </th>
              <th scope="col" style={{ width: 30 }}>
                {/* Ações */}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((user) => (
              <tr key={user.id}>
                <td style={{ textAlign: "center" }}>
                  <UserTooltips user={user} />
                </td>
                <td
                  onClick={() => {
                    selectUser(user);
                  }}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                >
                  {user.name}
                </td>
                <td>
                  <RenderRole role={user.role} />
                </td>
                <td>{user.cpf}</td>
                <td>
                  <RenderUserStats stats={user.status} />
                </td>
                <td>{user.email}</td>
                <td>
                  {/* <Button onClick={() => gotoUser(user.id)}>Gerenciar</Button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal isOpen={selectedUser !== null} toggle={closeUser}>
        <UserInfo user={selectedUser} close={closeUser} />
        <Tooltip id="tooltip2" />
      </Modal>
    </div>
  );
}

function UserInfo({ user, close }) {
  console.log("UserInfo", user);
  const [sendingWelcomeEmail, setSendingWelcomeEmail] = React.useState(false);
  const [sendingActivationEmail, setSendingActivationEmail] =
    React.useState(false);
  const [sendingResetPasswordEmail, setSendingResetPasswordEmail] =
    React.useState(false);
  function blockUser() {
    // USERS.set.
  }

  async function resendActivationLink() {
    if (sendingActivationEmail) return;
    let id = user.id;
    setSendingActivationEmail(true);
    try {
      await USERS.set.send_activation(id);
      toast.success("Email enviado com sucesso");
    } catch (error) {
      alert(error);
    }
    setSendingActivationEmail(false);
  }

  async function resendWelcomeEmail() {}

  async function sendResetPasswordEmail() {
    if (sendingResetPasswordEmail) return;
    let cpf = user.cpf;
    try {
      setSendingResetPasswordEmail(true);
      await USERS.set.send_reset(cpf);
      toast.success("Email enviado com sucesso");
    } catch (error) {
      alert(error);
    }
    setSendingResetPasswordEmail(false);
  }

  if (!user) {
    return null;
  }
  return (
    <>
      <ModalHeader toggle={close}>{user.name}</ModalHeader>
      <ModalBody>
        <ButtonsContainer>
          {/* <ActionButton
            data-tooltip-id="tooltip2"
            data-tooltip-content={"Bloquear usuário"}
          >
            <FontAwesomeIcon icon={faLock} size={"2x"} />
          </ActionButton> */}
          {/* <ActionButton
            data-tooltip-id="tooltip2"
            data-tooltip-content={"Ativar usuário"}
          >
            <FontAwesomeIcon icon={faPerson} size={"2x"} />
          </ActionButton> */}
          {user.status === USER_STATS.preactive && (
            <ActionButton
              data-tooltip-id="tooltip2"
              data-tooltip-content={"Re-enviar boas vindas"}
              onClick={resendActivationLink}
            >
              <FontAwesomeIcon icon={faHandshake} size={"2x"} />
            </ActionButton>
          )}
          <ActionButton
            data-tooltip-id="tooltip2"
            data-tooltip-content={"Enviar email de redefinição de senha"}
            onClick={sendResetPasswordEmail}
          >
            <FontAwesomeIcon icon={faPassport} size={"2x"} />
          </ActionButton>
        </ButtonsContainer>
      </ModalBody>
    </>
  );
}

export default Usuarios;
