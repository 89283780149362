import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #323232;
    align-items: center;
    cursor: pointer;
`;

export const Title = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: #a3a3a3;
    margin-bottom: 5px;
`;

export const User = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    gap: 10px;
    cursor: pointer;
`;

export const UserImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const UserName = styled.div`
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
`;

export const UserText  = styled.div`
    font-size: 12px;
    color: #a3a3a3;
`;

export const UserRole = styled.div`
    font-size: 12px;
    color: #a3a3a3;
`;

