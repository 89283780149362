import React from "react";
import LOGO from "../../assets/logo_s.png";
import * as S from "./styles";
import { Col, Container, Row } from "reactstrap";
import { Text } from "../../routes/main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {

  function openWhatsapp(){
    window.open("https://wa.me/5547997550041", "_blank")
  }


  return (
    <Container>
      <Row>
        <Col>
          <S.FooterContainer>
            <S.Column>
              <S.Logo src={LOGO} />
              <Text style={{ fontWeight: "bold" }}>
                Lince Saúde Multisolutions
              </Text>
            </S.Column>
            <S.Column style={{flex: 1}}>
              <Text style={{ fontWeight: "bold" }}>Lince Multisolutions Ltda <br/>
                05.948.834/0001-47</Text>
              <Text>Rua Lauro Muller, 950<br/>Itajaí / SC - 88301-401</Text>
              <Text><a href="mailto:contato@lincesaude.com.br">contato@lincesaude.com.br</a></Text>
              <Text onClick={openWhatsapp} style={{cursor: 'pointer'}}>(47) 99755-0041</Text>
            </S.Column>
            <S.Column>
              <S.Links>
                <S.Link href="https://lincesaude.com.br/termos-de-uso/" target="_blank"  rel="noreferrer">Termos de Uso</S.Link>
                <S.Link href="https://lincesaude.com.br/declaracao-de-privacidade/" target="_blank"  rel="noreferrer">Política de Privacidade</S.Link>
                <S.Link href="https://lince.freshdesk.com" target="_blank">Suporte</S.Link>
              </S.Links>
            </S.Column>
          </S.FooterContainer>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
