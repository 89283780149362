import React from "react";
import * as S from "../../main-styles";
import { ROLES } from "../../../utils/constants";
import { formatCPF, formataTelefone } from "../../../utils/functions";
import moment from "moment-timezone";
import RenderRole from "../../../components/RenderRole";
import RenderStatus from "../../../components/RenderStatus";
import UserTooltips from "../../../components/UserToolTips";

function UserData({ data }) {
  console.log(data);
  let vidas = data?.is_life ? data?.total_lives + 1 : data?.total_lives;
  let isPJ = data?.role === ROLES.pj || data?.role === ROLES.convenio;
  return (
    <S.InfoContainer>
      <S.GappedContainer>
        <div>
          <S.Label>Nome</S.Label>
          <S.Text>{data?.name}</S.Text>
        </div>
        <div style={{ gap: 5, display: "flex" }}>
          <RenderRole role={data?.role} />
          <RenderStatus status={data?.status} />
        </div>

        <div>
          <S.Label>CPF</S.Label>
          <S.Text>{formatCPF(data?.cpf)}</S.Text>
        </div>
      </S.GappedContainer>
      <S.GappedContainer>
        <div>
          <S.Label>Email</S.Label>
          <S.Text>{data?.email}</S.Text>
        </div>

        <div>
          <S.Label>Telefone</S.Label>
          <S.Text>{formataTelefone(data?.phone)}</S.Text>
        </div>
        {!isPJ && (
          <div>
            <S.Label>Vidas</S.Label>
            <S.Text>{vidas}</S.Text>
          </div>
        )}
      </S.GappedContainer>
      <S.GappedContainer>
        <div>
          <S.Label>Cliente desde</S.Label>
          <S.Text>{moment(data?.creation_date).format("DD/MM/YYYY")}</S.Text>
        </div>
        <div>
          <S.Label>Último acesso</S.Label>
          <S.Text>
            {data?.last_seen
              ? moment(data?.last_seen).format("DD/MM/YYYY HH:mm")
              : "Nunca acessou"}
          </S.Text>
        </div>
        <div>
          <S.Label>Funções</S.Label>
          <UserTooltips user={data} />
        </div>
      </S.GappedContainer>
    </S.InfoContainer>
  );
}

export default UserData;
