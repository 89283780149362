import "bootstrap/dist/css/bootstrap.min.css";

import { Col, Container, Row } from "reactstrap";
import "./App.css";
import Header from "./components/Header";
import Rotas from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Tooltip } from 'react-tooltip'
import Footer from "./components/Footer";


function App() {
  return (
    <Router>
      <Container fluid={true}>
        <Header />
        <Row>
          <Col>
            <Rotas />
            <Footer />
          </Col>
        </Row>
      </Container>
      <Tooltip id="tooltip" />
    </Router>
  );
}

export default App;
