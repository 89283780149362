import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth/auth_slice";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const combined_reducers = combineReducers({
  auth: authSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, combined_reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
