import React, { useEffect } from "react";

import * as S from "./styles";
import { Col, Container, Row } from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import USERS from "../../api/users";
import CONTRACTS from "../../api/contracts";
import { useSelector } from "react-redux";
import { Button, FHSB, Title, WhiteBox } from "../main-styles";
import StatusContrato from "../../components/StatusContrato";
import RenderContractType from "../../components/RenderContractType";
import { ROLES } from "../../utils/constants";
import { addLeadingZeros, toB64 } from "../../utils/functions";
import moment from "moment-timezone";

function Contratos() {
  const  navigate  = useNavigate();
  const { id, role } = useSelector((state) => state.auth.userData);
  const [state, setState] = React.useState({
    contratos: [],
    mine: {},
    loading: true,
  });
  const { contratos, mine, loading } = state;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    console.log("id", id);
    let mine = {};
    let contratos = await CONTRACTS.get.related(id);
    contratos = contratos.filter((a) => {
      if (a.id_cliente === id) {
        mine = a;
        return false;
      }
      return true;
    });
    setState((s) => ({ ...s, contratos, mine, loading: false }));
  }

  function gotoContract(id){
    // convert id to base64
    // navigate to /contratos/{id}
    id = toB64(id);
    navigate(`/contratos/detalhes/${id}`);
  }

  return (
    <Container>
      <Row>
        <Col>
          <WhiteBox style={{ marginTop: 15 }}>
            <FHSB style={{ width: "100%" }}>
              <Title>Contratos</Title>
              {role === ROLES.convenio && (
                <Link to="/contratos/novo">
                  <Button>Novo Contrato</Button>
                </Link>
              )}
            </FHSB>
            <table
              className="table table-hover"
              style={{ borderRadius: 5, overflow: "hidden", marginTop: 5 }}
            >
              <thead>
                <tr>
                  <td style={{ textAlign: "center" }}>ID</td>
                  <td>Cliente</td>
                  <td style={{ textAlign: "center" }}>Tipo</td>
                  <td style={{ textAlign: "center" }}>Status</td>
                  <td style={{ textAlign: "center" }}>Vidas</td>
                </tr>
              </thead>
              <tbody>
                {contratos.map((contrato) => {
                  return <Linha contrato={contrato} gotoContract={gotoContract} />;
                })}
              </tbody>
            </table>
          </WhiteBox>
        </Col>
      </Row>
    </Container>
  );
}

function Linha({ contrato, gotoContract }) {
  console.log("CONTRATO", contrato);
  let isPJ = contrato?.tipo_contrato !== 'titular';
  let nome = isPJ ? contrato.empresa_name : contrato.name;
  let tipo = contrato.tipo_contrato;
  let initial = isPJ ? (contrato.empresa_type === ROLES.pj ? "J" : "C") : "F";
  initial = initial.substring(0, 1).toUpperCase();
  let id = addLeadingZeros(contrato.id, 4);
  let m = moment(contrato.created_at).format("YYMM");
  let vidas = contrato.status === 'provisorio'?  contrato.vidas_json.length : contrato.vidas;

  return (
    <tr style={{ cursor: "pointer" }} onClick={() => gotoContract(contrato.id)}>
      <td style={{ textAlign: "center" }}>
        {initial}-{m}
        {id}
      </td>
      <td>{nome}</td>
      <td>
        <RenderContractType role={tipo} />
      </td>
      <td>
        <StatusContrato status={contrato.status} />
      </td>
      <td style={{ textAlign: "center", color: contrato.status === 'provisorio' ? '#fddcb4' : '#1d1d1d' }}
      data-tooltip-id="tooltip"
      data-tooltip-content={contrato.status === 'provisorio' ? 'Contrato Provisório, as vidas estão inativas' : ''}
      
      >{vidas}</td>
    </tr>
  );
}

export default Contratos;
