import React from "react";

import { USER_STATS } from "../../utils/constants";
import styled from "styled-components";

function RenderUserStats({ stats }) {
  let text = "";
  let color = "";
  let textColor = "#000000";

  switch (stats) {
    case USER_STATS.active:
      text = "Ativo";
      color = "#00a900";
      textColor = "#ffffff";
      break;
    case USER_STATS.inactive:
      text = "Inativo";
      color = "#c2c2c2";
      textColor = "#ffffff";
      break;
    case USER_STATS.pending:
      text = "Pendente";
      color = "#ffcc00";
      textColor = "#ffffff";
      break;
    case USER_STATS.blocked:
      text = "Bloqueado";
      color = "#cb6666";
      textColor = "#ffffff";
      break;
    case USER_STATS.deleted:
      text = "Excluído";
      color = "#000000";
      textColor = "#ffffff";
      break;
    case USER_STATS.banned:
      text = "Banido";
      color = "#2b0012";
      textColor = "#ffffff";
      break;
      case USER_STATS.preactive:
        text = "Aguardando";
        color = "#80b3ff";
        textColor = "#ffffff";
        break;
    default:
        text = stats
        color = "#ff006a";
        textColor = "#ffffff";
      break;
  }
  return <Badge color={color} textColor={textColor}>{text}</Badge>;
}

const Badge = styled.div`
    background-color: ${(props) => props.color};
    color: ${(props) => props.textColor};
    padding: 1px 5px;
    border-radius: 5px;
    text-align: center;
    font-size: 9px;
    
`;

export default RenderUserStats;
