import React from "react";
import { ROLES } from "../../utils/constants";

import * as S from "./styles";

function RenderRole({ role }) {
  switch (role) {
    case ROLES.superadmin:
      return <S.Superadmin>Superadmin</S.Superadmin>;
    case ROLES.admin:
      return <S.Admin>Admin</S.Admin>;
    case ROLES.convenio:
      return <S.Convenio>Convênio</S.Convenio>;
    case ROLES.pj:
      return <S.PJ>Empresa</S.PJ>;
    case ROLES.titular:
      return <S.Titular>Titular</S.Titular>;
    case ROLES.dependente:
      return <S.Titular>Dependente</S.Titular>;
    default:
      return <S.Container>{role} --</S.Container>;
  }
}

export default RenderRole;
