import React from "react";

import * as S from "./styles";
import RoleIcon from "../RoleIcon";
import RenderRole from "../RenderRole";
import RenderStatus from "../RenderStatus";

function UserBox({ user, title, small = false, onClick = () => {} }) {
  const { name, role, admin, status, is_life, lives, company } = user;
  let u_name = company ? company.razao_social : name;

  if (small) {
    return (
      <div>
        {title && <S.Title>{title}</S.Title>}
        <S.Container onClick={onClick}>
          <RoleIcon role={role} small={true} />
          {u_name}
        </S.Container>
      </div>
    );
  }
  return (
    <div>
      {title && <S.Title>{title}</S.Title>}
      <S.User onClick={onClick}>
        <RoleIcon role={role} />
        <S.UserInfo>
          <S.UserName>{u_name}</S.UserName>
          <S.UserText>Vidas: {lives}</S.UserText>
          <RenderStatus status={status} />
        </S.UserInfo>
      </S.User>
    </div>
  );
}

export default UserBox;
