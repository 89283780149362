import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { Col, Container, Row } from "reactstrap";
import {
  Button,
  COLORS,
  Input,
  Text,
  Title,
  WhiteBox,
} from "../../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { useParams } from "react-router-dom";
import AUTH from "../../../api/auth";
import { toast } from "react-toastify";

function ResetPassword() {
  const { passwordlink } = useParams();
  const [state, setState] = useState({
    status: "loading",
    password: "",
    passwordConfirm: "",
    error: "",
  });
  const { password, passwordConfirm, status, error } = state;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    if (!passwordlink)
      return setState((s) => ({
        ...s,
        status: "error",
        error: "Link inválido",
      }));
    try {
      let valid = await AUTH.get.checkRecoveryCode(passwordlink);
      console.log("valid", valid);
      setState((s) => ({
        ...s,
        status: "idle",
      }));
    } catch (error) {
      console.error(error);
      setState((s) => ({
        ...s,
        status: "error",
        error: "Link inválido",
      }));
    }
  }

  async function resetPassword() {
    if (password !== passwordConfirm) {
      return toast.error("As senhas não coincidem");
    }
    if (password.length < 6) {
      return toast.error("A senha deve ter no mínimo 6 caracteres");
    }
    try {
      let response = await AUTH.set.newPassword(passwordlink, password);
      console.log(response);
      setState((s) => ({ ...s, status: "success" }));
    } catch (error) {
      console.error(error);
    }
  }

  if (status === "loading")
    return (
      <Container>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <WhiteBox style={{marginTop: 20}}>Carregando informações</WhiteBox>
          </Col>
          <Col md={4} />
        </Row>
      </Container>
    );

  if (status === "success") {
    return (
      <Container>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <WhiteBox style={{ padding: 30, textAlign: "center" }}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={COLORS.main}
                size="3x"
              />
              <Title style={{ color: COLORS.main, marginTop: 10 }}>
                Senha definida com sucesso!
              </Title>
              <Text>
                Agora você já pode acessar o sistema com sua nova senha.
              </Text>
            </WhiteBox>
          </Col>
          <Col md={4} />
        </Row>
      </Container>
    );
  }
  if (status === "error") {
    return (
      <Container>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <WhiteBox style={{ padding: 30, textAlign: "center" }}>
              <FontAwesomeIcon
                icon={faTimesCircle}
                color={COLORS.red}
                size="3x"
              />
              <Title style={{ color: COLORS.red, marginTop: 10 }}>
                Link inválido
              </Title>
              <Text>Este link é inválido ou já foi utilizado.</Text>
            </WhiteBox>
          </Col>
          <Col md={4} />
        </Row>
      </Container>
    );
  }
  return (
    <Container style={{ width: "100%" }}>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
      <WhiteBox style={{marginTop: 20}}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 30,
                paddingBottom: 0,
                gap: 10,
                width: "100%",
              }}
            >
              <Title style={{ color: COLORS.main }}>Redefinir senha</Title>
              <Input
                type="password"
                placeholder="Nova senha"
                value={password}
                onChange={(e) =>
                  setState((s) => ({ ...s, password: e.target.value }))
                }
              />
              <Input
                type="password"
                placeholder="Confirme a nova senha"
                value={passwordConfirm}
                onChange={(e) =>
                  setState((s) => ({ ...s, passwordConfirm: e.target.value }))
                }
              />
              <Button disabled={status !== "idle"} onClick={resetPassword}>
                Redefinir senha
              </Button>
            </div>
      </WhiteBox>
          </Col>
          <Col md={4}></Col>
        </Row>
    </Container>
  );
}

export default ResetPassword;
