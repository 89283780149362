import styled from "styled-components";

export const Container = styled.div``;
export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #f2f2f2;
  margin-top: 20px;
  border-radius: 20px;
  padding: 50px;
  gap: 50px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 20px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Logo = styled.img`
  width: 80px;
  height: auto;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  color: #8c8c8c;
  text-decoration: none;
  cursor: pointer;
`;

export const Address = styled.p`
  font-size: 14px;
  margin-top: 10px;
`;

export const Contact = styled.p`
  font-size: 14px;
  margin-top: 5px;
`;
