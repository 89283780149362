import React, { useEffect, useMemo, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Modal, Row } from "reactstrap";
import {
  Button,
  COLORS,
  FH,
  FormContainer,
  Input,
  Label,
  Text,
  Title,
  WhiteBox,
} from "../../main-styles";
import * as S from "./styles";

import {
  faArrowTurnDownRight,
  faClock,
  faCloudArrowDown,
  faCopy,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import COMPANIES from "../../../api/companies";
import CONTRACTS from "../../../api/contracts";
import PRICING from "../../../api/pricing";
import USERS from "../../../api/users";
import RenderContractType from "../../../components/RenderContractType";
import Spinner from "../../../components/Spinner";
import StatusContrato from "../../../components/StatusContrato";
import { REQ_STATUS, ROLES } from "../../../utils/constants";
import {
  formatCNPJ,
  formatCPF,
  fromB64,
  getContractId,
} from "../../../utils/functions";
import { ActionButton } from "../../Admin/Contratos/style";
import Vida from "./Vida";

function Detalhes() {
  const navigate = useNavigate();
  const { uuid, mine, edit } = useParams();
  const { role } = useSelector((state) => state.auth.userData);
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const topRef = useRef(null);
  const companyDataRef = useRef(null);
  const tableRef = useRef(null);

  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    centro_de_custos: [],
    edit_vida_selected: null,
    new_vida_visible: false,
    tipos_de_contrato: [],
    contrato: {
      tipo: "",
      id: uuid,
      role: "",
      numero_proposta: "xxxx-xxxx-xxxx-xxxx",
      numero_contrato: "xxxx-xxxx-xxxx-xxxx",
      data_criacao: moment().format("YYYY-MM-DD"),
      data_assinatura: "",
      data_cancelamento: "",
      convenio: {
        id: "",
        nome: "",
      },
      centro_de_custo: null,
      vidas: [],
      status: "provisorio", // provisorio, ativo, cancelado e arquivado
      valor: "",
      stripe_subscription_id: "",
      stripe_client_id: "",
      validado: false,
      validando: false,
    },
    company: {
      cnpj: "",
      razao_social: "-",
      nome_fantasia: "-",
      responsavel: "",
      cpf_responsavel: "",
      email: "",
      telefone: "",
      validado: false,
      validando: false,
    },
    precificacao: {},
    carregando_precificacao: true,
  });
  const {
    contrato,
    contrato: {
      numero_proposta,
      numero_contrato,
      data_criacao,
      data_assinatura,
      data_cancelamento,
      convenio,
      centro_de_custo,
      vidas,
      status,
      valor,
      stripe_subscription_id,
      stripe_client_id,
      tipo,
      responsavel,
      cpf_responsavel,
      email,
      telefone,
    },
    status: pageStatus,
    centro_de_custos,
    edit_vida_selected,
    new_vida_visible,
    tipos_de_contrato,
    company,
    taxa_adesao,
    precificacao,
    carregando_precificacao,
  } = state;

  useEffect(() => {
    init();
  }, []);

  async function loadContract() {
    let id = fromB64(uuid);
    console.log("CARREGANDO CONTRATO", id);
    // carrega o contrato
    let contract = await CONTRACTS.get.byId(id);

    let contractId = getContractId(contract);
    let convenio = {
      id: "",
      nome: "",
    };
    if (contract?.id_convenio) {
      // pega o convenio
      convenio = await COMPANIES.get.byRef(contract.id_convenio);
    }
    console.log("CONTRATO", contract);

    let vidas = contract?.vidas_json;
    vidas = ordenaVidas(vidas);

    setState((s) => ({
      ...S,
      centro_de_custos: contract.centro_de_custo,
      tipos_de_contrato: [],
      contrato: {
        tipo: contract.tipo_contrato,
        id,
        role: contract.tipo_contrato,
        numero_proposta: contract.stripe_quote_id,
        numero_contrato: contractId,
        data_criacao: contract?.data_criacao,
        data_assinatura: contract?.data_assinatura,
        data_cancelamento: contract?.data_cancelamento,
        convenio,
        centro_de_custo: contract?.centro_de_custo,
        vidas,
        status: contract.status, // provisorio, ativo, cancelado e arquivado
        valor: "",
        stripe_subscription_id: "",
        stripe_client_id: "",
        validado: false,
        validando: false,
        activation_code: contract?.activation_code,
      },
      company: contract?.company || {},
      precificacao: {},
    }));

    // carrega a empresa
    // carrega as vidas
    // carrega os pagamentos
    // carrega os documentos
  }

  async function getTaxaAdesao() {
    try {
      let taxa = await USERS.get.pricing();
      console.log("taxa", taxa);
      setState((s) => ({ ...s, taxa_adesao: taxa }));
    } catch (error) {
      console.error(error);
    }
  }

  function updateCompany(field, value) {
    if (field === "cnpj") {
      clearCompany();
    }
    setState((s) => {
      return {
        ...s,
        company: {
          ...s.company,
          [field]: value,
        },
      };
    });
  }

  async function pegaPrecificacao(tipo) {
    setState((s) => {
      return {
        ...s,
        carregando_precificacao: true,
        precificacao: null,
      };
    });
    // se tive um subscription id, deve buscar a precificação aplicada
    if (stripe_subscription_id) {
      // TODO: PEGA PRACIFICACAO APLICADA
      // let precificacao = await USERS.get.precificacao(stripe_subscription_id);
      // console.log("PRECIFICACAO", precificacao);
      return {
        valor: 0,
      };
    }
    // se não tiver subscription id, deve buscar a precificação do tipo de contrato: pf, pj ou convenio
    let precificacao;
    switch (tipo) {
      case ROLES.titular:
        precificacao = await PRICING.get.default_pf();
        console.log("PRECIFICACAO PF", precificacao);
        precificacao = precificacao.prices[0];
        break;
      case ROLES.pj:
        precificacao = await PRICING.get.default_pj();
        precificacao = precificacao.prices.find(
          (a) => a.lookup_key === "pj_avulso"
        );
        console.log("PRECIFICACAO PJ", precificacao);
        break;
      case ROLES.convenio:
        precificacao = await PRICING.get.default_pj();
        precificacao = precificacao.prices.find(
          (a) => a.lookup_key === "pj_convenio"
        );
        console.log("PRECIFICACAO convenio", precificacao);
        break;
      default:
        console.log("TIPO DE CONTRATO NÃO ENCONTRADO", tipo);
        break;
    }
    setState((s) => {
      return {
        ...s,
        precificacao,
        carregando_precificacao: false,
      };
    });
  }

  function clearCompany() {
    setState((s) => {
      return {
        ...s,
        company: {
          razao_social: "-",
          nome_fantasia: "-",
          validado: false,
          validando: false,
        },
      };
    });
  }

  function closeEditVida() {
    setState((s) => {
      // remove as vidas que não foram validadas
      let vidas = s.contrato.vidas.filter((a) => a.validated !== false);
      return {
        ...s,
        edit_vida_selected: null,
        contrato: {
          ...s.contrato,
          vidas,
        },
      };
    });
  }

  function selectVida(vida) {
    setState((s) => {
      return {
        ...s,
        edit_vida_selected: vida,
      };
    });
  }

  function saveVida(id, vida) {
    setState((s) => {
      let vidas = s.contrato.vidas.map((v) => {
        if (v.id === id) {
          return vida;
        }
        return v;
      });

      vidas = ordenaVidas(vidas);
      return {
        ...s,
        edit_vida_selected: null,
        contrato: {
          ...s.contrato,
          vidas,
        },
      };
    });
  }

  function newVida(titular = false) {
    console.log("ADICONANDO VIDA COM TITULAR", titular);
    if (titular !== false) {
      titular = vidas.find((v) => v.id === titular);
      titular = {
        id: titular.id,
        nome: titular.nome,
      };
    }
    let vida = {
      id: `#id-${vidas.length + 1}`,
      nome: "",
      cpf: "",
      email: "",
      nascimento: "",
      titular,
      validated: false,
      billing: false,
    };
    console.log("NOVA VIDA", vida);
    setState((s) => {
      let contrato = s.contrato;

      let vidas = [...contrato.vidas, vida];
      vidas = ordenaVidas(vidas);

      contrato = {
        ...contrato,
        vidas,
      };

      return {
        ...s,
        contrato,
        edit_vida_selected: vida,
      };
    });
  }

  async function init() {
    await loadContract();
    let promises = [];
    promises.push(getTaxaAdesao());
    promises.push(loadCentroDeCustos());
    promises.push(pegaPrecificacao(contrato.tipo));
    await Promise.all(promises);
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
    }));
    return;
  }

  async function loadCentroDeCustos() {
    try {
      let centro_de_custos = await USERS.get.centro_de_custos();
      let auto = {
        value: 0,
        label: "> Este contrato",
      };
      centro_de_custos = centro_de_custos.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      });
      setState((s) => ({
        ...s,
        centro_de_custos: [auto, ...centro_de_custos],
        contrato: {
          ...s.contrato,
          centro_de_custo: auto,
        },
      }));
      return true;
    } catch (e) {
      console.error(e);
    }
  }

  function ordenaVidas(vidas) {
    console.log("VIDAS", vidas);
    let titulares = vidas
      .filter((v) => !v.titular)
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

    vidas = titulares
      .map((titular) => {
        let dependentesDoTitular = vidas
          .filter((d) => {
            console.log("d", d);
            let r = parseFloat(d?.titular) === parseFloat(titular?.id);
            return r;
          })
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        return [titular, ...dependentesDoTitular];
      })
      .flat();
    return vidas;
  }

  function updateContrato(field, value) {
    console.log(field, value);
    if (field === "tipo") {
      pegaPrecificacao(value.value);
    }
    setState((s) => {
      return {
        ...s,
        contrato: {
          ...s.contrato,
          [field]: value,
        },
      };
    });
  }

  function copyLink() {
    let link = `https://sistema.lincesaude.com.br/contratos/review/${contrato.activation_code}`;
    // copy to clipboard
    window.navigator.clipboard.writeText(link);
    toast.success("Link copiado para a área de transferência");
  }

  async function expire() {
    try {
      // confirma
      let confirm = window.confirm("Deseja realmente expirar essa cotação?");
      if (!confirm) return;
      await CONTRACTS.set.expire(contrato?.activation_code);
      toast.success("Cotação expirada com sucesso");
      navigate("/contratos");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao expirar cotação");
    }
  }

  async function downloadPDF() {
    if (downloadingPdf) return;
    setDownloadingPdf(true);
    try {
      let contractid = getContractId(contrato);
      await CONTRACTS.get.quotePdf(numero_proposta, contractid);

      setDownloadingPdf(false);
    } catch (error) {
      console.error(error);
      setDownloadingPdf(false);
    }
    //
  }

  async function forceActivate(vida) {
    console.log("forçando ativação", vida);
    // deve ativar a vida na hubcare
    // se o usuario nunca foi visto, deve disparar o email de boas vindas de dependente.
    let r=  await USERS.set.force_activate(vida.id);
    console.log("force activate", r);
    if(r.id){
      toast.success("Vida ativada com sucesso");
      // update vida
      setState((s) => {
        let vidas = s.contrato.vidas.map((v) => {
          if (v.id === vida.id) {
            return {
              ...v,
              hub_id: r.id,
            };
          }
          return v;
        });
        return {
          ...s,
          contrato: {
            ...s.contrato,
            vidas,
          },
        };
      });
    }

  }

  function pegaPrecificacaoUsuario(numero) {
    console.log("pegando precificacao usuario", numero);
    numero = numero + 1;
    if (!precificacao?.tiers) return null;
    let precificacoes = precificacao?.tiers;
    console.log("PRECIFICACAO USADA", precificacao);
    for (let i = 0; i < precificacoes?.length; i++) {
      if (precificacoes[i].up_to === null || numero <= precificacoes[i].up_to) {
        return precificacoes[i].unit_amount;
      }
    }

    // Se não encontrar uma precificação adequada, retorna null ou um valor padrão
    return null;
  }

  const totalPrice = useMemo(() => {
    if (vidas.length === 0) return 0;
    if (!precificacao?.tiers) return 0;
    let titulares = vidas.filter((v) => !v.titular);
    let precotitular = pegaPrecificacaoUsuario(0);
    let preco = titulares.reduce((prev, curr) => {
      prev += precotitular;
      let dependentes = vidas.filter((v) => v.titular.id === curr.id);
      dependentes.map((d, i) => {
        prev += pegaPrecificacaoUsuario(i + 1);
        return d;
      });
      return prev;
    }, 0);
    return preco / 100;
  }, [vidas, precificacao, pegaPrecificacaoUsuario]);

  const total = useMemo(() => {
    if (vidas.length === 0) return 0;
    let titulares = vidas.filter((v) => !v.titular)?.length;
    let total = totalPrice;
    total += taxa_adesao * titulares;
    return total;
  }, [totalPrice, taxa_adesao]);

  if (pageStatus === REQ_STATUS.LOADING) {
    return (
      <Container>
        <Row>
          <Col>
            <Spinner /> carregando...
          </Col>
        </Row>
      </Container>
    );
  }

  let id = `#CASDFASDFASDFFSAF`;

  return (
    <Container style={{ marginTop: 15 }}>
      <Row>
        <Col>
          <WhiteBox ref={topRef}>
            <Title>Contrato</Title>

            <S.FormFields>
              <Field
                id="numero_proposta"
                name="Número da Proposta"
                value={numero_proposta || "-"}
                onChange={(e) =>
                  updateContrato("numero_proposta", e.target.value)
                }
                type={"info"}
              />
              <Field
                id="numero_contrato"
                name="Número do Contrato"
                value={numero_contrato}
                onChange={(e) =>
                  updateContrato("numero_contrato", e.target.value)
                }
                type={"info"}
              />
              <Field
                id="data_criacao"
                name="Data de Criação"
                value={moment(data_criacao).format("DD/MM/YYYY")}
                onChange={(e) => updateContrato("data_criacao", e.target.value)}
                type="info"
              />
              {data_assinatura && (
                <Field
                  id="data_assinatura"
                  name="Data de Assinatura"
                  value={moment(data_assinatura).format("DD/MM/YYYY")}
                  onChange={(e) =>
                    updateContrato("data_assinatura", e.target.value)
                  }
                  type="info"
                />
              )}
              {status === "cancelado" && (
                <Field
                  id="data_cancelamento"
                  name="Data de Cancelamento"
                  value={data_cancelamento}
                  onChange={(e) =>
                    updateContrato("data_cancelamento", e.target.value)
                  }
                  type="info"
                />
              )}
              <FormContainer>
                <Label>Status</Label>
                <StatusContrato status={status} />
              </FormContainer>
              <FormContainer>
                <Label>Tipo de Contrato</Label>
                <RenderContractType role={contrato.tipo} />
              </FormContainer>
              {status === "provisorio" && (
                <>
                  <FormContainer>
                    <Label>PDF da Cotação</Label>
                    <ActionButton
                      style={{ backgroundColor: "#3e8be4" }}
                      onClick={downloadPDF}
                    >
                      <FontAwesomeIcon icon={faCloudArrowDown} />
                      {downloadingPdf ? "Aguarde...." : "Baixar"}
                    </ActionButton>
                  </FormContainer>
                  <FormContainer>
                    <Label>Link para aprovação</Label>
                    <ActionButton
                      style={{
                        backgroundColor: "#ffffff",
                        color: "#aaa",
                        border: `1px solid #aaa`,
                      }}
                      onClick={copyLink}
                    >
                      https://sistema.lincesaude.com.br/cont...
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{ marginLeft: 5 }}
                      />
                    </ActionButton>
                  </FormContainer>
                  <FormContainer>
                    <Label>Ações</Label>
                    <FH>
                      <ActionButton
                        style={{
                          backgroundColor: "#ddaaaa",
                          width: 25,
                        }}
                        onClick={expire}
                        data-tooltip-id="tooltip"
                        data-tooltip-content={"Expirar cotação"}
                      >
                        <FontAwesomeIcon icon={faClock} />
                      </ActionButton>
                    </FH>
                  </FormContainer>
                </>
              )}

              <>
                {stripe_client_id && (
                  <Field
                    id="stripe_subscription_id"
                    name="Stripe Subscription ID"
                    value={stripe_subscription_id}
                    onChange={(e) =>
                      updateContrato("stripe_subscription_id", e.target.value)
                    }
                    type="info"
                  />
                )}
                {stripe_client_id && (
                  <Field
                    id="stripe_client_id"
                    name="Stripe Client ID"
                    value={stripe_client_id}
                    onChange={(e) =>
                      updateContrato("stripe_client_id", e.target.value)
                    }
                    type="info"
                  />
                )}
              </>
            </S.FormFields>
          </WhiteBox>
          {company?.type && (
            <ExtraData
              tipo={contrato?.tipo_contrato}
              updateCompany={updateCompany}
              company={company}
            />
          )}
          <WhiteBox ref={tableRef}>
            <Title>Vidas</Title>

            <table
              className="table table-striped"
              style={{ borderRadius: 4, overflow: "hidden" }}
            >
              <thead>
                <tr>
                  <th>Nome</th>
                  <th></th>
                  <th>CPF</th>
                  <th>Email</th>
                  <th style={{ width: 100 }}>Nascimento</th>
                </tr>
              </thead>
              <tbody>
                {vidas.length === 0 && (
                  <tr>
                    <td colSpan={7}>Nenhuma vida cadastrada</td>
                  </tr>
                )}
                {vidas.map((vida, index) => {
                  let show_force_activate = vida?.is_life && !vida?.hub_id && status === "ativo";
                  return (
                    <tr key={index}>
                      {show_force_activate && (
                        <Button onClick={() => forceActivate(vida)}>
                          Forçar Ativação
                        </Button>
                      )}
                      {vida?.titular && (
                        <td>
                          <FontAwesomeIcon
                            icon={faArrowTurnDownRight}
                            style={{ marginLeft: 8 }}
                          />
                        </td>
                      )}

                      <td
                        colSpan={!vida?.titular ? 2 : 1}
                        style={{ textTransform: "capitalize" }}
                      >
                        {vida.name || vida.nome}
                      </td>
                      <td
                        style={vida.cpfError ? { color: COLORS.dark_red } : {}}
                      >
                        <FH>{formatCPF(vida.cpf)}</FH>
                      </td>
                      <td
                        style={
                          vida.emailError
                            ? {
                                color: COLORS.dark_red,
                                textTransform: "lowercase",
                              }
                            : { textTransform: "lowercase" }
                        }
                      >
                        <FH>{vida.email}</FH>
                      </td>
                      <td>{moment(vida.date_birth).format("DD/MM/YYYY")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </WhiteBox>
          {/* <WhiteBox>
            <Title>Valores</Title>
            <Container>
              <Row>
                <Col md={4}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: 5, fontWeight: "bold" }}>
                          Tabela de Preços
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        {carregando_precificacao && (
                          <tr>
                            <td colSpan={2}>
                              <Spinner /> Atualizando tabela de preços
                            </td>
                          </tr>
                        )}
                      </tr>
                      <RenderTiers tiers={precificacao?.tiers} />
                    </tbody>
                  </table>
                </Col>
                <Col md={7}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: 5, fontWeight: "bold" }}>
                          Mensalidades
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      {vidas.length === 0 && (
                        <tr>
                          <td colSpan={3} style={{ padding: 5 }}>
                            <Label>Nenhuma vida adicionada</Label>
                          </td>
                        </tr>
                      )}
                      <RenderVidas
                        vidas={vidas}
                        pegaPrecificacaoUsuario={pegaPrecificacaoUsuario}
                      />
                      <tr
                        style={{
                          borderBottom: "1px solid #8a8a8a",
                          fontWeight: "bold",
                        }}
                      >
                        <td style={{ padding: 5 }}>SubTotal</td>
                        <td>
                          <Label>Mensal</Label>
                        </td>
                        <td>
                          {parseFloat(totalPrice)?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                      {vidas.length > 0 && (
                        <>
                          <tr style={{ borderBottom: "1px solid #8a8a8a" }}>
                            {vidas.filter((v) => !v.titular).length > 1 ? (
                              <td style={{ padding: 5 }}>
                                Taxa de Adesão (
                                {parseFloat(taxa_adesao)?.toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}{" "}
                                {` x`}
                                {vidas.filter((v) => !v.titular).length})
                              </td>
                            ) : (
                              <td style={{ padding: 5 }}>Taxa de Adesão</td>
                            )}

                            <td>
                              <Label style={{ fontWeight: "bold" }}>
                                TAXA ÚNICA
                              </Label>
                            </td>
                            <td>
                              {parseFloat(
                                vidas.filter((v) => !v.titular).length *
                                  parseFloat(taxa_adesao)
                              )?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                          </tr>
                        </>
                      )}
                      <tr style={{ fontWeight: "bold" }}>
                        <td style={{ padding: 5 }}>Total</td>
                        <td></td>
                        <td>
                          {parseFloat(total)?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md={1}></Col>
              </Row>
            </Container>
          </WhiteBox> */}
        </Col>
      </Row>

      {edit_vida_selected && (
        <Modal isOpen={edit_vida_selected} toggle={closeEditVida}>
          <Vida
            data={edit_vida_selected}
            titulares={vidas.filter((a) => !a.titular)}
            onChange={saveVida}
          />
        </Modal>
      )}
    </Container>
  );
}

function ExtraData({ company }) {
  console.log("company", company);

  switch (company?.type) {
    case ROLES.pj:
    case ROLES.convenio:
      return (
        <WhiteBox id="company-data">
          <Container>
            <Row>
              <Col md={12}>
                <Title>Informações da Empresa</Title>
                <Field
                  id="CNPJ"
                  name="CNPJ"
                  value={formatCNPJ(company.document)}
                  onChange={(e) => {}}
                  type="info"
                  style={{ marginTop: 5 }}
                />
                <Field
                  id="razao_social"
                  name="Razão Social"
                  value={company.name}
                  type="info"
                  style={{ marginTop: 5 }}
                />
              </Col>
            </Row>
          </Container>
        </WhiteBox>
      );

      break;

    default:
      return null;
      break;
  }
}

function Field({ style = {}, id, name, value, onChange, type = "text" }) {
  return (
    <FormContainer style={style}>
      <Label>{name}</Label>
      {type === "info" ? (
        <Text>{value}</Text>
      ) : (
        <Input id={id} value={value} onChange={onChange} type={type} />
      )}
    </FormContainer>
  );
}

export default Detalhes;
