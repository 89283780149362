import api  from "../axios";

const  CONTRACTS = {
    get: {
        search: async(search)=>{
            let response = await api.get(`/contracts/search?search=${search}`);
            return response.data;
        },
        byId: async(id)=>{
            let response = await api.get(`/contracts/one/${id}`);
            return response.data;
        },
        byUUID: async(uuid)=>{
            let response = await api.get(`/contracts/review/${uuid}`);
            return response.data;
        },
        all: async(status,page,perPage)=>{
            let response = await api.get(`/contracts/all?status=${status}&page=${page}&perPage=${perPage}`);
            return response.data;
        },
        related: async(user_id)=>{
            let response = await api.get(`/contracts/related/${user_id}`);
            return response.data

        },
        quotePdf: async(id, contractid)=>{
          

            try {
                const response =   await api.get(`/contracts/quote/${id}`, {
                    responseType: 'blob' // Isso é importante para garantir que a resposta seja tratada como um blob
                  });
        
                const blob = response.data;
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `Q-${contractid}.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl);
              } catch (error) {
                console.error('Erro:', error);
              }

        },
        checkoutLink: async(uuid)=>{
            let response = await api.get(`/contracts/activate/${uuid}`);
            return response.data;
        }
    },
    set: {
        new: async(data)=>{
            let response = await api.post(`/users`, data);
            return response.data;
        },
        expire: async(id)=>{
            let response = await api.put(`/contracts/quote/expire/${id}`);
            return response.data;
        }

    }
}

export default CONTRACTS;