import React from "react";
import * as S from "./styles";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDisplayMedical, faHeartPulse } from "@fortawesome/pro-light-svg-icons";

function RenderLife({ user }) {
  const { is_life, hub_id } = user;
  if(!is_life) return null;
  return (
    <S.Container>
      <S.MedicalContainer>
        <S.MedicalICon>
          <FontAwesomeIcon icon={faHeartPulse} color={"#fff"} size={"2x"} />
        </S.MedicalICon>
        {hub_id ? "Vida ativa" : "Vida inativa"}
      </S.MedicalContainer>
    </S.Container>
  );
}

export default RenderLife;
