import React, { useEffect, useState } from "react";

import * as S from "./styles";
import PRICING from "../../../api/pricing";
import { Button, Description, FH, Title, WhiteBox } from "../../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faLink, faSave } from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";

const PRICE_TAGS = {
  EVOLUCAO: {
    CONVENIO: "evolucao_convenio",
    PF: "evolucao_pf",
    PJ_AVULSO: "evolucao_pj_avulso",
  },
  CONVENIO: {
    TITULAR: "convenio_titular",
    DEPENDENTE_1: "convenio_dependente_1",
    DEPENDENTE_2: "convenio_dependente_2",
    DEPENDENTE_3: "convenio_dependente_3",
    DEPENDENTE_N: "convenio_dependente_N",
  },
  PF: {
    MENSAL: "pf_mensal",
    TRIMESTRAL: "pf_trimestral",
    SEMESTRAL: "pf_semestral",
    ANUAL: "pf_anual",
  },
  PJ: {
    TITULAR: "pj_titular",
    DEPENDENTE_1: "pj_dependente_1",
    DEPENDENTE_2: "pj_dependente_2",
    DEPENDENTE_3: "pj_dependente_3",
    DEPENDENTE_N: "pj_dependente_N",
  },
};

function Pricing() {
  const [state, setState] = useState({
    pricings: [],
    pricing: {},
    loading: true,
    error: "",
  });
  const { pricings, pricing, loading, error } = state;

  useEffect(() => {
    pegaPricings();
  }, []);

  async function pegaPricings() {
    let pricings = await PRICING.admin.get.all();
    setState((s) => ({ ...s, pricings, loading: false }));
  }

  async function salvaPricing(tag) {}

  async function checaPricing(tag) {}

  function updatePricing(tag, value) {
    setState((s) => {
      let pricings = s.pricings.map((p) => {
        if (p.tag === tag) p.pricing_id = value;
        return p;
      });
      return { ...s, pricings };
    });
  }

  if (loading) return <S.Container>Carregando...</S.Container>;

  return (
    <S.Container>
      <Title>Configuração de preços</Title>
      <Description>
        Nesta página você define quais precificações devem ser utilizadas para
        cada um dos serviços
      </Description>
      <WhiteBox>
        <Title>Evolução de preços</Title>
        <Description>
          Produtos criados para simular a evolução de preço.
        </Description>
        {Object.keys(PRICE_TAGS.EVOLUCAO).map((key) => {
          let tag = PRICE_TAGS.EVOLUCAO[key];
          let pricing = pricings.find((p) => p.tag === tag);
          pricing = pricing.pricing_id;
          return (
            <PricingTag tag={tag} value={pricing} onChange={updatePricing} />
          );
        })}
      </WhiteBox>
      <WhiteBox>
        <Title>Pessoa Física - Avulso</Title>
        <Description>Precificação para contratação pelo website</Description>
        {Object.keys(PRICE_TAGS.PF).map((key) => {
          let tag = PRICE_TAGS.PF[key];
          let pricing = pricings.find((p) => p.tag === tag);
          pricing = pricing.pricing_id;
          return (
            <PricingTag tag={tag} value={pricing} onChange={updatePricing} />
          );
        })}
      </WhiteBox>
      <WhiteBox>
        <Title>PJ Avulso</Title>
        <Description>Contratação de PJ avulso</Description>
        {Object.keys(PRICE_TAGS.PJ).map((key) => {
          let tag = PRICE_TAGS.PJ[key];

          let pricing = pricings.find((p) => p.tag === tag);
          pricing = pricing.pricing_id;
          return (
            <PricingTag tag={tag} value={pricing} onChange={updatePricing} />
          );
        })}
      </WhiteBox>
      <WhiteBox>
        <Title>Convênio</Title>
        <Description>Contratação por convênio</Description>
        {Object.keys(PRICE_TAGS.CONVENIO).map((key) => {
          let tag = PRICE_TAGS.CONVENIO[key];

          let pricing = pricings.find((p) => p.tag === tag);
          pricing = pricing.pricing_id;
          console.log(pricing);
          return (
            <PricingTag tag={tag} value={pricing} onChange={updatePricing} />
          );
        })}
      </WhiteBox>
    </S.Container>
  );
}

function PricingTag({ tag, value, onChange }) {
  const [original, setOriginal] = useState(null);
  const [valid, setValid] = useState(true);
  const [toCheck, setToCheck] = useState(false);
  useEffect(() => {
    setOriginal(value);
  }, []);

  let isModified = original !== value;

  async function save() {
    try {
      let response = await PRICING.admin.set.update(tag, value);
      console.log(response);
      if (response === true) {
        toast.success("Preço atualizado com sucesso");
        setOriginal(value);
      }
    } catch (error) {
      toast.error("Confira a chave diretamente no Stripe");
    }
  }

  return (
    <S.Tag>
      <S.TagTitle>{tag.toUpperCase()}</S.TagTitle>
      <FH>
        <S.TagInput
          type="text"
          value={value}
          color={value.length === 30 ? "#c8ffca" : "#ffffff"}
          maxLength={30}
          onChange={(e) => onChange(tag, e.target.value)}
        />
        {!isModified && (
          <a
            href={`https://dashboard.stripe.com/prices/${original}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button color={"#878787"}>
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </a>
        )}
        {isModified && value.length === 30 && (
          <>
            <Button onClick={save}>
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </>
        )}
      </FH>
    </S.Tag>
  );
}

export default Pricing;
