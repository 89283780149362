import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const ActionButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    background-color: ${props=>props.color ? props.color : "#333"};
    padding: 5px;
    border-radius: 5px;
    transition: all 0.2s;
    &:hover {
        background-color:${props=>props.color ? `${props.color}37` : "#33333337"};
        color: #000;
    }
    `;

