import styled from "styled-components";

export const COLORS = {
  main: "#819943",
  secondary: "#c0cda0",
  tertiary: "#888",
  grey: "#b1b1b1",
  dark_grey: "#333",
  light: "#fff",
  dark: "#000",
  red: "#A60C0C",
  green: "#00ff00",
  blue: "#0f73bb",
  yellow: "#ffcc00",
  light_red: "#ff8888",
  light_green: "#88ff88",
  light_blue: "#8888ff",
  light_yellow: "#ffff88",
  dark_red: "#880000",
  dark_green: "#008800",
  dark_blue: "#000088",
  dark_yellow: "#888800",
};

export const Container = styled.div`
  /* Styles for the main container */
`;

export const WhiteBox = styled.div`
  /* Styles for white boxes */
  background-color: #ffffff67;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #00000010;
  // blur background
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
  gap: 5px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  & + & {
    margin-top: 20px;
  }
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  margin-bottom: 20px;
  border-bottom: 1px dotted #3d3d3d43;
  padding-bottom: 20px;
`;

export const GappedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & + & {
    border-left: 1px dotted #3d3d3d21;
    padding-left: 10px;
  }
`;

export const Title = styled.h1`
  /* Styles for titles */
  font-size: 20px;
  font-weight: bold;
  color: #333;
`;

export const TextArea = styled.textarea`
  font-size: 12px;
  color: #333;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 10px 20px;
  flex: 1;
  background-color: #ffffff;
  width: 100%;
  height: 100px;
`;

export const Text = styled.p`
  /* Styles for regular text */
  font-size: 13px;
  color: #666;
  line-height: 1;
  margin: 0;
`;

export const Label = styled(Text)`
  font-size: 10px;
  color: #909090;
  text-transform: uppercase;
`;

export const Subtitle = styled.h2`
  /* Styles for subtitles */
  font-size: 16px;
  font-weight: bold;
  color: #555;
`;

export const Description = styled.p`
  /* Styles for descriptions */
  font-size: 12px;
  color: #888;
`;

export const FHSB = styled.div`
  /* Styles for flex horizontal space between */
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const Button = styled.button`
  /* Styles for buttons */
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: ${(props) =>
    props.disabled ? "#ccc" : props.color ? props.color : COLORS.main};
  border: none;
  border-radius: 8px;
  padding: ${(props) => (props.square ? "10px" : "10px 25px")};
  cursor: pointer;
 
`;

export const FH = styled.div`
  /* Styles for flex horizontal */
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Input = styled.input`
  /* Styles for inputs */
  font-size: 12px;
  color: #333;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 10px 20px;
  flex: 1;
  background-color: #ffffff;
`;

export const Select = styled.select`
  /* Styles for selects */
  font-size: 12px;
  color: #333;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 4px 8px;
`;

export const Option = styled.option`
  /* Styles for options */
  font-size: 12px;
  color: #333;
`;

export const Link = styled.a`
  /* Styles for links */
  font-size: 13px;
  color: #3c8159;
  text-decoration: none;
  cursor: pointer;
  line-height: 1;
  border-bottom: 1px dotted #3c8159;
`;

export const Image = styled.img`
  /* Styles for images */
  width: 100%;
  max-width: 100%;
  height: auto;
`;

export const LeftMenu = styled.ul`
  /* Styles for left menus */
  list-style: none;
  padding: 15px;
  margin: 0;
  gap: 5px;
  background-color: ${COLORS.main};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
`;

export const LeftMenuItem = styled.li`
  /* Styles for left menu items */
  font-size: 1rem;
  cursor: pointer;
  gap: 5px;
  display: flex;
  align-items: center;
  color: white;
  > a {
    text-decoration: none;
    color: white;
  }
`;

export const FormContainer = styled.div`
  // 1 column grid
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 25px;
  grid-row-gap: 5px;
`;

export const HG = styled.div`
  // infinite column grid, one line
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 5px;
`;
