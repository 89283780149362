import React from "react";

import * as S from "./styles";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  faHeartPulse,
  faLock,
  faPencil,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NovaEmpresa from "../../NovaEmpresa";
import NovaAssinatura from "../../NovaAssinatura";
import { ROLES } from "../../../utils/constants";
import AddDependentModal from "../../../components/AddDependentModal";

function AddModal({ open, toggle, id, data, addingRole }) {
  const bloqueado = data?.status === "blocked";
  const isLife = data?.is_life;

  switch (addingRole) {
    case ROLES.pj:
    case ROLES.convenio:
      return <NovaAssinatura isOpen={open} toggle={toggle} />;
    case ROLES.titular:
      return <AddDependentModal isOpen={open} toggle={toggle} />;
      break;
    default:
      return <NovaAssinatura isOpen={open} toggle={toggle} />;
      break;
  }
}

function TogglerBtn({
  text = "",
  icon = faHeartPulse,
  onClick,
  active = true,
  color = "#bc0505",
}) {
  return (
    <S.Tool onClick={onClick} active={active}>
      <S.ToolIcon>
        <FontAwesomeIcon
          icon={icon}
          color={active ? color : "#c0c0c0"}
          fixedWidth
          // transform="shrink-6"
          size={"2x"}
          style={{ marginBottom: 5 }}
          // transform={active ? "" : "shrink-6"}
        />
        {/* {!active && (
          <FontAwesomeIcon
            icon={faTimes}
            fixedWidth
            size={"2x"}
            color={"#a5a5ad"}
          />
        )} */}
      </S.ToolIcon>
      <S.ToolText>{text}</S.ToolText>
    </S.Tool>
  );
}

export default AddModal;
