import api from "../axios";

const PA = {
  get: {
    all: async (page, perPage, search = "", role) => {
      let url = `/users?page=${page}&perPage=${perPage}`;
      if (search) url += `&search=${search}`;
      if (role) url += `&role=${role}`;
      let response = await api.get(url);
      return response.data;
    },
    sintomas: async () => {
      let url = `/pa/symptoms`;
      let response = await api.get(url);
      return response.data;
    },
  },
  set: {
    new: async (data)=>{
      let url = `/pa/new`
      let response = await api.post(url, data);
      return response.data;
    }
  },
};

export default PA;
