import React, { useContext, useEffect } from "react";

import * as S from "./styles";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  faHeartPulse,
  faLock,
  faPencil,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "..";
import USERS from "../../../api/users";
import { toast } from "react-toastify";

function ConfigModal({ open, toggle, id }) {
  // asyn toast

  const { data, setState } = useContext(UserContext);
  const bloqueado = data?.status === "blocked";
  const isLife = data?.is_life;

  async function toggleLife() {
    if (data?.status !== "active") {
      toast.warn("O usuário precisa estar ativo para gerenciar vidas.");
      return;
    }
    const ativa = isLife;
    // confirma
    if (
      !window.confirm(
        `Deseja realmente ${isLife ? "desativar" : "ativar"} a vida?`
      )
    )
      return;

    setState((s) => {
      let is_life = !s.data.is_life;
      toast.promise(updateLife(is_life), {
        pending: `${is_life ? "Ativando" : "Desativando"} vida`,
        success: `Vida ${is_life ? "Ativada" : "Desativada"} com sucesso!`,
        error: `Erro ao ${is_life ? "ativar" : "desativar"} a vida!`,
      });
      // updateLife(is_life)
      return { ...s, data: { ...s.data, is_life } };
    });
  }

  async function updateLife(is_life) {
    try {
      let response;
      if (is_life) {
        response = await USERS.set.add_life(id);
      } else {
        response = await USERS.set.remove_life(id);
      }
      console.log("RESPONSE", response);
    } catch (error) {
      setState((s) => {
        return { ...s, data: { ...s.data, is_life: !is_life } };
      });
      throw error;
    }
  }

  async function toggleBlocked() {
    // confirma
    if (
      !window.confirm(
        `Deseja realmente ${bloqueado ? "desbloquear" : "bloquear"} o usuário?`
      )
    )
      return;

    setState((s) => {
      let status = s.data.status === "blocked" ? "active" : "blocked";
      return { ...s, data: { ...s.data, status } };
    });
    // let response = await USERS.set.block(id);
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Configurações</ModalHeader>
      <ModalBody>
        <S.Tools>
          <TogglerBtn
            active={isLife}
            text={isLife ? "Desativar Vida" : "Ativar Vida"}
            onClick={toggleLife}
          />
          {/* <TogglerBtn
            icon={faLock}
            active={bloqueado}
            text={bloqueado ? "Bloqueado" : "Bloquear"}
            onClick={toggleBlocked}
          /> */}
          {/* <TogglerBtn
            icon={faPencil}
            color={"#656565"}
            active={true}
            text={"Editar"}
            onClick={() => {}}
          /> */}
        </S.Tools>
      </ModalBody>
    </Modal>
  );
}

function TogglerBtn({
  text = "",
  icon = faHeartPulse,
  onClick,
  active = true,
  color = "#bc0505",
}) {
  return (
    <S.Tool onClick={onClick} active={active}>
      <S.ToolIcon>
        <FontAwesomeIcon
          icon={icon}
          color={active ? color : "#c0c0c0"}
          fixedWidth
          // transform="shrink-6"
          size={"2x"}
          style={{ marginBottom: 5 }}
          // transform={active ? "" : "shrink-6"}
        />
        {/* {!active && (
          <FontAwesomeIcon
            icon={faTimes}
            fixedWidth
            size={"2x"}
            color={"#a5a5ad"}
          />
        )} */}
      </S.ToolIcon>
      <S.ToolText>{text}</S.ToolText>
    </S.Tool>
  );
}

export default ConfigModal;
