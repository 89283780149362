import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as S from "./styles";
import { COLORS } from "../../routes/main-styles";

function IconToggler({ icon, name, selected, toggle }) {
  return (
    <S.Container onClick={toggle} selected={selected}>
      <FontAwesomeIcon icon={icon} color={selected ? "#ffffff" : COLORS.main} />
      <S.Text style={{ color: selected ? "#ffffff" : COLORS.main }}>
        {name}
      </S.Text>
    </S.Container>
  );
}

export default IconToggler;
