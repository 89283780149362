import React, { useEffect, useState } from "react";
import * as S from "../styles";
import { Col, Container, Row } from "reactstrap";
import { Text, Title, WhiteBox } from "../../main-styles";

function Waiting({ id_atendimento }) {
    const [files, setFiles] = useState([]);

    const handleDrop = (event) => {
        event.preventDefault();
        const newFiles = Array.from(event.dataTransfer.files);
        setFiles([...files, ...newFiles]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        console.log("ID ATENDIMENTO", id_atendimento);
    }, [id_atendimento]);

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <S.Container>
                        <S.Top>
                            <WhiteBox
                                style={{ marginTop: 20 }}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <Title>Em espera</Title>
                                <Text>
                                    Enquanto aguarda, tem algum exame que deseja enviar ao médico?
                                </Text>
                                <form>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={(event) =>
                                            setFiles([...files, ...event.target.files])
                                        }
                                    />
                                </form>
                                <ul>
                                    {files.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                    ))}
                                </ul>
                            </WhiteBox>
                        </S.Top>
                    </S.Container>
                </Col>
            </Row>
        </Container>
    );
}

export default Waiting;
