import api from "../axios";

const PRICING = {
  get: {
    default_pj: async () => {
      const response = await api.get("/finance/pricing/pj/default");
      return response.data;
    },
    all_pj: async () => {
      const response = await api.get("/finance/pricing/pj");
      return response.data;
    },
    default_pf: async () => {
      const response = await api.get("/finance/pricing/pf/default");
      return response.data;
    },
    all_pf: async () => {
      const response = await api.get("/finance/pricing/pf/");
      return response.data;
    },
    all_convenio: async () => {
      const response = await api.get("/finance/pricing/convenio");
      return response.data;
    },
  },
  admin: {
    get: {
      all: async () => {
        const response = await api.get("/finance/admin/pricings");
        return response.data;
      },
      one: async (id) => {
        const response = await api.get(`/finance/admin/pricings/one/${id}`);
        return response.data;
      },
      verify: async (tag) => {
        const response = await api.get(`/finance/admin/pricings/verify/${tag}`);
        return response.data;
      },
    },
    set: {
      update: async (tag, value) => {
        const response = await api.patch(
          `/finance/admin/pricings/${tag}`,
          { stripe_pricing_id: value }
        );
        return response.data;
      },
    },
  },
};

export default PRICING;
