import React, { useEffect, useState } from "react";
import { REQ_STATUS, ROLES } from "../../../../utils/constants";
import USERS from "../../../../api/users";
import { useParams } from "react-router-dom";
import { Subtitle } from "../../../main-styles";
import * as S from "./styles";

function Detalhes() {
  const { id } = useParams();
  const [state, setState] = useState({
    info: [],
    role: "",
    dependents: [],
    type: "",
    status: REQ_STATUS.LOADING,
  });
  const { info, role, dependents, type, status } = state;

  useEffect(() => {
    init();
  }, [id]);

  async function init() {
    let promises = await Promise.all([USERS.get.one(id)]);
    const [info] = promises;
    setState((state) => ({
      ...state,
      info,
      role: info?.role,
      type: info?.type,
      childs: info?.chidls,
      status: REQ_STATUS?.IDLE,
    }));
  }
  if (status === REQ_STATUS.LOADING) return <div>Carregando...</div>;
  return (
    <div>
      <Subtitle>Detalhes do Usuário</Subtitle>
      <S.Container>
        <S.ContainerEsquerdo>Tipo: {type}</S.ContainerEsquerdo>
        <S.ContainerDireito>
          <RenderDependents dependents={dependents} />
        </S.ContainerDireito>
      </S.Container>
    </div>
  );
}

function RenderDependents({ dependents }) {
  // renderiza os dependentes, sejam eles pessoas físicas ou jurídicas
  return (
    <S.DependentContainer>
      <S.DependentName>Entidades Associadas</S.DependentName>
      {dependents?.map((dependent) => (
        <Dependent dependent={dependent} />
      ))}
      {dependents.length === 0 && (
        <S.Dependent>
          <S.DependentInfo>Nenhuma</S.DependentInfo>
        </S.Dependent>
      )}
    </S.DependentContainer>
  );
}

function Dependent({ dependent }) {
  const type = dependent?.type;
  switch (type) {
    case ROLES.dependente:
      return (
        <S.Dependent>
          <S.DependentImage url={dependent?.image} />
          <S.DependentName>{dependent?.name}</S.DependentName>
        </S.Dependent>
      );
    case ROLES.titular:
      return (
        <S.Dependent>
          <S.DependentImage url={dependent?.image} />
          <S.DependentName>{dependent?.name}</S.DependentName>
          <S.DependentInfo>X Dependentes</S.DependentInfo>
        </S.Dependent>
      );
    case ROLES.convenio:
      return (
        <S.Dependent>
          <S.DependentImage url={dependent?.image} />
          <S.DependentName>{dependent?.name}</S.DependentName>
          <S.DependentInfo>X Vidas</S.DependentInfo>
        </S.Dependent>
      );
    case ROLES.pj:
      return (
        <S.Dependent>
          <S.DependentImage url={dependent?.image} />
          <S.DependentName>{dependent?.name}</S.DependentName>
          <S.DependentInfo>X Vidas</S.DependentInfo>
        </S.Dependent>
      );

    default:
      break;
  }
}

export default Detalhes;
